import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import { HouseData, ConverterLayer, PartOfHouse, InputBoxType, autoHouseData, makeHouseState, CompletenessType } from "./DataTypes";
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Button } from '@material-ui/core'
import { ConverterInputBox } from './ConverterInputBox';
import * as MeshMaker from './MeshMaker';
import { switchLayerState, GetPolygonCentroid, CheckHouseCompleteness, CheckColsedPolygon, setErrorColorForLayer } from './CoreAndHouseController';
import '../css/CADConverter/HouseAndCore.scss';

interface houseState {
  name: string,
  wall: ConverterLayer | null;
  lightWindow: ConverterLayer | null;
  normalWindow: ConverterLayer | null;
  exclusiveArea: number; // 전용 면적
  serviceArea: number; // 발코니 면적
  balconyOver150cm: number;
  balconyLess150cm: number;
  extraMenu: boolean;
  level: number;
  levleHeight: number;
  piloti: number;
  part: PartOfHouse,
  setting: boolean,
  addLightWindow: boolean,
  addNomralWindow: boolean,
  addWall: boolean,
  id: string,
  hideList: boolean,
}

interface houseProp {
  showLayerList: (id: string) => void,
  removeHouse: (house: HouseData) => void,
  valueChanged: () => void,
  showModel: (information: string) => void,
  house: HouseData,
  selectLayer: ConverterLayer | null,
  settingID: string,
  autoSetData?: autoHouseData | null,
  isSettingLevel?: boolean,
  editable: boolean,
}

export class House extends Component<houseProp, houseState> {
  state: houseState = {
    name: this.props.house.name,
    wall: this.props.house.wall,
    lightWindow: this.props.house.lightWindow,
    normalWindow: this.props.house.normalWindow,
    exclusiveArea: this.props.house.exclusiveArea, // 전용 면적
    serviceArea: this.props.house.serviceArea, // 발코니 면적
    balconyOver150cm: this.props.house.balconyOver150cm,
    balconyLess150cm: this.props.house.balconyLess150cm,
    piloti: this.props.house.piloti,
    level: this.props.house.level.length,
    levleHeight: this.props.house.levelHeights[0],
    part: PartOfHouse.wall,
    extraMenu: false,
    setting: false,
    addLightWindow: (this.props.house.lightWindow && true) || false,
    addNomralWindow: (this.props.house.normalWindow && true) || false,
    addWall: (this.props.house.wall && true) || false,
    id: this.props.house.id,
    hideList: this.props.house.hideList,
  }

  componentDidMount = async () => {
    if (this.props.house.complete === CompletenessType.complete && this.props.house.outputPolygon.length === 0) {
      this.makeHousePolygon();
    }
  }

  componentDidUpdate = (previousProps: Readonly<houseProp>, previousState: Readonly<houseState>) => {
    if (this.props.house.id !== this.props.settingID)
      return;

    if (previousProps.selectLayer !== this.props.selectLayer) {
      switch (this.state.part) {
        case PartOfHouse.wall:
          this.setWall(this.props.selectLayer);
          break;
        case PartOfHouse.lightWindow:
          this.setLightWindow(this.props.selectLayer);
          break;
        case PartOfHouse.normalWindow:
          this.setNormalWindow(this.props.selectLayer);
          break;
        default:
          break;
      }
      CheckHouseCompleteness(this.props.house);

      this.makeHousePolygon();
      this.props.valueChanged();
    }
  }

  setWall = (layer: ConverterLayer | null) => {
    if (!layer)
      return;

    let exLayer = this.props.house.wall;
    if (this.props.selectLayer === this.props.house.wall) {
      this.props.house.wall = null;

      this.setState({ wall: null, addWall: false })
      if (exLayer)
        switchLayerState(exLayer);
    }
    else if (this.props.selectLayer!.selected) {
      this.props.showModel('이미 선택되어 있는 레이어입니다.');
      return;
    }
    else {
      if (CheckColsedPolygon(layer)) {
        this.props.house.wall = layer;

        this.setState({ wall: layer, addWall: true })

        switchLayerState(layer);

        if (exLayer)
          switchLayerState(exLayer);

      }
      else {
        setErrorColorForLayer(layer);
        this.props.showModel('닫친 폴리곤이 아닙니다.');
      }
    }
  }

  setLightWindow = (layer: ConverterLayer | null) => {
    let exLayer = this.props.house.lightWindow;
    if (this.props.house.lightWindow === this.props.selectLayer) {
      this.props.house.lightWindow = null;
      this.setState({ lightWindow: null, addLightWindow: false })
      if (exLayer)
        switchLayerState(exLayer);
    }
    else if (this.props.selectLayer!.selected) {
      this.props.showModel('이미 선택되어 있는 레이어입니다.');
      return;
    }
    else {
      this.props.house.lightWindow = layer;
      this.setState({ lightWindow: layer, addLightWindow: true })

      if (exLayer)
        switchLayerState(exLayer);

      switchLayerState(layer);
      layer!.polygons.forEach(p => {
        p.lineMesh.renderOrder = 1;
      })
    }
  }

  setNormalWindow = (layer: ConverterLayer | null) => {
    let exLayer = this.props.house.normalWindow;
    if (this.props.house.normalWindow === this.props.selectLayer) {
      this.props.house.normalWindow = null;
      this.setState({ normalWindow: null, addNomralWindow: false })
      if (exLayer)
        switchLayerState(exLayer);
    }
    else if (this.props.selectLayer!.selected) {
      this.props.showModel('이미 선택되어 있는 레이어입니다.');
      return;
    }
    else {
      this.props.house.normalWindow = layer;
      this.setState({ normalWindow: layer, addNomralWindow: true })

      if (exLayer)
        switchLayerState(exLayer);

      switchLayerState(layer);

      layer!.polygons.forEach(p => {
        p.lineMesh.renderOrder = 1;
      })
    }
  }

  makeHousePolygon = () => {
    let notice = MeshMaker.remakeHousePolygons(this.props.house);

    if (this.props.house.wall && (this.props.house.lightWindow || this.props.house.normalWindow)) {
      if (notice !== makeHouseState.Finish) {
        this.props.showModel(`${this.props.house.name}에 생성 안 된 창문 라인이 있습니다.`);
        if (notice === makeHouseState.lightWindowError || notice === makeHouseState.allWindowError)
          setErrorColorForLayer(this.props.house.lightWindow);
        if (notice === makeHouseState.outerWindowError || notice === makeHouseState.allWindowError)
          setErrorColorForLayer(this.props.house.normalWindow);
      }
    }

    if (this.props.house.wall) {
      this.props.house.centerOfAllLine = GetPolygonCentroid(this.props.house.wall);
    }

    CheckHouseCompleteness(this.props.house);
  }

  showLayerList = (part: PartOfHouse) => {
    this.props.showLayerList(this.props.house.id);
    this.setState({ part: part })
  }

  valueChanged = (value: number, inputType: InputBoxType) => {
    switch (inputType) {
      case InputBoxType.exclusiveArea:
        this.setExclusiveArea(value);
        break;
      case InputBoxType.serviceArea:
        this.setServiceArea(value);
        break;
      case InputBoxType.balconyOver150cm:
        this.setBalconyOver150cm(value);
        break;
      case InputBoxType.level:
        let height = this.props.house.levelHeights[0];
        this.props.house.level = [];
        this.props.house.levelHeights = [];
        for (let i = 0; i < value; i++) {
          this.props.house.level.push(true);
          this.props.house.levelHeights.push(height);
        }
        this.setState({ level: value });
        break;
      case InputBoxType.levelHeights:
        for (let i = 0; i < this.props.house.levelHeights.length; i++) {
          this.props.house.levelHeights[i] = value;
        }
        this.setState({ levleHeight: value });
        break;
      case InputBoxType.piloti:
        if (value > this.props.house.level.length) {
          value = this.props.house.level.length;
        }

        for (let i = 0; i < this.props.house.level.length; i++) {
          if (i < value) {
            this.props.house.level[i] = false;
          }
          else {
            this.props.house.level[i] = true;
          }
        }
        this.props.house.piloti = value;
        this.setState({ piloti: value });
        break;
      default:
        break;
    }
    this.makeHousePolygon();
    this.props.valueChanged();
    CheckHouseCompleteness(this.props.house);
  }

  setExclusiveArea = (value: number) => {
    this.props.house.exclusiveArea = value;
    this.setState({ exclusiveArea: value });
  }

  setServiceArea = (value: number) => {
    this.props.house.serviceArea = value;
    this.props.house.balconyLess150cm = value;
    this.props.house.balconyOver150cm = 0;
    this.setState({ serviceArea: value, balconyLess150cm: value, balconyOver150cm: 0 });
  }

  setBalconyOver150cm = (value: number) => {
    if (value > this.state.serviceArea) {
      value = this.state.serviceArea;
    }

    this.props.house.balconyOver150cm = value;
    this.props.house.balconyLess150cm = this.props.house.serviceArea - this.props.house.balconyOver150cm;
    this.setState({ balconyOver150cm: value, balconyLess150cm: this.props.house.balconyLess150cm });
  }

  showExtraMenu = () => {
    this.setState({ extraMenu: !this.state.extraMenu })
  }

  toogleList = () => {
    this.props.house.hideList = !this.state.hideList;
    this.setState({ hideList: !this.state.hideList })
  }

  render = () => {
    return (
      <div className='house'>
        <div className='nameDiv' onClick={this.toogleList}>{this.state.name}{(this.state.hideList && <UpIcon className='showIcon' />) || <DownIcon className='showIcon' />}
          <span className='notice' hidden={this.props.house.complete === CompletenessType.complete}>레이어를 선택 후 면적 정보를 입력해주세요</span>
          <CloseIcon className='closeIcon' onClick={() => { if (this.props.editable) this.props.removeHouse(this.props.house) }} />
        </div>
        <div hidden={this.state.hideList}>
          <div className='buttonDiv'>
            <Button className={`${(this.props.settingID === this.props.house.id && this.state.part === PartOfHouse.wall) && 'selected' || 'unselected'}`} onClick={() => this.showLayerList(PartOfHouse.wall)} disabled={!this.props.editable}>벽 레이어 선택</Button>
            <CheckIcon className={`CheckIcon ${this.state.wall && 'checked'}`} />
            {this.state.wall && this.state.wall.name}
          </div>
          <div className='buttonDiv'>
            <Button className={`${(this.props.settingID === this.props.house.id && this.state.part === PartOfHouse.lightWindow) && 'selected' || 'unselected'}`} onClick={() => this.showLayerList(PartOfHouse.lightWindow)} disabled={!this.props.editable}>채광 창 레이어 선택</Button>
            <CheckIcon className={`CheckIcon ${this.state.lightWindow && 'checked'}`} />
            {this.state.lightWindow && this.state.lightWindow.name}
          </div>
          <div className='buttonDiv'>
            <Button className={`${(this.props.settingID === this.props.house.id && this.state.part === PartOfHouse.normalWindow) && 'selected' || 'unselected'}`} onClick={() => this.showLayerList(PartOfHouse.normalWindow)} disabled={!this.props.editable}>일반 창 레이어 선택</Button>
            <CheckIcon className={`CheckIcon ${this.state.normalWindow && 'checked'}`} />
            {this.state.normalWindow && this.state.normalWindow.name}
          </div>
          <div className='inputValues'>
            <ConverterInputBox name='전용 면적' value={this.state.exclusiveArea} areaType={InputBoxType.exclusiveArea} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />
            <div style={{ position: 'relative' }}>
              <div className='extraMenu' onClick={this.showExtraMenu}>자세히+</div>
              <ConverterInputBox name='발코니 면적' value={this.state.serviceArea} areaType={InputBoxType.serviceArea} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />
            </div>
          </div>
          <div className={`inputValues ${!this.state.extraMenu && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
            <ConverterInputBox name='서비스 면적' value={this.state.balconyLess150cm} areaType={InputBoxType.balconyLess150cm} valueOnChange={this.valueChanged} disable={true} unit='㎡' step={0.1} />
            <ConverterInputBox name='용적율 포함 면적' value={this.state.balconyOver150cm} areaType={InputBoxType.balconyOver150cm} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />
          </div>
          <div className={`inputValues ${!this.props.isSettingLevel && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
            <ConverterInputBox name='층수' value={this.state.level} areaType={InputBoxType.level} valueOnChange={this.valueChanged} unit='층' />
            <ConverterInputBox name='층고' value={this.state.levleHeight} areaType={InputBoxType.levelHeights} valueOnChange={this.valueChanged} unit='m' step={0.01} />
          </div>
          <div className={`inputValues ${!this.props.isSettingLevel && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
            <ConverterInputBox name='필로티' value={this.state.piloti} areaType={InputBoxType.piloti} valueOnChange={this.valueChanged} unit='층' />
          </div>
        </div>
        <div className='Line' />
      </div>
    )
  }
}
