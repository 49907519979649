import React, { Component } from 'react';
import { FileDrop } from 'react-file-drop';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplate.scss';
import '@teneleven/protocols-ts-web';

import { HouseData, ConverterLayer, mapProjectionData, siteStruct, Polygon, entityType, Unit, saveState } from './DataTypes';
import { dataParsing, asyncFileRead } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite, checkFileName, checkSpecialSymbolInName, getRoadLine } from './DBManager';
import { NaverMapManager, NaverPoint, NaverLatLng } from './NaverMapManager';
import { SceneManager, WKTtoGeom } from './SceneManager';
import { any } from 'prop-types';
import { Field, FieldUI, FieldType } from './Field';
import { ReactComponent as Logo } from '../img/CADConverter/logo.svg';
import { Button } from '@material-ui/core';
import ForwardArrow from '@material-ui/icons/ArrowForward'
import SearchIcon from '@material-ui/icons/Search'
import { deleteFieldFromFieldList, getFieldsArea, mouseOverLayerTag, mouseOutLayerTag, setErrorColorForLayer } from './CoreAndHouseController';
import { shapeInfoData } from './ProjectDataStruct';
import { ModalProps, Modal, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import App from '../App';
import { getErrorCircle } from './MeshMaker';

const uuid4 = require('uuid/v4');
const jsts = require('jsts');
export interface SceneProps {

}

export interface SceneState {
  layers: ConverterLayer[];
  house: HouseData[];
  handle: number;
  isCad: boolean;
  isCadastral: boolean;
  screenWidth: number;
  screenHeight: number;
  address: string;
  canvasAlpha: number;
  fileName: string;
  saveName: string;
  showLayer: boolean;
  selectLayer: ConverterLayer | null;
  siteArea: number,
  roadArea: number,
  vacancyOusideArea: number,
  vacancyInsideArea: number,
  settingID: string,
  userID: string,
  showModal: boolean,
  loadFile: boolean,
  dragCover: boolean,
  fileUnit: Unit,
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    layers: [],
    house: [],
    handle: 0,
    isCad: true,
    isCadastral: false,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    address: '',
    canvasAlpha: 50,
    fileName: '선택된 파일 없음',
    saveName: '',
    showLayer: true,
    selectLayer: null,
    siteArea: 0,
    roadArea: 0,
    vacancyOusideArea: 0,
    vacancyInsideArea: 0,
    settingID: '',
    userID: 'test@1011.co.kr',
    showModal: false,
    loadFile: false,
    dragCover: true,
    fileUnit: Unit.Millimeters,
  };

  mapManager = new NaverMapManager();
  sceneManager = new SceneManager();

  mount: HTMLDivElement | null = null;
  polygon2DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  siteFields: Field[] = [];
  roadFields: Field[] = [];
  vacancyOutsideFields: Field[] = [];
  vacancyInsideFields: Field[] = [];

  DBTableName = 'platform-buildit-my-site-v2';
  S3BucketName = 'teneleven-platform-my-site-v2';
  fileData: string = '';
  areaBias = 1;
  makedAreaBias = false;

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => console.log('test'),
    title: '제목'
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  }

  componentDidMount = async () => {

    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.addObjectToScene(this.polygon2DGroup);

    this.sceneManager.SceneInit();

    this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);
    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';

    this.mapManager.addListener('zoom_changed', this.naverMapChanged);
    this.mapManager.addListener('center_changed', this.naverMapChanged);

    this.animate();

    this.setState({
      userID: App.session.email,
    })

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
  }

  componentDidUpdate = (previousProps: Readonly<SceneProps>, previousState: Readonly<SceneState>) => {
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      let aspect = width / height;

      let frustumSize = this.sceneManager.orthoCamera.right;
      if (this.state.canvasAlpha !== 100 && !this.state.isCad) {
        frustumSize = this.getFrustumSizeWithMapSize();
      }

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(width, height);
    }

    if (previousState.isCad !== this.state.isCad || previousState.canvasAlpha !== this.state.canvasAlpha) {
      if (this.state.isCad) {
        this.sceneManager.setRendererAlpha(1);
      }
      else {
        this.sceneManager.setRendererAlpha(this.state.canvasAlpha / 100);
      }
    }

    if (this.state.showLayer !== previousState.showLayer) {
      this.onWindowResize();
    }
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onWindowResize = () => {
    this.setState({
      screenWidth: window.innerWidth - 235 * (!this.state.showLayer ? 1 : 0) - (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 122,
    });
  }

  removeFieldFromFieldList = (field: Field) => {
    let i = this.siteFields.indexOf(field);
    if (i > -1) {
      this.mapManager.clearAllPolygon();
    }

    deleteFieldFromFieldList(this.siteFields, field);
    deleteFieldFromFieldList(this.roadFields, field);
    deleteFieldFromFieldList(this.vacancyOutsideFields, field);
    deleteFieldFromFieldList(this.vacancyInsideFields, field);

    this.recalculateArea();
  }

  addNewField = (fields: Field[], type: FieldType) => {
    let newField = new Field(fields.length.toString(), type);
    let uuid = newField.id;

    if (type === FieldType.site) {
      if (fields.length > 0) {
        this.showModal('알림', ['사업영역은 하나만 허용합니다!!!!'], buttonNum.oneButton, () => console.log('enable only one site layer'));
        return;
      }
    }

    fields.push(newField);

    this.setState({
      settingID: uuid,
      showLayer: false,
    })

    this.recalculateArea();
  }

  dataInitialize = () => {
    this.polygon2DGroup.children = [];
    this.siteFields = [];
    this.roadFields = [];
    this.vacancyOutsideFields = [];
    this.vacancyInsideFields = [];

    this.bbox = new THREE.Box3();
    this.mapManager.clearAllPolygon();

    this.setState({
      layers: [],
    })
  }

  loadFilebyFile = async (file: any) => {
    if (!file[0].name.endsWith('.dxf')) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data)
      return;
    this.fileData = data;
    this.dataInitialize();

    let hasCurve = false;
    let information: string[] = [];
    let layer = dataParsing(data, this.state.fileUnit);

    this.bbox.makeEmpty();
    layer.forEach(l => {
      l.polygons.forEach(p => {
        this.polygon2DGroup.add(p.lineMesh);
        if (p.hasCurve) {
          hasCurve = true;
          information.push(`${l.name} 레이어에 라운드가 있습니다`);
          l.errorLayer = true;
          this.polygon2DGroup.add(getErrorCircle(l));
          setErrorColorForLayer(l);
        }

        p.vertices.forEach(v => {
          this.bbox.expandByPoint(v);
        });
      });
    });

    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    if (this.state.screenHeight < this.state.screenWidth) {
      let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
      frustumSize = height / this.state.screenHeight * this.state.screenWidth;
    }
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(0, 0, 1);
    this.sceneManager.orthoControl.target.set(0, 0, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    if (hasCurve) {
      this.showModal('알림', information, buttonNum.oneButton, () => console.log('special symbol'));
    }

    this.setState({
      layers: layer,
      fileName: file[0].name,
      loadFile: true,
      isCad: true,
    }, () => this.onWindowResize())
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }

  recalculateArea = () => {
    this.setState({
      siteArea: getFieldsArea(this.siteFields),
      roadArea: getFieldsArea(this.roadFields),
      vacancyInsideArea: getFieldsArea(this.vacancyInsideFields),
      vacancyOusideArea: getFieldsArea(this.vacancyOutsideFields),
    })
  }

  showLayerList = (id: string) => {
    this.setState({
      showLayer: false,
      settingID: id,
    })
  }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;

      default:
        break;
    }
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        // this.mapManager.changeMapType();
        break;
      default:
        break;
    }
  }

  objectRotate(degree: number) {
    this.polygon2DGroup.rotateZ(THREE.MathUtils.degToRad(degree));
  }

  addSiteAreaToMap = async () => {
    if (!this.mapManager.isCreate() || this.siteFields.length === 0 || !this.siteFields[0].getLayer())
      return;

    this.mapManager.setBasePosition(this.sceneManager.getScreenPosition(this.siteFields[0].getFirstPoint()!.clone().applyMatrix4(this.polygon2DGroup.matrix)));
    this.mapManager.updateMapProjectionData(this.sceneManager.getScreenPosition(this.siteFields[0].getFirstPoint()!.clone().applyMatrix4(this.polygon2DGroup.matrix)));

    let mapProjData = this.mapManager.getMapProjectionData();
    let screenInfo = this.sceneManager.getScreenInfomation();

    let siteLatlngs: any[] = [];
    this.siteFields.forEach(f => {
      siteLatlngs = siteLatlngs.concat(f.getFieldLatLngs(this.polygon2DGroup.matrix, mapProjData, screenInfo));
    });

    let insideLatLngs: any[] = [];
    this.vacancyInsideFields.forEach(f => {
      insideLatLngs = insideLatLngs.concat(f.getFieldLatLngs(this.polygon2DGroup.matrix, mapProjData, screenInfo));
    })

    let outsideLatLngs: any[] = [];
    this.vacancyOutsideFields.forEach(f => {
      outsideLatLngs = outsideLatLngs.concat(f.getFieldLatLngs(this.polygon2DGroup.matrix, mapProjData, screenInfo));
    })

    let roadLatLngs: any[] = [];
    this.roadFields.forEach(f => {
      roadLatLngs = roadLatLngs.concat(f.getFieldLatLngs(this.polygon2DGroup.matrix, mapProjData, screenInfo));
    })

    this.mapManager.setNaverSitePolygon(siteLatlngs);
    this.mapManager.setNaverVacancyInsidePolygon(insideLatLngs);
    this.mapManager.setNaverVacancyOutsidePolygon(outsideLatLngs);
    this.mapManager.setNaverRoadPolygon(roadLatLngs);
  }

  getFrustumSizeWithMapSize = () => {
    let mapProj = this.mapManager.getMapProjectionData();
    let rect = this.mount!.getBoundingClientRect();
    let p1 = mapProj.projection.fromPageXYToCoord(NaverPoint(0, rect.top));
    let p2 = mapProj.projection.fromPageXYToCoord(NaverPoint(this.state.screenWidth, rect.top));
    return mapProj.projection.getDistance(p1, p2) / 2;
  }

  resizeCanvasResolution = () => {
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    let width = this.getFrustumSizeWithMapSize();
    // width = this.areaBias !== 1 ? width / this.areaBias : width;
    this.sceneManager.CameraFrustumResize(width / this.areaBias, aspect);
  }

  naverMapChanged = () => {
    this.areaBias = 1;
    this.makedAreaBias = false;
    this.resizeCanvasResolution();
  }

  canvasModeChanged = (_isCAD: boolean) => {
    if (_isCAD) {
      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
    }
    else {
      this.sceneManager.getControl().enableKeys = false;
      this.sceneManager.canvasElement.style.pointerEvents = 'none';
      (this.refs.map as HTMLDivElement).style.visibility = 'visible';
      this.resizeCanvasResolution();
    }

    this.setState({
      isCad: _isCAD,
    });
  }

  mapTypeChanged = (isCadastral: boolean) => {
    this.mapManager.changeMapType(isCadastral);

    this.setState({
      isCadastral: isCadastral
    })
  }

  checkSaveState = async () => {
    let fn = this.state.saveName === '' ? 'my_site' : this.state.saveName;
    let information: string[] = [];
    let state: saveState = saveState.complete;

    if (checkSpecialSymbolInName(fn)) {
      information.push('제목에 사용할 수 없는 특수 문자("\/)가 포함되어 있습니다.');
      state = saveState.error;
    }

    let checkedName = await checkFileName(fn, this.state.userID, this.DBTableName);
    if (!checkedName) {
      information.push('이미 같은 제목을 가진 나의 사업영역이 존재합니다.');
      state = saveState.error;

    }

    if (this.mapManager.getMapSettingState()) {
      information.push('사업영역을 맵에 표시해 주세요.');
      state = saveState.error;
    }

    if (this.roadFields.length < 1 || this.state.roadArea <= 0) {
      information.push('최소 하나의 도로영역이 선택되어야 하며, 면적은 0㎡ 보다 커야합니다.');
      state = saveState.error;
    }

    if (this.state.isCad) {
      information.push('MAP화면에만 저장 가능합니다.');
      state = saveState.error;
    }

    switch (state as saveState) {
      case saveState.complete:
        this.saveToAWS(fn);
        break;
      case saveState.warning:
        information.push('계속 진행 하시겠습니까??')
        this.showModal('알림', information, buttonNum.twoButton, () => this.saveToAWS(fn));
        break;
      case saveState.error:
        this.showModal('경고', information, buttonNum.oneButton, () => console.log('error'));
        break;
      default:
        break;
    }
  }

  saveToAWS = async (fn: string) => {
    this.showModal('알림', ['나의 사업영역을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));

    let { globalId: globalID, userId: privateID } = await incrementCounter("my_site_id", this.state.userID);

    let S3SavePath = `${this.S3BucketName}/${App.stage}/${globalID}`;
    let imageName = `img_large.png`;
    let shapeInfoName = 'shapeInfo.json';
    let date = new Date().toISOString();

    await new Promise((resolve, reject) => {
      this.state.layers.forEach(l => {
        let visible = false;
        if (l.selected) {
          visible = true;
        }
        l.polygons.forEach(p => {
          p.lineMesh.visible = visible && p.selected;
        })
      })
      resolve();
    })

    let captureBbox = new THREE.Box3();
    this.siteFields.forEach(f => {
      f.getLayer()!.polygons.forEach(p => {
        if (p.selected) {
          p.vertices.forEach(v => {
            captureBbox.expandByPoint(v);
          })
        }
      })
    })

    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })

    //image 
    saveDataToS3(lImgBuf, S3SavePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, S3SavePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, S3SavePath, 'img_small.png', 'image/png');

    saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

    this.mapManager.updateMapProjectionData(this.sceneManager.getScreenPosition(this.siteFields[0].getFirstPoint()!.clone().applyMatrix4(this.polygon2DGroup.matrix)));

    let projectSiteWKT: string[] = [];
    let roadSiteWKT: string[] = [];
    let vacancyInsideWKT: string[] = [];
    let vacancyOutsideWKT: string[] = [];

    let mapProjData = this.mapManager.getMapProjectionData();
    let screenInfo = this.sceneManager.getScreenInfomation();

    this.siteFields.forEach(f => {
      projectSiteWKT = projectSiteWKT.concat(f.getWKTArray(this.polygon2DGroup.matrix, mapProjData, screenInfo));
    });

    this.roadFields.forEach(f => {
      roadSiteWKT = roadSiteWKT.concat(f.getWKTArray(this.polygon2DGroup.matrix, mapProjData, screenInfo));
    })

    this.vacancyInsideFields.forEach(f => {
      vacancyInsideWKT = vacancyInsideWKT.concat(f.getWKTArray(this.polygon2DGroup.matrix, mapProjData, screenInfo));
    })

    this.vacancyOutsideFields.forEach(f => {
      vacancyOutsideWKT = vacancyOutsideWKT.concat(f.getWKTArray(this.polygon2DGroup.matrix, mapProjData, screenInfo));
    })

    let shapeInfo: shapeInfoData = {
      boundaryLine: [''],
      projectSite: projectSiteWKT,
      projectSiteArea: this.state.siteArea,
      projectSiteRoad: [''],
      road: roadSiteWKT,
      setbackLineApartment: [''],
      setbackLineMultiHouse: [''],
      setbackLineOfficetel: [''],
      setbackLineRowHouse: [''],
      skylineCircle: [''],
      skylineLine: [''],
      vacancyInsie: vacancyInsideWKT,
      vacancyOutsie: vacancyOutsideWKT,
    }
    App.stage !== "prod" && console.log(shapeInfo);
    saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');

    let dbItem: siteStruct = {
      stage: App.stage,
      global_id: globalID,
      user_id: privateID,
      name: fn,
      email: this.state.userID,
      address: await getAddressByProjectSite(projectSiteWKT),
      project_site_area: this.state.siteArea,
      project_site: projectSiteWKT,
      road_site_area: this.state.roadArea,
      road_site: roadSiteWKT,
      vacancy_outside_area: this.state.vacancyOusideArea,
      vacancy_outside: vacancyOutsideWKT,
      vacancy_inside_area: this.state.vacancyInsideArea,
      vacancy_inside: vacancyInsideWKT,
      img_path: `s3://${S3SavePath}/${imageName}`,
      shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
      created_at: date,
      modified_at: date,
      deleted: false,
    };
    App.stage !== "prod" && console.log(dbItem);
    saveDataToDynamoDB(dbItem, this.DBTableName);

    this.showModal('알림', ['나의 사업영역을 저장했습니다.'], buttonNum.oneButton, () => console.log('save done'));
  }

  setSelectLayer = (click: ConverterLayer) => {
    if (click.selected) {
      this.showModal('알림', ['이미 선택되어 있는 레이어입니다.'], buttonNum.oneButton, () => console.log('selected layer'));
      return;
    }

    if (click.polygons.length > 1 && this.siteFields.length > 1 && this.siteFields[0].id === this.state.settingID) {
      this.showModal('알림', ['여러 개의 대지영역이 동시에 입력될 수 없습니다. 가장 큰 면적의 대지영역이 자동으로 선택됩니다.'], buttonNum.oneButton, () => console.log('add multipolygon to site layer'));
    }

    if (!this.state.selectLayer || click !== this.state.selectLayer) {
      this.setState({ selectLayer: click }, this.recalculateArea);
    }
    else {
      this.setState({ selectLayer: null }, () => this.setState({ selectLayer: click }, this.recalculateArea));
    }
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    })
  }

  autoSetting = () => {
    this.siteFields = [];
    this.roadFields = [];
    this.vacancyInsideFields = [];
    this.vacancyOutsideFields = [];

    this.state.layers.forEach(l => {
      if (l.errorLayer)
        return;

      let splits = l.name.split('_');

      switch (splits[0].toUpperCase()) {
        case 'SITE':
          if (this.siteFields.length < 1) {
            let newField = new Field('field', FieldType.site);
            newField.setLayer(l);
            if (Number(splits[1]))
              newField.setArea(0);
            this.siteFields.push(newField);
          }
          break;
        case 'ROAD':
          {
            let newField = new Field('field', FieldType.road);
            newField.setLayer(l);
            this.roadFields.push(newField);
          }
          break;
        case 'PARK':
          {
            let newField = new Field('field', FieldType.vacancyOutside);
            newField.setLayer(l);
            this.vacancyOutsideFields.push(newField);
          }
          break;
        case 'SPECIAL':
          {
            let newField = new Field('field', FieldType.vacancyInside);
            newField.setLayer(l);
            if (Number(splits[1]))
              newField.setArea(0);
            this.vacancyInsideFields.push(newField);
          }
          break;
        default:
          break;
      }
    })

    this.recalculateArea();
  }

  render = () => {
    return (
      <React.Fragment>
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        <header>
          <div className='Head'>
            <Logo className='logo' />
            <div className='headText'>
              <span>CAD Converter</span>
              <span className='typeName'>사업영역</span>
            </div>
          </div>
        </header>
        <div className='MainBody'>
          <div className='information'>
            <div className='info'><div className='infoLabel'>사업영역 면적</div><div className='inforValue'>{this.state.siteArea}㎡</div></div>
            <div className='info'><div className='infoLabel'>인접도로 면적</div><div className='inforValue'>{this.state.roadArea}㎡</div></div>
            <div className='info'><div className='infoLabel'>공지영역 면적</div><div className='inforValue'>{this.state.vacancyOusideArea}㎡</div></div>
            <div className='info'><div className='infoLabel'>특수영역 면적</div><div className='inforValue'>{this.state.vacancyInsideArea}㎡</div></div>
            <div>
              <select className='unit' onChange={(e) => this.setState({ fileUnit: Number(e.target.value) })}>
                <option value={Unit.Millimeters}>mm</option>
                <option value={Unit.Meters}>m</option>
                <option value={Unit.Inches}>inche</option>
              </select>
            </div>
            <Button className='fileLoad' component="label">파일 선택
              <input type="file" id="ex_file" style={{ display: 'none' }} onChange={this.loadDXFFile} accept='.dxf' />
            </Button>
          </div>

          <div className='RenderView'>
            <div className='Scene' >
              <div className='Canvas' ref={(mount) => { this.mount = mount }}>
                <div className={`dropFileBox ${this.state.loadFile && 'hidden'}`}>
                  <FileDrop onDrop={(files, event) => this.loadFilebyFile(files)}
                    onFrameDragEnter={() => this.setState({ dragCover: false })}
                    onFrameDragLeave={() => this.setState({ dragCover: true })}
                    onFrameDrop={() => this.setState({ dragCover: true })}
                  >
                    <div className='cover hidden'></div>
                  </FileDrop>
                  <div className={`content ${!this.state.dragCover && 'noEvent'}`}>
                    <div className='topText'>CAD 파일을 불러와서 <br />사업영역을 업로드해주세요!</div>
                    <Button className='button' component="label">파일 선택
                      <input type="file" id="ex_file" style={{ display: 'none' }} onChange={this.loadDXFFile} accept='.dxf' />
                    </Button>
                    <div className='bottomText'>또는 파일을 여기로 끌어 놓으세요.<br />현재 dxf 파일만 가능합니다.</div>
                  </div>
                </div>
                <div ref="map" style={{ width: `100%`, height: `100%`, position: "absolute" }} />
                <div className={`toolBar ${!this.state.loadFile && 'hidden'}`}>
                  <div className='searchDiv' hidden={this.state.isCad}>
                    <input
                      type='text'
                      className='addressSearch'
                      placeholder='주소 검색'
                      value={this.state.address}
                      onChange={e => this.setState({ address: e.target.value })}
                      onKeyUp={e => this.textInputKeyUp(e)}
                    />
                    <SearchIcon className='icon' onClick={() => this.mapManager.searchAddressToCoordinate(this.state.address)} />
                  </div>
                  <div className='rightButtons'>
                    <div className='switchTabs'>
                      <Button className={`switchButton ${this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(true)}>CAD</Button>
                      <Button className={`switchButton ${!this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(false)}>MAP</Button>
                    </div>
                    <div className={`switchTabs ${this.state.isCad && 'hidden'}`}>
                      <Button className={`switchButton ${!this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(false)}>지도</Button>
                      <Button className={`switchButton ${this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(true)}>지적도</Button>
                    </div>
                    <input
                      className={`slider ${this.state.isCad && 'hidden'}`}
                      type='range'
                      min='0'
                      max='100'
                      value={this.state.canvasAlpha}
                      onChange={(e) => this.setState({ canvasAlpha: Number(e.target.value) })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`mainLayout ${!this.state.loadFile && 'hidden'}`}>
              <div className='functionalLayout' >
                <div className='fileNameLayout lbrBorder'>
                  <div>CAD file<span className={`${this.state.layers.length > 0 && 'loaded'}`}>{this.state.fileName}</span></div>
                  <input type='text' value={this.state.saveName} onChange={e => this.setState({ saveName: e.target.value })} placeholder='나의 사업영역 제목 입력'></input>
                </div>
                <div className='bodyLayout lbrBorder'>
                  <div className='list'>{this.siteFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.siteFields, FieldType.site)}>+ 대지영역 추가</Button>
                  <div className='list'>{this.roadFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.roadFields, FieldType.road)}>+ 인접도로 추가</Button>
                  <div className='list'>{this.vacancyOutsideFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.vacancyOutsideFields, FieldType.vacancyOutside)}>+ 공지영역 추가</Button>
                  <div className='list'>{this.vacancyInsideFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.vacancyInsideFields, FieldType.vacancyInside)}>+ 특수영역 추가</Button>
                  <Button onClick={this.autoSetting}>자동 추가</Button>
                </div>
                <div className='saveLayout lbrBorder TwoButtonHeight'>
                  <Button className='navyButton' onClick={() => this.addSiteAreaToMap()}>사업영역 맵 표시</Button>
                  <Button className='sideMargin' onClick={() => this.showModal('나의 사업영역 저장', ['나의 사업영역을 저장하시겠습니까?'], buttonNum.twoButton, this.checkSaveState)}>나의 사업영역 저장</Button>
                  <div className='extraText'><span>서비스 이용약관</span><span className='end'>Copyright © 2019 BUILDIT</span></div>
                </div>
              </div>

              <div className='layerLayout' hidden={this.state.showLayer}>
                <div className='arrowDiv'><ForwardArrow className='forwardArrow' onClick={e => this.setState({ showLayer: true })} /></div>
                <div className='layerList' onMouseLeave={() => mouseOutLayerTag(this.state.layers)}>
                  {this.state.layers!.map(l =>
                    <Button key={l.name} className={`${l.selected && 'selected'} ${l.errorLayer && 'error'}`} onMouseOver={() => mouseOverLayerTag(this.state.layers, l)} onClick={() => this.setSelectLayer(l)}>{`${l.name}`}</Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
