import React, { Component, Fragment, ChangeEvent } from 'react';
import SessionComponent from './SessionComponent';
import { TextField, FormControl, Button, FilledInput, Checkbox, CircularProgress } from '@material-ui/core';
import { UserJS } from '@teneleven/protocols-ts-web/lib/user';
import { BuilditPropsBase } from './Utils';
import App from './App';

import './css/RegistrationUser.scss';
import { Link } from 'react-router-dom';
import { default as _ } from 'lodash';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import AWS from 'aws-sdk';
import { User } from './model/User';
import AWSModule from './AWSModule';
import { BrFlrOulnInfo } from '@teneleven/protocols-ts-web/lib/public_api_pb';
import BuilditInput, { InputAttr } from './BuilditInput';

import CheckIcon from '@material-ui/icons/Check';
import Modal, { ModalOptions } from './Modal';
import axios from 'axios';
import EmailConfirm from './UnconfirmedUser';

import Axios from 'axios';

import jquery from 'jquery';
import Tooltip from './Tooltip';
const $ = jquery;

interface RegistrationUserProps extends BuilditPropsBase {

  
}
interface RegistrationUserState {
  email: string,
  password: string,
  passwordRepeat: string,
  name: string,
  company: string,
  contactNumber: string,
  policy: {
    all: boolean,
    use: boolean,
    personalInfo: boolean,
    optionalPersonalInfo: boolean
  },
  registerSuccess: boolean,
  passwordRequiredList: Array<{key: string, checked: boolean, regex: RegExp}>,
  emailInput: InputAttr,
  passwordInput: InputAttr,
  repeatPasswordInput: InputAttr,
  modal: {
    open: boolean,
    option?: ModalOptions,
  },
  loading: boolean,
}

export default class RegistrationUser extends SessionComponent<RegistrationUserProps, RegistrationUserState> {
  state: RegistrationUserState = {
    email: "",
    password: "",
    passwordRepeat: "",
    name: "",
    company: "",
    contactNumber: "",
    policy: {
      all: false,
      use: false,
      personalInfo: false,
      optionalPersonalInfo: false,
    },
    registerSuccess: false,
    passwordRequiredList: [
      {key: "대문자", checked: false, regex: /[A-Z]/},
      {key: "소문자", checked: false, regex: /[a-z]/},
      {key: "숫자", checked: false, regex: /[0-9]/},
      {key: "특수문자", checked: false, regex: /[!@#$%^\&\*\(\)\-\+_=]/},
      {key: "8글자이상", checked: false, regex: /.{8}/},
    ],
    emailInput: {
      requiredCheck: false,
      error: false,
    },
    passwordInput: {
      requiredCheck: false,
      error: false,
    },
    repeatPasswordInput: {
      requiredCheck: false,
      error: false,
    },
    modal: {
      open: false,
    },
    loading: false,
  }

  refs: any;

  componentWillMount = () => {
    if (this.props.session) {
      this.props.logout && this.props.logout();
    }
  }
  componentDidUpdate = (pp: Readonly<RegistrationUserProps>, ps: Readonly<RegistrationUserState>) => {
    if (!_.isEqual(this.state.password, ps.password)) {
      this.setState({
        passwordRequiredList: this.state.passwordRequiredList.map(v => {
          v.checked = Boolean(this.state.password.match(v.regex));
          return v;
        }),
      })
    }
  }
  

  render = () => {
    return (
      <div className="RegistrationUser buildit-template">
        {
          this.state.loading && 
          <div className="dim">
            <CircularProgress className="circular-progress" />
          </div>
        }
        <div className="register-wrapper">
          <Link to="/login" style={{textDecoration: "none"}}>
            <img src="/img/buildit_platform_logo.svg" style={{height: "25px"}} className="logo" />
          </Link>
          <div className="register-form">
            <div className="header">
              <div>빌드잇</div>
              <div>회원가입</div>
            </div>
            {
              <Fragment>
                <div className="input-wrap">
                  <RegisterInput
                    id="register-email"
                    className="register-input"
                    onChange={(e: React.ReactText) => this.setState({
                      email: e as string, 
                      emailInput: {
                        error: false,
                        requiredCheck: false,
                        msg: "",
                      }
                    })}
                    value={this.state.email} autoFocus={true} placeholder="이메일 (필수)" 
                    required={true} onBlur={this.checkEmail}
                    requiredCheck={this.state.emailInput.requiredCheck}
                    error={this.state.emailInput.error}
                    errMsg={this.state.emailInput.msg}
                  />
                  <RegisterInput
                    id="register-password"
                    className="register-input"
                    onBlur={this.checkPassword}
                    onChange={(e: React.ReactText) => this.setState({ 
                      password: e as string,
                      passwordInput: {
                        error: false,
                        requiredCheck: false,
                      }
                    })}
                    value={this.state.password} placeholder="비밀번호 (필수)"
                    required={true} type="password" requiredList={this.state.passwordRequiredList}
                    requiredCheck={
                      this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0
                    }
                    error={this.state.passwordInput.error} errMsg={this.state.passwordInput.msg}
                    capsLockTooltip={true}
                  />
                  <RegisterInput
                    id="register-password-repeat"
                    className="register-input"
                    onChange={(e: React.ReactText) => this.setState({
                      passwordRepeat: e as string,
                      repeatPasswordInput: {
                        error: false,
                        msg: undefined,
                        requiredCheck: false
                      }
                    })}
                    value={this.state.passwordRepeat} placeholder="비밀번호 확인 (필수)" 
                    required={true} type="password"
                    requiredCheck={
                      this.state.passwordRequiredList && this.state.passwordRequiredList.length > 0 &&
                      this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0 &&
                      this.state.password === this.state.passwordRepeat
                    }
                    error={this.state.repeatPasswordInput.error}
                    errMsg={this.state.repeatPasswordInput.msg}
                    onBlur={this.checkRepeatPassword}
                    capsLockTooltip={true}
                  />
                  {/* <div style={{ color: "#999", fontSize: "12px", marginTop: "20px" }}>
                    선택항목
                  </div>
                  <RegisterInput
                    className="register-input"
                    onChange={(e: React.ReactText) => this.setState({ name: e as string})}
                    value={this.state.name} placeholder="이름 또는 기업명" 
                  />
                  <RegisterInput
                    className="register-input"
                    onChange={(e: React.ReactText) => this.setState({ company: e as string})}
                    value={this.state.company} placeholder="소속회사" 
                  />
                  <RegisterInput
                    className="register-input"
                    onChange={(e: React.ReactText) => this.setState({ contactNumber: e as string})}
                    value={this.state.contactNumber} placeholder="연락처" 
                  /> */}

                </div>
                <div className="policy-wrap">
                  <div className="all wrap">전체 동의합니다. 
                    <Checkbox 
                      className={`m-l-a m-r-md checkbox ${this.state.policy.all && "checked" || ""}`} checked={this.state.policy.all} 
                      onClick={e => {
                        if (this.state.policy.all) {
                          this.setState({ policy: { personalInfo: false, use: false, all: false, optionalPersonalInfo: false }});
                        } else {
                          this.setState({ 
                            policy: {
                              personalInfo: true,
                              use: true,
                              all: true,
                              optionalPersonalInfo: this.optionalFieldCheck()
                            }
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="use wrap">이용 약관 동의 (필수)
                    <div className="action m-l-a m-r-md">
                      <div onClick={e => this.setModal(true, {
                        title: "이용 약관",
                        color: "DARK",
                        negative: () => { this.setModal(false) },
                        positive: () => { 
                          this.setModal(false);
                          this.setState({ 
                            policy: {
                              all: (this.state.policy.personalInfo && (this.state.policy.optionalPersonalInfo === this.optionalFieldCheck())),
                              use: true,
                              personalInfo: this.state.policy.personalInfo,
                              optionalPersonalInfo: this.state.policy.optionalPersonalInfo,
                            }
                          })
                        },
                        positiveTitle: "동의",
                        type: "NORMAL",
                        content: policy.use
                      })}>
                        내용보기
                      </div>
                      {/* <Link to="/policy/a" style={{color: "#ccc"}}>내용보기</Link> */}
                      <Checkbox 
                        className={`m-l-md checkbox ${this.state.policy.use && "checked" || ""}`} checked={this.state.policy.use} 
                        onChange={e => {
                          this.setState({
                            policy: {
                              all: (this.state.policy.personalInfo && e.target.checked && (!this.optionalFieldCheck() || this.state.policy.optionalPersonalInfo)) ? true: false,
                              personalInfo: this.state.policy.personalInfo,
                              use: e.target.checked,
                              optionalPersonalInfo: this.state.policy.optionalPersonalInfo
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="personal wrap">개인정보 수집 및 이용 (필수)
                    <div className="action m-l-a m-r-md">
                      <div onClick={e => this.setModal(true, {
                        title: "개인정보 수집 및 이용 (필수)",
                        color: "DARK",
                        negative: () => { this.setModal(false) },
                        positive: () => { 
                          this.setModal(false) 
                          this.setState({ 
                            policy: {
                              all: (this.state.policy.use && (this.state.policy.optionalPersonalInfo === this.optionalFieldCheck())),
                              personalInfo: true,
                              use: this.state.policy.use,
                              optionalPersonalInfo: this.state.policy.optionalPersonalInfo
                            }
                          })
                        },
                        positiveTitle: "동의",
                        type: "NORMAL",
                        content: policy.personal
                      })}>
                        내용보기
                      </div>
                      <Checkbox 
                        className={`m-l-md checkbox ${this.state.policy.personalInfo && "checked" || ""}`} checked={this.state.policy.personalInfo} 
                        onChange={e => {
                          this.setState({
                            policy: { 
                              all: (this.state.policy.use  && e.target.checked && (!this.optionalFieldCheck() || this.state.policy.optionalPersonalInfo)) ? true: false,
                              personalInfo: e.target.checked,
                              use: this.state.policy.use,
                              optionalPersonalInfo: this.state.policy.optionalPersonalInfo
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="personal2 wrap">개인정보 수집 및 이용 (선택)
                    <div className="action m-l-a m-r-md">
                      <div onClick={e => this.setModal(true, {
                        title: "개인정보 수집 및 이용 (선택)",
                        color: "DARK",
                        negative: () => { this.setModal(false) },
                        positive: () => { 
                          this.setModal(false) 
                          this.setState({ 
                            policy: {
                              all: (this.state.policy.use && this.state.policy.personalInfo),
                              personalInfo: this.state.policy.personalInfo,
                              use: this.state.policy.use,
                              optionalPersonalInfo: true,
                            }
                          })
                        },
                        positiveTitle: "동의",
                        type: "NORMAL",
                        content: policy.personalOption
                      })}>
                        내용보기
                      </div>
                      <Checkbox 
                        className={`m-l-md checkbox ${this.state.policy.optionalPersonalInfo && "checked" || ""}`} checked={this.state.policy.optionalPersonalInfo} 
                        onChange={e => {
                           this.setState({
                            policy: { 
                              all: this.optionalFieldCheck() === true ? (this.state.policy.use && this.state.policy.personalInfo && e.target.checked) : (this.state.policy.use && this.state.policy.personalInfo),
                              personalInfo: this.state.policy.personalInfo,
                              use: this.state.policy.use,
                              optionalPersonalInfo: e.target.checked
                            }
                          })
                        }}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="sign-up-wrap">
                  <Button onClick={e => this.registerUser()} disableRipple={true} 
                    className={`bg-navy btn btn-run btn-large sign-up-btn`}
                    >SIGN UP</Button>
                </div>
                <div className="login-wrap">
                  <Link to="/login" style={{textDecoration: "none"}}>
                    <Button disableRipple={true} style={{width: "360px", color: "#ccc", height: "40px", marginTop: "10px"}}>LOGIN</Button>
                  </Link>
                </div>
              </Fragment>
            }
          </div>
          <div className="footer">
            <a href="/termofservice?type=개인%20정보%20처리%20방침">개인정보보호</a>&nbsp;|&nbsp;<a href="/termofservice?type=서비스%20이용%20약관">이용약관</a>
            <div className="m-l-a">Copyright © 2019 BUILDIT</div>
          </div>
        </div>
        {
          this.state.modal.open &&
          <Modal
            open={this.state.modal.open}
            color="DARK"
            type="MOBILE"
            positive={this.state.modal.option && this.state.modal.option.positive}
            negative={this.state.modal.option && this.state.modal.option.negative}
            title={this.state.modal.option && this.state.modal.option.title}
            negativeTitle={this.state.modal.option && this.state.modal.option.negativeTitle}
            positiveTitle={this.state.modal.option && this.state.modal.option.positiveTitle}
            content={this.state.modal.option && this.state.modal.option.content}
          />
        }
      </div>
    );
  }

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      modal: {
        open: open,
        option: options
      }
    })
  }

  checkPassword = () => {
    if (this.state.passwordRequiredList.findIndex(e => e.checked === false) > -1) {
      this.setState({
        passwordInput: {
          error: true,
          requiredCheck: false,
        }
      })
      return false;
    } else {
      return true;
    }
  }
  checkRepeatPassword = () => {
    // 첫 조건이 만족하지 않은 경우 ( 윗 비밀번호 )
    if (this.state.passwordRequiredList.findIndex(e => e.checked === false) > -1) {
      return false;
    }


    let error = true;
    if (this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0 && this.state.password === this.state.passwordRepeat) {
      error = false;
    }

    this.setState({
      repeatPasswordInput: {
        error: error,
        requiredCheck: !error,
        msg: error && "입력한 비밀번호가 일치하지 않습니다" || undefined
      }
    })
    return !error;
  }

  checkEmail = async() => {
    const emailReg = new RegExp(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i);
    if (Boolean(this.state.email.match(emailReg))) {

      let isExistEmail = false;
      const emailCheckUrl = `${App.APIG_URL}/CheckEmailExists`;
      const r = await axios.post(emailCheckUrl, { email: this.state.email });
      
      if (r.status === 200) {
        isExistEmail = r.data.value;
        if (isExistEmail) {
          this.setState({
            emailInput: {
              error: true,
              requiredCheck: this.state.emailInput.requiredCheck,
              msg: "이미 존재하는 이메일 입니다"
            }
          })
          return false;
        } else {
          this.setState({
            emailInput: {
              error: false,
              requiredCheck: true,
              msg: ""
            }
          })
          return true;
        }
      } else {
        this.setState({
          emailInput: {
            error: false,
            requiredCheck: true,
            msg: ""
          }
        }, () => {
          alert("예기치 못한 오류로 잠시후 이용해주세요");
        })
        return false;
      }
    } else if (this.state.email.length > 0) {
      this.setState({
        emailInput: {
          error: true,
          requiredCheck: this.state.emailInput.requiredCheck,
          msg: "이메일 형식을 지켜주세요"
        }
      })
      return false;
    } else {
      return false;
    }
  }

  registerUser = async () => {
    this.setState({
      loading: true
    }, async () => {
      if (!(await this.checkEmail())) {
        $("#register-email").focus();
        this.setState({ loading: false });
        return;
      }

      if (!(await this.checkPassword())) {
        $("#register-password").focus();
        this.setState({ loading: false });
        return;
      } 

      if (!(await this.checkRepeatPassword())) {
        this.setState({ loading: false });
        $("#register-password-repeat").focus();
        return;
      }

      if (!this.state.policy.all) {

        let msg = "";
        if (!this.state.policy.use || !this.state.policy.personalInfo) {
          msg = "이용 약관(필수) 및 개인정보 수집 및 이용(필수)에 동의하셔야 회원가입이 가능합니다";
        } else if (this.optionalFieldCheck() && this.state.policy.optionalPersonalInfo === false) {
          msg = "선택 항목 입력시, 개인정보 수집 및 이용(선택)을 동의하셔야 회원가입이 가능합니다.";
        }

        this.setModal(true, {
          title: "약관 동의",
          color: "DARK",
          negative: () => { this.setModal(false) },
          positive: () => { this.setModal(false) },
          type: "NORMAL",
          content: <div>{msg}</div>
        });
        this.setState({ loading: false });
        return;
      }


      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: App.USER_POOL_ID,
        ClientId: App.COGNITO_CLIENT_ID,
      });

      const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "email",
        Value: this.state.email,
      });

      userPool.signUp(this.state.email, this.state.password, [attributeEmail], [], async (e: any, r: any) => {
        if (e) {
          alert(this.signUpError(e));
        } else {
          this.setState({ registerSuccess: true }, async () => {
            const user: User = {
              id: r!.userSub,
              email: this.state.email,
              company: this.state.company || undefined,
              contact_number: this.state.contactNumber || undefined,
              name: this.state.name || undefined,
              optional_consent: this.state.policy.optionalPersonalInfo,
              point: 0,
              service_point: 0,
            };

            const dr = await Axios.post(`${App.APIG_URL}/RegisterRequest`, user);

            // @ts-ignore
            this.props.history.push(`/user/unconfirmed?email=${this.state.email}&t=register`)
          })
        }
      });
    })
    
  }

  signUpError = (e: {code: string, message: string, name: string}) => {
    let msg = "";
    switch (e.code) {
      case "UsernameExistsException":
        msg = "이미 가입된 이메일 입니다.";
        break;
      default:
        msg = "예기치 못한 에러입니다. 잠시후 다시 시도해 주시기 바랍니다.";
        break;
    }

    return msg;
  }

  optionalFieldCheck = () => {
    return (this.state.company.length > 0 || this.state.name.length > 0 || this.state.contactNumber.length > 0);
  }


}


export interface RegisterInputProps {
  className?: string;
  type?: "text" | "password";
  required?: boolean;
  requiredCheck?: boolean;
  requiredList?: Array<{ key: string, checked: boolean }>;
  placeholder?: string; 
  value: React.ReactText;
  onChange: (e: React.ReactText) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  error?: boolean;
  errMsg?: string;
  autoFocus?: boolean;
  id?: string;
  length?: number;
  pattern?: string;
  capsLockTooltip?: boolean;
}
export interface RegisterInputState {
  type?: "text" | "password";
  required?: boolean;
  capsLoack: boolean;
  focused: boolean;
}

export class RegisterInput extends Component<RegisterInputProps, RegisterInputState> {
  state : RegisterInputState = {
    capsLoack: false,
    focused: false,
  }

  onFocus = () => {
    this.setState({focused: true});
    this.props.onFocus && this.props.onFocus();
  }

  onBlur = () => {
    this.setState({focused: false});
    this.props.onBlur && this.props.onBlur();
  }

  componentWillMount = () => {
    window.addEventListener("keyup", e => {
      if (e.getModifierState("CapsLock")) {
        this.setState({capsLoack: true});
      } else {
        this.setState({capsLoack: false});
      }
    })
  }

  render() {
    return (
      <div className={`RegisterInput ${this.props.className && this.props.className || ""}`} onBlur={this.onBlur} onFocus={this.onFocus}>
        {
          this.props.required && this.props.requiredList && this.props.requiredList.length > 0 &&
          <div className="required-list">
          {
            this.props.requiredList.map(r => {
              return (
                <div className={`required-item ${r.checked}`}> {r.key} </div>
              )
            })
          }
          </div>
        }
        
        <BuilditInput
          id={this.props.id}
          autoFocus={this.props.autoFocus}
          value={this.props.value}
          type={this.props.type}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          errorMsg={this.props.errMsg}
          error={this.props.error}
          length={this.props.length}
          pattern={this.props.pattern}
          endAdornment={
            this.props.required &&
            <Fragment>
              <CheckIcon className={`icon ${this.props.error && "error" || ""} ${this.props.requiredCheck}`} />
            </Fragment> || undefined
          }
          onKeyDown={this.props.onKeyDown && this.props.onKeyDown}
        />
        <Tooltip msg="Caps lock이 켜졌습니다." on={this.state.capsLoack && this.state.focused && this.props.capsLockTooltip} place="right"/>
        
      </div>
    )
  }

}



// 따로 컴포넌트화 
const policy = {
  use: 
  <div>
    <div>제 1장 총칙</div>
    <div>제 1조 목적</div>
    <div>이 약관은 (주)텐일레븐(이하 "회사"라 한다)이 운영하는 인터넷 사이트 및 PC 어플리케이션(이하 "빌드잇"라 한다)에서 제공하는 제반 서비스의 이용과 관련하여 "회사"와 "이용자"의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</div>
    <div>제 2조 정의</div>
    <div>1.	빌드잇: "회사"가 컴퓨터 등 정보통신설비를 이용하여 서비스를 제공할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 인터넷 사이트 및 PC 애플리케이션을 운영하는 사업자의 의미로도 사용합니다.</div>
    <div>2.	이용자: "빌드잇"에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 받는 "회원" 또는 "비회원"을 말합니다.</div>
    <div>3.	회원: "빌드잇"에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 받는 자를 말합니다.</div>
    <div>4.	비회원: 회원으로 가입하지 않고 "회사"가 제공하는 서비스를 이용하는 자를 말합니다.</div>
    <div>5.	아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인한 이메일주소를 말합니다.</div>
    <div>6.	비밀번호: 회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 설정하고 회사가 승인한 문자나 숫자의 조합을 말합니다.</div>
    <div>7.	게시판: 그 명칭, 형태, 위치와 관계없이 회원 및 비회원 이용자에게 공개할 목적으로 부호•문자•음성•음향 •화상•동영상 등의 정보 (이하 "게시물"이라 합니다)를 회사가 제공하는 서비스상의 가상공간을 말합니다.</div>
    <div>8.	포인트: “빌드잇”에서 통용되는 가상의 지불 수단으로 서비스를 이용하는데 사용이 됩니다. </div>
    <div>9.	보너스 포인트: “포인트”와 같은 가상의 지불 수단으로 “포인트” 구매 시 또는 이벤트 시 보너스로 지급되는 포인트입니다.</div>
    <div>위 항에서 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래 관행에 의합니다.</div>
    <div>제 3조 약관 등의 명시와 설명 및 개정</div>
    <div>1.	"회사"는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 "빌드잇"의 인터넷 사이트에 게시합니다. 다만, 약관의 내용은 이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.</div>
    <div>2.	"회사"는 "약관 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</div>
    <div>3.	"회사"가 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 이용자가 알기 쉽도록 표시하여 공지합니다. 변경된 약관은 공지된 시점부터 그 효력이 발생하며, 이용자는 약관이 변경된 후에도 본 서비스를 계속 이용함으로써 변경 후의 약관에 대해 동의를 한 것으로 간주됩니다.</div>
    <div>제 4조 약관의 해석</div>
    <div>1.	"회사"는 서비스운영을 위해 별도의 운영정책을 마련하여 운영할 수 있으며, "회사"는 "빌드잇" 인터넷 사이트에 운영정책을 사전 공지 후 적용합니다.</div>
    <div>2.	본 약관에서 정하지 아니한 사항이나 해석에 대해서는 별도의 운영정책, 관계법령 또는 상관례에 따릅니다.</div>
    <div>제 5조 서비스의 제공 및 변경</div>
    <div>1.	"회사"는 다음과 같은 서비스를 제공합니다.</div>
    <div>1.	건축설계 서비스</div>
    <div>2.	건축설계 컨설팅 보고서 서비스</div>
    <div>3.	기타 "빌드잇"의 이용자를 위하여 제공하는 서비스</div>
    <div>4.	유료로 제공하는 부가 서비스</div>
    <div>2.	"회사"가 제공하는 “포인트”, “보너스 포인트”는 원칙적으로 양도양수가 불가합니다.</div>
    <div>3.	"회사"가 제공하는 서비스의 내용을 기술적 사양의 변경 등의 이유로 변경할 경우에는 그 사유를 이용자에게 통지하거나, 이용자가 알아볼 수 있도록 공지사항으로 게시합니다.</div>
    <div>제 6조 서비스의 중단</div>
    <div>1.	"회사"는 컴퓨터 등 정보 통신 설비의 보수 점검, 교체 및 고장, 통신의 두절, 천재지변 등의 사유가 발생한 경우 에는 서비스의 제공을 제한하거나 일시적으로 중단할 수 있습니다.</div>
    <div>2.	사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 이용자에게 통지하거나 이용자가 알아볼 수 있도록 공지 사항으로 게시합니다.</div>
    <div>제 7조 회원에 대한 통지</div>
    <div>1.	"회사"는 이메일, 이동전화 단문메시지서비스(SMS) 등으로 회원에게 통지할 수 있습니다.</div>
    <div>2.	"회사"는 불특정다수 회원에 대한 통지의 경우 공지사항으로 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.</div>
    <div>제 2장 이용계약 및 정보보호</div>
    <div>제 8조 회원가입 및 회원정보의 변경</div>
    <div>1.	"이용자"는 회사가 정한 가입 양식에 따라 회원 정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원 가입을 신청합니다.</div>
    <div>2.	"회사"는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 "회원"으로 등록합니다.</div>
    <div>1) 등록 내용에 허위, 기재누락, 오기가 있는 경우</div>
    <div>2) 가입 신청자가 이전에 회원 자격을 상실한 적이 있는 경우(다만 회원 자격 상실 후 회사가 필요하다고 판단하여 회원 재가입에 대한 승낙을 얻은 경우에는 예외로 합니다.)</div>
    <div>3) "회사"로부터 회원 자격 정지 조치 등을 받은 회원이 그 조치 기간 중에 이용 계약을 임의 해지하고 재가입 신청을 하는 경우</div>
    <div>4) 회원으로 등록하는 것이 "빌드잇"의 기술상 현저히 지장이 있다고 판단되는 경우</div>
    <div>5) 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</div>
    <div>3.	회원 가입 계약의 성립 시기는 "회사"의 승낙이 "회원"에게 도달한 시점으로 합니다.</div>
    <div>4.	"회원"은 회원 가입 신청 및 이용 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 그 변경사항을 알려야 합니다.</div>
    <div>5.	제4항의 변경 사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</div>
    <div>6.	회원 가입은 반드시 본인의 진정한 정보를 통하여 가입할 수 있으며 "회사"는 "회원"이 등록한 정보에 대하여 확인 조치를 할 수 있습니다. "회원"은 "회사"의 확인 조치에 대하여 적극 협력하여야 하며, 만일 이를 준수하지 아니 할 경우 "회사"는 등록한 정보가 부정한 것으로 간주하여 처리할 수 있습니다.</div>
    <div>7.	"회사"는 "회원"을 등급 별로 구분하여 이용시간, 이용회수, 포인트 구매, 서비스 메뉴, 매물 등록 건 수 등을 세분하여 서비스 이용에 차등을 둘 수 있습니다.</div>
    <div>8.	"회원"은 1인 1계정에 한하여 서비스 이용이 가능하며, 중복 가입 시 서비스 이용이 불가할 수 있습니다.</div>
    <div>제 9조 이용 계약의 종료</div>
    <div>1.	회원의 해지</div>
    <div>1) "회원"은 회사에 언제든지 해지 의사를 통지할 수 있고 회사는 특별한 사유가 없는 한 이를 즉시 수락하여야 합니다. 다만, "회원"은 해지의사를 통지하기 전에 모든 진행중인 절차를 완료, 철회 또는 취소해야만 합니다. 이 경우 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다.</div>
    <div>2) "회원"이 발한 의사표시로 인해 발생한 불이익에 대한 책임은 회원 본인이 부담하여야 하며, 이용계약이 종료되면 "회사"는 "회원"에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.</div>
    <div>3) "회원"의 의사로 이용계약을 해지한 후, 추후 재이용을 희망할 경우에는 재이용 의사가 "회사"에 통지되고, 이에 대한 "회사"의 승낙이 있는 경우에만 서비스 재이용이 가능합니다.</div>
    <div>4) 본 항에 따라 해지를 한 "회원"은 이 약관이 정하는 회원가입절차와 관련조항에 따라 신규 회원으로 다시 가입할 수 있습니다. 다만, "회원"이 중복참여가 제한된 이벤트 중복 참여 등 부정한 목적으로 회원탈퇴 후 재이용을 신청하는 경우 "회사"는 가입을 일정기간 동안 제한할 수 있습니다.</div>
    <div>5) 본 항에 따라 해지를 한 이후에는 재가입이 불가능하며, 모든 가입은 신규가입으로 처리됩니다.</div>
    <div>2.	회사의 해지</div>
    <div>1) "회사"는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다.</div>
    <div>(1) 다른 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 공서양속에 위배되는 행위를 한 경우</div>
    <div>(2) "회사"가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우</div>
    <div>(3) 제 8조 제 2항의 승낙거부 사유가 추후 발견된 경우</div>
    <div>(4) "회사"가 정한 서비스 운영정책을 이행하지 않거나 위반한 경우</div>
    <div>(5) 기타 "회사"가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우</div>
    <div>2) "회사"가 해지를 하는 경우 "회사"는 "회원"에게 이메일, 전화, 기타의 방법을 통하여 해지 사유를 밝혀 해지 의사를 통지합니다. 이용계약은 "회사"의 해지의사를 "회원"에게 통지한 시점에 종료됩니다.</div>
    <div>3) 본 항에서 정한 바에 따라 이용계약이 종료될 시에는 "회사"는 "회원"에게 부가적으로 제공한 각종혜택을 회수할 수 있습니다. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 회원이 책임을 부담하여야 하고, "회사"는 일체의 책임을 지지 않습니다.</div>
    <div>4) 본 항에서 정한 바에 따라 이용계약이 종료된 경우에는, "회원"의 재이용 신청에 대하여 "회사"는 이에 대한 승낙을 거절할 수 있습니다.</div>
    <div>3.	이용계약의 종료 시, 제공된 서비스에 대한 환불은 본 규정 제13조에 따릅니다.</div>
    <div>4.	"회원"이 계약을 해지하는 경우, 관련법 및 "개인정보취급방침"에 따라 "회사"가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 "회원"의 모든 데이터는 소멸됩니다.</div>
    <div>제 10조 개인정보보호</div>
    <div>1.	"회사"는 "이용자"의 회원가입시 서비스 제공에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.</div>
    <div>1) 이메일주소</div>
    <div>2) 비밀번호</div>
    <div>2.	"회사"가 "이용자"의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.</div>
    <div>3.	제공된 개인정보는 당해 이용자의 동의없이 목적 외의 이용이나 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.</div>
    <div>1) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</div>
    <div>2) 도용방지를 위하여 본인확인에 필요한 경우</div>
    <div>3) 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</div>
    <div>4.	"회사"가 제2항과 제3항에 의해 "이용자"의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 [정보통신망 이용촉진 및 정보보호 등에 관한 법률] 제22조 제2항이 규정 한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</div>
    <div>5.	"회사"는 "이용자"의 개인정보를 보호하기 위해 "개인정보취급방침"을 수립하고 개인정보보호책임자를 지정하여 이를 게시하고 운영합니다.</div>
    <div>6.	"이용자"는 언제든지 "회사"가 갖고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "회사"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에 "회사"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</div>
    <div>7.	"회사" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. 다만, 아래의 경우에는 회원 정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원 정보를 그 보관의 목적으로만 이용합니다.</div>
    <div>1) 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 "회사"는 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다.</div>
    <div>2) "회사"가 이용계약을 해지하거나 "회사"로부터 서비스 이용정지조치를 받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로 이용계약종료 후 5년간 성명, 이메일주소를 비롯하여 이용계약 해지와 서비스 이용정지와 관련된 정보 등의 필요정보를 보관합니다.</div>
    <div>8.	"회사"는 새로운 업체가 제휴사 또는 제휴영업점의 지위를 취득할 경우 제7조 2항에서 정한 것과 같은 방법을 통하여 공지합니다. 이 때 회원이 별도의 이의제기를 하지 않을 경우 "빌드잇" 서비스 제공이라는 필수적인 목적을 위해 해당 개인 정보 제공 및 활용에 동의한 것으로 간주합니다.</div>
    <div>9.	모든 "이용자"는 "빌드잇"로부터 제공받은 정보를 다른 목적으로 이용하거나 타인에게 유출 또는 제공해서는 안되며, 위반 사용으로 인한 모든 책임을 부담해야 합니다. 또한 "회원"은 자신의 개인정보를 책임 있게 관리하여 타인이 회원의 개인정보를 부정하게 이용하지 않도록 해야 합니다.</div>
    <div>10.	"회사"는 "회원"의 개인정보를 보호하기 위해 [정보통신망 이용촉진 및 정보보호 등에 관한 법률] 상의 개인정보 유효기간제도에 따라 1년간 미접속한 회원의 개인정보를 파기 또는 분리하여 별도로 저장/관리하며(휴면계정), "회원"의 별도의 요청이 없는 한 사용이 제한됩니다.</div>
    <div>제 3장 서비스의 이용</div>
    <div>제 11조 필지 등에 관한 정보제공 서비스</div>
    <div>1.	필지 등에 관한 정보제공 서비스는 "회사"가 이용자 스스로 해당 정보를 확인 및 이용할 수 있도록 관련 정보를 제공하는 것입니다.</div>
    <div>2.	“회사”는 “빌드잇” 인터넷 사이트 및 PC 애플리케이션 내에서 제공하는 모든 정보에 대해서 정확성이나 신뢰성이 있는 정보를 제공하기 위해 노력하며, 신고 받은 잘못된 정보의 정정, 삭제 조치를 하는 등의 서비스 관리자로서의 책임을 부담합니다.</div>
    <div>3.	"회사"는 "빌드잇" 인터넷 사이트 및 PC 애플리케이션을 통해 제공되는 정보의 내용을 수정할 의무를 지지 않으나, 필요에 따라 개선할 수는 있습니다.</div>
    <div>제 12조 정보의 제공 및 광고의 게재</div>
    <div>1.	"회사"는 "회원"이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항, 서비스 화면, 전자 우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래 관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 위 정보 제공에 대하여 수신 거절을 할 수 있습니다.</div>
    <div>2.	"회사"는 서비스 운영과 관련하여 회사가 제공하는 서비스의 화면 및 홈페이지 등에 광고를 게재할 수 있습니다.</div>
    <div>제 13조 지급방법</div>
    <div>1.	“빌드잇”의 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가능한 방법으로 할 수 있습니다.</div>
    <div>1) 계좌이체</div>
    <div>제 14조 환불 규정</div>
    <div>1.	“회원”이 서비스 이용계약을 취소할 경우 해당 이용계약에 따라 지급된 잔여 “보너스 포인트”는 소멸되며, 현금으로 구매한 “포인트”의 경우 총 결제금액의 10% 위약금을 공제한 후 잔여 “포인트”는 현금으로 환불합니다. "회원"이 폐업 신고한 후 환불 요청하는 경우도 같습니다. </div>
    <div>2.	"회원"의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있습니다.</div>
    <div>3.	위약금이 결제한 금액보다 클 경우 환불이 불가합니다.</div>
    <div>4.	본 약관에서 정하지 않은 환불에 관한 사항은 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령, 지침 또는 상관례에 의합니다.</div>
    <div>제 4장 책임</div>
    <div>제 15조 회사의 의무</div>
    <div>1.	"회사"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 이용자에게 서비스를 제공하는데 최선을 다하여야 합니다.</div>
    <div>2.	"회사"는 "이용자" 상호간의 거래에 있어서 어떠한 보증도 제공하지 않습니다. 이용자 상호간 거래 행위에서 발생하는 문제 및 손실에 대해서 회사는 일체의 책임을 부담하지 않으며, 거래 당사자 간에 직접 해결해야 합니다.</div>
    <div>제 16조 회원의 아이디(ID) 및 비밀번호에 대한 의무</div>
    <div>1.	"아이디(ID)"와 "비밀번호"에 관한 관리책임은 "회원"에게 있습니다.</div>
    <div>2.	"회원"은 자신의 "아이디(ID)" 및 "비밀번호"를 제3자에게 이용하게 해서는 안됩니다.</div>
    <div>3.	"회원"이 자신의 "아이디(ID)" 및 "비밀번호"를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "회사"에 통보하고 "회사"의 안내가 있는 경우에는 그에 따라야 합니다.</div>
    <div>제 17조 이용자의 의무</div>
    <div>1.	"이용자"는 다음 행위를 하여서는 안됩니다. 만약 다음 각 호와 같은 행위가 확인되면 회사는 해당 "이용자"에게 서비스 이용에 대한 제재를 가할 수 있으며 민형사상의 책임을 물을 수 있습니다.</div>
    <div>1) 회사 서비스의 운영을 고의 및 과실로 방해하는 경우</div>
    <div>2) 신청 또는 변경 시 허위 내용의 등록</div>
    <div>3) 타인의 정보 도용</div>
    <div>4) "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</div>
    <div>5) "회사"의 저작권 등 지적재산권에 대한 침해</div>
    <div>6) 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</div>
    <div>2.	“회사”는 전항의 규정된 위반 행위를 하는 회원에 대해 서비스 일시 중단 등의 조치를 취할 수 있으며, 필요한 경우 이에 대한 시정을 요구할 수 있습니다. 특히, 회원이 시정을 요구받은 기간 내에 시정을 하지 아니한 경우, 동일한 위반 행위를 반복할 경우 또는 다수의 위반 행위가 확인된 경우에 회사는 회원과의 서비스 이용계약을 해지할 수 있습니다. 단, 이 경우 회사는 회원에게 전화, 이메일, SMS 등의 방법으로 개별 통지합니다.</div>
    <div>3.	"회사"는 회사의 정책에 따라서 회원 간의 차별화된 유료서비스를 언제든지 제공할 수 있습니다. </div>
    <div>제 18조 저작권의 귀속 및 이용제한</div>
    <div>1.	"회원"이 "빌드잇"을 이용하여 작성한 저작물에 대한 저작권 기타 지적 재산권은 "회원"에 귀속합니다.</div>
    <div>2.	"이용자"는 서비스를 이용함으로써 얻은 정보 중 "회사"에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</div>
    <div>제 19조 책임의 한계 등</div>
    <div>1.	"회사"는 무료로 제공하는 정보 및 서비스에 관하여 "개인정보취급방침" 또는 관계 법령의 벌칙, 과태료 규정 등 강행 규정에 위배되지 않는 한 원칙적으로 손해를 배상할 책임이 없습니다.</div>
    <div>2.	“회사”는 천재지변, 불가항력, 서비스용 설비의 보수, 교체, 점검, 공사 등 기타 이에 준하는 사항으로 정보 및 서비스를 제공할 수 없는 경우에 회사의 고의 또는 과실이 없는 한 이에 대한 책임이 면제됩니다.</div>
    <div>3.	"회사"는 "이용자"의 귀책사유로 인한 정보 및 서비스 이용의 장애에 관한 책임을 지지 않습니다.</div>
    <div>4.	"빌드잇"에서 제공하는 정보에 대한 신뢰 여부는 전적으로 "이용자" 본인의 책임이며, "회사"는 건축 설계 결과물에 대하여 발생하는 어떠한 직접, 간접, 부수적, 파생적, 징벌적 손해, 손실, 상해 등에 대하여 도덕적, 법적 책임을 부담하지 않습니다.</div>
    <div>5.	"빌드잇"을 통하여 노출, 배포, 전송되는 정보를 "이용자"가 이용하여 발생하는 부동산 거래 등에 대하여 "회사"는 어떠한 도덕적, 법적 책임이나 의무도 부담하지 않습니다.</div>
    <div>제 20조 손해배상 등</div>
    <div>1.	"회사"는 "회원"이 서비스를 이용함에 있어 "회사"의 고의 또는 과실로 인해 손해가 발생한 경우에는 민법 등 관련 법령이 규율하는 범위 내에서 그 손해를 배상합니다.</div>
    <div>2.	"회원"이 이 약관을 위반하거나 관계 법령을 위반하여 "회사"에 손해가 발생한 경우에는 "회사"에 그 손해를 배상하여야 합니다.</div>
    <div>제 21조 분쟁해결</div>
    <div>1.	“회사”와 "이용자"간에 서비스 이용과 관련하여 발생한 분쟁에 대해 "이용자"의 피해 구제 신청이 있는 경우에는 공정 거래 위원회 또는 시, 도지사가 의뢰하는 분쟁 조정 기관의 조정에 따를 수 있습니다.</div>
    <div>제 22조 재판권 및 준거법</div>
    <div>1.	"회사"와 "회원"간 제기된 소송은 대한민국법을 준거법으로 합니다.</div>
    <div>2.	"회사"와 "회원"간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.</div>
    <div>본 약관은 2020년 6월 11일부터 적용합니다.</div>
  </div>,
  personal: 
  <div>
    <div>개인정보의 수집 및 이용(필수)</div>
    <div>
      <div>빌드잇 서비스 제공을 위하여 필요한 최소한의 개인정보이므로 동의를 해주셔야 서비스를 이용하실 수 있습니다. </div>
      <div>- 수집하려는 개인 정보의 항목: 이메일 주소, 비밀번호</div>
      <div>- 개인정보의 수집 및 이용 목적: 이용자 식별 및 본인 여부 확인, 회원 관리, 개인식별, 가입의사의 확인, 가입 및 가입횟수 제한, 민원처리, 공지사항 전달</div>
      <div>- 개인정보의 보유기간: 회원 탈퇴 시 까지</div>
    </div>
  </div>,
  personalOption: 
  <div>
    <div>개인정보의 수집 및 이용(선택)</div>
    <div>
      <div>빌드잇 서비스 제공을 위하여 필요한 최소한의 개인정보이므로 동의를 해주셔야 서비스를 이용하실 수 있습니다. </div>
      <div>- 수집하려는 개인 정보의 항목: 이메일 주소, 비밀번호</div>
      <div>- 개인정보의 수집 및 이용 목적: 이용자 식별 및 본인 여부 확인, 회원 관리, 개인식별, 가입의사의 확인, 가입 및 가입횟수 제한, 민원처리, 공지사항 전달</div>
      <div>- 개인정보의 보유기간: 회원 탈퇴 시 까지</div>
    </div>
  </div>,
}
