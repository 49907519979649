import React, { Component, ChangeEvent } from 'react';
import { Scene, reportInformation } from './scene';
import AWSModule from '../AWSModule';
import { getS3Json, getDBData, tm2lonlat } from './DBManager';
import wkx from 'wkx';
import * as THREE from '@teneleven/three';
import _, { forEach } from 'lodash';
import App from '../App';
import queryString from 'query-string';
import { default as moment } from 'moment';
import { ReactComponent as SunIcon } from '../img/Visualizer/icon-sun.svg';
import { ReactComponent as SunIconHover } from '../img/Visualizer/icon-sun-hover.svg';
import { ReactComponent as TopViewIcon } from '../img/Visualizer/icon-top-view.svg';
import { ReactComponent as TopViewIconHover } from '../img/Visualizer/icon-top-view-hover.svg';
import Checkbox from '@material-ui/core/Checkbox';

import InfoIcon from '@material-ui/icons/Info'
import SettingBackIcon from '@material-ui/icons/SettingsBackupRestore'
import { InformationModal } from './InformationModal';
import { DEMData, getDemHeight } from './DEMManager';
import { Modal, ModalProps, buttonNum } from '../CADConverter/Modal';
import { OBJLoader } from '@teneleven/three/examples/jsm/loaders/OBJLoader';

interface ReportIndexInfo {
  successed: boolean;
  reportID: number;
  realReportID: number;
  added: boolean;
}

interface VisualizerProps {
}

interface VisualizerState {
  ServerStage: string,
  ProjectID: number,
  ProjectName: string,
  userProjectID: number,
  index: number[],
  reportIndexList: ReportIndexInfo[],
  // reportList: boolean[],
  // reportIndex: number[],
  dem: THREE.Group;
  demCount: number;
  subBuilding: THREE.Group;
  subBuildingCount: number;
  handle: number;
  siteCenterInScene: THREE.Vector3;
  siteCenterInLonLat: THREE.Vector2;
  centerInTM: THREE.Vector3;
  demFinished: boolean;
  subModelFinished: boolean;
  cameraChange: number;
  siteArea: number;
  siteUseDistrict: string;
  showInfo: boolean;
  showLightControl: boolean;
  lightIndex: number;
  loadingMessage: string;
  loadingProgess: string;
  finished: number;
  sceneSize: number;
  syncCameraPosition: THREE.Vector3;
  syncCameraTarget: THREE.Vector3;
  syncCameraZoom: number;
  reset: boolean;
  address: string;
  buildingCategory: string;
  slider: boolean;
  sunButtonOver: boolean;
  topViewButtonOver: boolean;
  showModal: boolean;
  season: 'spring' | 'summer' | 'fall' | 'winter',
}

export class Visualizer extends Component<VisualizerProps, VisualizerState> {
  state: VisualizerState = {
    ServerStage: App.stage,
    ProjectID: 0,
    ProjectName: '',
    userProjectID: 0,
    index: [],
    dem: new THREE.Group(),
    demCount: 1,
    subBuilding: new THREE.Group(),
    subBuildingCount: 1,
    handle: 1,
    siteCenterInScene: new THREE.Vector3(0),
    centerInTM: new THREE.Vector3(0),
    siteCenterInLonLat: new THREE.Vector2(0),
    demFinished: false,
    subModelFinished: false,
    cameraChange: 0,
    siteArea: 0,
    siteUseDistrict: '',
    showInfo: false,
    showLightControl: true,
    lightIndex: 4,
    loadingMessage: '지형 정보를 불러오는 중입니다.',
    loadingProgess: ' (0/9)',
    finished: 0,
    reportIndexList: [],
    // reportList: [],
    // reportIndex: [],
    sceneSize: 0,
    syncCameraPosition: new THREE.Vector3(0),
    syncCameraTarget: new THREE.Vector3(0),
    syncCameraZoom: 1,
    reset: false,
    slider: false,
    address: '',
    sunButtonOver: false,
    topViewButtonOver: false,
    showModal: true,
    buildingCategory: '',
    season: "winter",
  }

  siteVertsArray: THREE.Vector2[][] = [];
  siteLine: THREE.Line[] = [];
  demData: DEMData[] = [];
  reportInformation: reportInformation[] = [];
  lightMiddleIndex = 4;
  lightTimeText = '12:00';
  subBuildingRange = 500;

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => console.log('test'),
    title: '제목'
  }

  getScenePos = (x: number, y: number) => {
    let newPosX = ((x - this.state.centerInTM.x) / 10);
    let newPosY = -((y - this.state.centerInTM.z) / 10);

    return new THREE.Vector3(newPosX, 0, newPosY);
  }

  componentDidUpdate(previousProps: VisualizerProps, previousState: VisualizerState) {
    if (previousState.siteCenterInScene !== this.state.siteCenterInScene) {
      this.state.subBuilding.position.set(this.state.siteCenterInScene.x, this.state.siteCenterInScene.y, this.state.siteCenterInScene.z);
    }
  }

  generateDEMMesh = async (dem: number[][], x: number, y: number, TMx: number, TMy: number) => {
    let demWidth = 257;
    const geometry = new THREE.PlaneBufferGeometry(demWidth - 1, demWidth - 1, demWidth - 1, demWidth - 1);
    geometry.rotateX(-Math.PI / 2);
    geometry.rotateY(-Math.PI / 2);

    for (let y = 0; y < demWidth; y++) {
      for (let x = 0; x < demWidth; x++) {
        geometry.attributes.position.setY(x + y * demWidth, dem[x][demWidth - y - 1] * 0.1);
      }
    }

    geometry.computeVertexNormals();
    var material = new THREE.MeshPhongMaterial({ color: 0xffffff, shininess: 0, reflectivity: 0, });
    // material.wireframe = true;

    let mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(y, 0, x);
    mesh.receiveShadow = true;
    mesh.castShadow = true;

    await new Promise(async (resolve) => {
      // texture load
      const urn = 'urn::buildit:texture:v0.0.1:tex' + 1024 + '/' + TMx + '_' + TMy + '.png';
      let s3 = await new AWSModule("S3").connect();

      const url = await s3.S3!.getSignedUrl('getObject', {
        Bucket: 'buildit-osd',
        Key: urn,
      });

      let texture = new THREE.TextureLoader().load(url);
      material.map = texture;

      this.state.dem.add(mesh);
      this.setState({
        demCount: this.state.demCount + 1,
        loadingMessage: `지형 정보를 불러오는 중입니다.`,
        loadingProgess: ` (${this.state.demCount}/9)`,
      })
      resolve();
    })
  }

  setSiteLineMesh = async () => {
    await Promise.all(this.siteVertsArray.map(async a => {
      let siteArray: THREE.Vector3[] = [];
      let lineGeo = new THREE.Geometry();
      a.forEach(v => {
        let pos = this.getScenePos(v.x, v.y);
        siteArray.push(new THREE.Vector3(pos.x, getDemHeight(this.demData, v) * 0.1, pos.z));
      });

      siteArray.forEach(s => {
        lineGeo.vertices.push(s);
      });

      var material = new THREE.LineBasicMaterial({
        color: 0xff0000
      });

      this.siteLine.push(new THREE.Line(lineGeo, material));
    }))
  }

  getDEM = async (TMx: number, TMy: number) => {
    let s3 = await new AWSModule("S3").connect();

    const cx = (Math.floor(TMx / 2560)) * 2560;
    const cy = (Math.floor(TMy / 2560)) * 2560 + 1120;

    const dems: number[][] = [];
    let offset = 1;
    for (let i = -offset; i <= offset; i += 1) {
      for (let j = -offset; j <= offset; j += 1) {
        dems.push([cx + 2560 * i, cy + 2560 * j])
      }
    }
    await Promise.all(dems.map(async key => {
      const sr = await s3.S3!.getObject({
        Bucket: 'teneleven-engine-dem',
        // Bucket: 'teneleven-pubdata-dem-v2-apn2',
        Key: `${key[0] + '_' + key[1]}` + '.dem',
      }).promise();
      const vals = _.range(257).map(i => {
        // return _.range(257).map(j => (sr.Body as Buffer).readInt16LE((i * 257 + j) * 2))
        return _.range(257).map(j => (sr.Body as Buffer).readFloatLE((i * 257 + j) * 4))
      });
      this.demData.push({ pos: new THREE.Vector2(key[0], key[1]), value: vals });
      await this.generateDEMMesh(vals, (key[0] - cx) * 0.1, (key[1] - cy) * 0.1, key[0], key[1]);
    }));
    this.state.dem.rotateY(Math.PI / 2);
    this.setState({
      centerInTM: new THREE.Vector3(cx + 1280, 0, cy + 1280),
    }, () => this.setState({
      siteCenterInScene: this.getScenePos(TMx, TMy),
      siteCenterInLonLat: tm2lonlat(new THREE.Vector2(TMx, TMy)),
      demFinished: true,
    }))
    await this.setSiteLineMesh();
  }

  getSubBuilding = async (TMx: number, TMy: number, siteArray: string[]) => {
    let offset = 0;
    const LIMIT = 10000;

    let lambda = await new AWSModule("LAMBDA").connect();
    let s3 = await new AWSModule("S3").connect();

    let loader = new OBJLoader();
    this.state.subBuilding.scale.set(0.1, 0.1, -0.1);
    let subGeo = new THREE.Geometry();
    let count = 0;

    let multipolygonSite: wkx.Polygon[] = [];
    siteArray.forEach(site => {
      let p = wkx.Geometry.parse(site);
      //@ts-ignore
      if (p.polygons) {
        //@ts-ignore
        p.polygons.forEach(cp => {
          multipolygonSite.push(cp as wkx.Polygon);
        });
      }
      else {
        multipolygonSite.push(p as wkx.Polygon);
      }
    });

    // let listData = await getURLData(getURL(this.state.ProjectID, '0/obj/list.txt'));

    let t0 = performance.now();
    await lambda.Lambda!.invoke({
      FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:platform-buildit-BldQuery-r1-v20191011",
      Payload: JSON.stringify({
        table: 'platform-buildit-building-obj-v2',
        type: 'NEAR',
        query: new wkx.MultiPolygon(multipolygonSite).toWkt(),
        range: this.subBuildingRange,
        offset: offset,
        limit: LIMIT,
        exclude: siteArray,
      })
    }).promise().then(async v => {
      App.stage !== "prod" && console.log(v);
      let arrayCount = JSON.parse(v.Payload as string).length;
      let subbuildingArray: any[] = [];
      let cellSize = 1200
      for (let i = 0; i < (arrayCount / cellSize); i++) {
        subbuildingArray.push(JSON.parse(v.Payload as string).slice(cellSize * i, cellSize * (i + 1)))
      }

      for (let i = 0; i < subbuildingArray.length; i++) {
        await Promise.all(subbuildingArray[i].map(async (p: any) => {
          const url = await s3.S3!.getSignedUrl('getObject', {
            Bucket: 'teneleven-pubdata-building-obj-apn2',
            Key: `${p.partition_key}.obj`,
          });
          await new Promise(resolve => {
            loader.load(url,
              (model: THREE.Group) => {
                (model.children[0] as THREE.Mesh).position.set(- TMx, 0, - TMy);
                (model.children[0] as THREE.Mesh).updateMatrix();
                let geo = new THREE.Geometry().fromBufferGeometry((model.children[0] as THREE.Mesh).geometry as THREE.BufferGeometry);
                for (let i = 0; i < geo.faces.length; i++) {
                  let temp = geo.faces[i].a;
                  geo.faces[i].a = geo.faces[i].c
                  geo.faces[i].c = temp;
                }
                geo.computeVertexNormals();

                subGeo.merge(geo, (model.children[0] as THREE.Mesh).matrix);
                count++;

                if (count > cellSize / 3) {
                  this.state.subBuilding.add(this.makeSubMesh(subGeo));
                  count = 0;
                  subGeo = new THREE.Geometry();
                }

                this.setState({
                  subBuildingCount: this.state.subBuildingCount + 1,
                  loadingMessage: `주변 건물 정보를 불러오는 중입니다. `,
                  loadingProgess: `(${this.state.subBuildingCount}/${arrayCount})`,
                })
                resolve();
              },
              (xhr: any) => {
                // console.log(xhr);
              },
              (error: any) => {
                // console.log(error);
              }
            )
          })
        }))
      }
    });
    let t1 = performance.now();
    App.stage !== "prod" && console.log((t1 - t0) / 1000);

    this.state.subBuilding.add(this.makeSubMesh(subGeo));
    App.stage !== "prod" && console.log(this.state.subBuilding.children.length);
    this.setState({ subModelFinished: true });
  }

  getSubbuildingbyList = async (TMx: number, TMy: number, list: any) => {
    let s3 = await new AWSModule("S3").connect();
    let loader = new OBJLoader();
    this.state.subBuilding.scale.set(0.1, 0.1, -0.1);
    let subGeo = new THREE.Geometry();
    let subbuildingArray: any[] = [];
    let cellSize = 400
    for (let i = 0; i < (list.obj.length / cellSize); i++) {
      subbuildingArray.push(list.obj.slice(cellSize * i, cellSize * (i + 1)))
    }

    for (let i = 0; i < subbuildingArray.length; i++) {
      await Promise.all(subbuildingArray[i].map(async (obj: any) => {
        const url = await s3.S3!.getSignedUrl('getObject', {
          Bucket: 'teneleven-engine-result',
          Key: `${this.state.ServerStage}/${this.state.ProjectID}/0/obj/${obj.id}`,
        });
        await new Promise(resolve => {
          loader.load(url, (model: THREE.Group) => {
            (model.children[0] as THREE.Mesh).position.set(- TMx, 0, - TMy);
            (model.children[0] as THREE.Mesh).updateMatrix();
            let geo = new THREE.Geometry().fromBufferGeometry((model.children[0] as THREE.Mesh).geometry as THREE.BufferGeometry);
            for (let i = 0; i < geo.faces.length; i++) {
              let temp = geo.faces[i].a;
              geo.faces[i].a = geo.faces[i].c
              geo.faces[i].c = temp;
            }
            geo.computeVertexNormals();

            subGeo.merge(geo, (model.children[0] as THREE.Mesh).matrix);

            this.setState({
              subBuildingCount: this.state.subBuildingCount + 1,
              loadingMessage: `주변 건물 정보를 불러오는 중입니다. `,
              loadingProgess: `(${this.state.subBuildingCount}/${list.obj.length})`,
            })
            resolve();
          })
        })
      }))
      this.state.subBuilding.add(this.makeSubMesh(subGeo));
      subGeo = new THREE.Geometry();
    }

    this.setState({ subModelFinished: true });
  }

  makeSubMesh = (geo: THREE.Geometry) => {
    let mesh = new THREE.Mesh(geo, new THREE.MeshPhongMaterial({ color: '#ffffff', shininess: 0.5 }));
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh;
  }

  componentDidMount = async () => {
    const qs = queryString.parse(window.location.search);
    // this.setState({
    //   index: Number(qs.rid)
    // }, async () => {
    const rid = qs.rid! instanceof Array ? qs.rid!.map(Number) : [parseInt(qs.rid as string)];
    this.state.index = rid;

    let lambda = await new AWSModule("LAMBDA").connect();
    const r = await App.search({
      "table": "platform-buildit-project",
      "query": {
        "query_string": {
          "query": `user_project_id:${qs.pid}`
        }
      }
    });
    const rr = r.data;
    let projectId;
    if (rr.hits.hits) {
      projectId = rr.hits.hits[0]._source.project_id;
      let ddb = await new AWSModule("DDB").connect();

      let project = await ddb.Ddb!.get({
        TableName: 'engine-buildit-project',
        Key: {
          id: `${this.state.ServerStage}_${projectId}`,
        },
      }).promise();
      App.stage !== "prod" && console.log('project ', project.Item);

      await this.setState({
        ProjectID: projectId,
        userProjectID: Number(qs.pid),
        buildingCategory: project.Item!.projectInfo.buildingCategory,
        ProjectName: project.Item!.projectInfo.projectName,
        address: project.Item!.projectInfo.projectAddress,
        siteUseDistrict: rr.hits.hits[0]._source.project_use_district,
      }, async () => {
        let shapeJson: any;
        await getS3Json(project.Item!.shapeInfo).then(v => {
          shapeJson = v;
        });
        App.stage !== "prod" && console.log(shapeJson);

        this.subBuildingRange = project.Item!.parameterInfo.buildingStoriesMax * 50;
        this.subBuildingRange = Math.max(250, this.subBuildingRange);// this.subBuildingRange < 250 ? 250 : this.subBuildingRange;
        this.subBuildingRange = Math.min(700, this.subBuildingRange);// this.subBuildingRange > 700 ? 700 : this.subBuildingRange;

        let center = new THREE.Vector2(0, 0);
        let count = 0;

        (shapeJson.projectSite as []).forEach((ps: any) => {
          // @ts-ignore
          let gj = wkx.Geometry.parse(ps).toGeoJSON().coordinates as number[][][];

          gj.forEach(g => {
            let vArray: THREE.Vector2[] = [];
            g.forEach(c => {
              center.add(new THREE.Vector2(c[0], c[1]));
              vArray.push(new THREE.Vector2(c[0], c[1]));
            })
            count += g.length;
            this.siteVertsArray.push(vArray);
          })

        })
        center.divideScalar(count);
        let exludeArray = shapeJson.setbackLineApartment.concat(shapeJson.vacancyInside);
        App.stage !== "prod" && console.log(exludeArray);
        await this.getDEM(center.x, center.y);

        try {
          let objList = await getS3Json(`s3://teneleven-engine-result/${this.state.ServerStage}/${this.state.ProjectID}/0/objList.json`);
          await this.getSubbuildingbyList(center.x, center.y, objList);
        } catch (error) {
          await this.getSubBuilding(center.x, center.y, exludeArray);
        }

        let reportIndexList: ReportIndexInfo[] = [];
        for (let i = 0; i < project.Item!.projectInfo.reportsNumber; i++) {
          let item: any;
          await getDBData(this.state.ServerStage, this.state.ProjectID, i + 1).then(v => {
            item = v;
          })
          let real_report_number = -1;
          if (item)
            real_report_number = item.real_report_number;
          if (real_report_number > 0) {
            reportIndexList.push({
              added: false,
              realReportID: real_report_number,
              reportID: i,
              successed: project.Item!.succeeded_ai[i],
            })
          }
        }

        let sortList = reportIndexList.sort((a, b) => { return a.realReportID - b.realReportID })
        App.stage !== "prod" && console.log(sortList);
        this.setState({
          siteArea: shapeJson.projectSiteArea,
          reportIndexList: sortList,
          // reportList: reportList,
          // reportIndex: reportIndex,
        });

        (rid as []).map(i => {
          this.getReportIndexInfo(i - 1).added = true;
          // reportIndexList[i - 1].added = true;
        })
      })
    } else {
      console.log(rr.error);
    }

    window.addEventListener("keyup", this.onKeyUp, false);
    // });
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'b':
        // console.log(this.reportInformation);
        break;
      default: break;
    }
  }

  getInfoById = (index: number) => {
    return this.reportInformation.find((e) => { return index === e.reportID; })
  }

  AddRemoveScene = (index: number) => {
    let indices = this.state.index;
    let i = indices.findIndex(i => i === index + 1);
    if (i >= 0) {
      if (indices.length == 1)
        return;
      this.getReportIndexInfo(index).added = false;
    }
    else {
      if (this.state.index.length >= 4)
        return;
      indices.push(index + 1);
      this.getReportIndexInfo(index).added = true;
    }

    this.setState({
      index: indices,
      sceneSize: indices.length,
    });
  }

  getReportIndexInfo = (reportID: number) => {
    let i = this.state.reportIndexList.findIndex(info => info.reportID === reportID);
    return this.state.reportIndexList[i];
  }

  RemoveScene = (index: number) => {
    let indices = this.state.index;
    let i = indices.findIndex(i => i === index + 1);
    indices.splice(i, 1);
    this.setState({
      index: indices,
      sceneSize: indices.length,
    });
  }

  ZoomOutScene = (index: number) => {
    let reportIndexList = this.state.reportIndexList;
    for (let i = 0; i < reportIndexList.length; i++) {
      if (reportIndexList[i].reportID !== index - 1) {
        reportIndexList[i].added = false;
      }
    }
    this.setState({ reportIndexList: reportIndexList });
  }

  SetCameraAngle = (position: THREE.Vector3, target: THREE.Vector3, zoom: number) => {
    this.setState({
      syncCameraPosition: new THREE.Vector3().copy(position),
      syncCameraTarget: new THREE.Vector3().copy(target),
      syncCameraZoom: zoom
    })
  }

  ResetAllScene = () => {
    App.stage !== "prod" && console.log('reset');
    this.setState({
      reset: !this.state.reset,
      cameraChange: 0,
      season: 'winter'
    }, () => this.setLightIndex(4)
    )
  }

  setLightIndex = (index: number) => {
    this.lightTimeText = moment(0).utc().add(moment.duration(index + 8, 'hours')).format('HH:mm');
    this.setState({ lightIndex: index });
  }

  showModal = (title: string, content: string, buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = [content];
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    })
  }

  render = () => {
    let sunIcon = <SunIcon className='imageButton' />;
    let topViewIcon = <TopViewIcon className='imageButton' />;

    if (this.state.sunButtonOver || !this.state.showLightControl) {
      sunIcon = <SunIconHover className='imageButton' />
    }
    if (this.state.topViewButtonOver || this.state.cameraChange % 2 !== 0) {
      topViewIcon = <TopViewIconHover className='imageButton' />
    }
    if (this.state.ProjectID !== 0 && this.state.demFinished && this.state.subModelFinished && this.state.reportIndexList.length > 0) {
      return (
        <React.Fragment>
          <header className='VisualizeHead'>
            <img src="/img/buildit_platform_logo.svg" className="logo" />
            <div className='projectName'>{this.state.ProjectName}</div>
            <div className='buttons'>
              <div className='imageBox' onClick={() => this.setState({ showInfo: !this.state.showInfo })}>
                <span className="tooltiptext" >프로젝트 정보</span>
                <InfoIcon className='imageButton' />
              </div>
              <div className='imageBox' onClick={() => this.setState({ showLightControl: !this.state.showLightControl })} onMouseOver={() => this.setState({ sunButtonOver: true })} onMouseOut={() => this.setState({ sunButtonOver: false })}>
                <span className="tooltiptext">음영 조절</span>
                {sunIcon}
              </div>
              <div className='imageBox' onClick={() => this.setState({ cameraChange: this.state.cameraChange + 1 })} onMouseOver={() => this.setState({ topViewButtonOver: true })} onMouseOut={() => this.setState({ topViewButtonOver: false })}>
                <span className="tooltiptext">탑 뷰</span>
                {topViewIcon}
              </div>
              <div className='imageBox' onClick={this.ResetAllScene}>
                <span className="tooltiptext">렌더링 초기화</span>
                <SettingBackIcon className='imageButton' />
              </div>
            </div>
          </header>

          <div className='lightControl' hidden={this.state.showLightControl}>
            <div className='text'>계절</div>
            <div className='seasonButtons'>
              <div className={`button ${this.state.season === 'spring' && 'selected' || ''}`} onClick={() => this.setState({ season: 'spring' })} >춘분</div>
              <div className={`button ${this.state.season === 'summer' && 'selected' || ''}`} onClick={() => this.setState({ season: 'summer' })}>하지</div>
              <div className={`button ${this.state.season === 'fall' && 'selected' || ''}`} onClick={() => this.setState({ season: 'fall' })}>추분</div>
              <div className={`button ${this.state.season === 'winter' && 'selected' || ''}`} onClick={() => this.setState({ season: 'winter' })}>동지</div>
            </div>
            <div className='text'>시간<span className='time'>{this.lightTimeText}</span> </div>
            <div><input className='slider' type='range' value={this.state.lightIndex} min='0' max='8' step='0.001' onChange={e => this.setLightIndex(Number(e.target.value))}></input></div>
          </div>

          <div className='mainView'>
            <div className='Visualizer' >
              <div className='checkboxList'>
                {(this.state.reportIndexList).map(index => {
                  if (index.successed)
                    return <div className='reportBox' key={index.reportID}>
                      <Checkbox
                        className={`checkbox ${index.added && 'checked' || ''}`}
                        checked={index.added}
                        onClick={() => this.AddRemoveScene(index.reportID)}
                      />
                      <span className='label'>{index.realReportID}번 결과</span>
                    </div>
                })}
              </div>
              <div className='canvases'>
                {(this.state.index as []).map(i => {
                  return <Scene
                    key={i}
                    ServerStage={this.state.ServerStage}
                    ProjectID={this.state.ProjectID}
                    index={i}
                    dem={this.state.dem}
                    subBuilding={this.state.subBuilding}
                    getPos={this.getScenePos}
                    siteCenter={this.state.siteCenterInScene}
                    siteLine={this.siteLine}
                    cameraChange={this.state.cameraChange}
                    reportInfors={this.reportInformation}
                    lightIndex={this.state.lightIndex}
                    indexSize={this.state.index.length}
                    indexList={this.state.index}
                    finished={() => this.setState({ finished: this.state.finished + 1 })}
                    removeScene={() => this.RemoveScene(i - 1)}
                    setCamera={this.SetCameraAngle}
                    zoomOut={this.ZoomOutScene}
                    syncCameraPosition={this.state.syncCameraPosition}
                    syncCameraTarget={this.state.syncCameraTarget}
                    syncCameraZoom={this.state.syncCameraZoom}
                    reset={this.state.reset}
                    delete={this.getReportIndexInfo(i - 1).added}
                    demData={this.demData}
                    showModal={this.showModal}
                    buildingCategory={this.state.buildingCategory}
                    centerInLanLat={this.state.siteCenterInLonLat}
                    season={this.state.season}
                  />
                })}
              </div>

              <InformationModal
                reportInformation={this.reportInformation}
                indexArray={this.state.index}
                siteUseDistrict={this.state.siteUseDistrict}
                siteArea={this.state.siteArea.toLocaleString()}
                projectID={this.state.userProjectID}
                address={this.state.address}
                open={this.state.showInfo}
              />

              <Modal
                content={this.ModalProps.content}
                title={this.ModalProps.title}
                open={this.state.showModal}
                buttonNum={this.ModalProps.buttonNum}
                positive={this.ModalProps.positive} />
            </div>
          </div>
        </React.Fragment >
      )
    }
    else {
      return (
        <div className='loading'>
          <header>
            <img src="/img/buildit_platform_logo.svg" className="logo" />
            <div className='projectName'>{this.state.ProjectName}</div>
          </header>
          <div className='information'>
            <div className='progress'>
              <img src={'/img/icon/loading.png'} ></img>
            </div>
            <div className='centerWord'>BUILDIT 3D VIEWER</div>
            <div className='loadingMessage'>
              <span>{this.state.loadingMessage}</span><span className='white'>{this.state.loadingProgess}</span>
            </div>
          </div>
        </div>
      )
    }
  }
}