import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import './css/MyPageHead.scss';
import { MyPageProps, pageContent, } from './MyPage';
import EmailIcon from '@material-ui/icons/Email';
import ReceiptIcon from '@material-ui/icons/Receipt';
import NextIcon from '@material-ui/icons/NavigateNext'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import AWS from 'aws-sdk';
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom';
import App from './App';
import { bool } from 'aws-sdk/clients/signer';
import AWSModule from './AWSModule';

interface MyPageHeadProps extends MyPageProps {
  pageContent: pageContent,
  userEmail: string,
}

interface MyPageHeadState {
  userName: string,
  userEmail: string,
  buildITPoint: number,
  servicePoint: number,
  userLevel: string,
  mySiteCount: number,
  myTypeCount: number,
  myPlanCount: number,
}

export default class MyPageHead extends SessionComponent<MyPageHeadProps, MyPageHeadState> {
  state: MyPageHeadState = {
    userName: '고객',
    userEmail: App.session.email,
    buildITPoint: App.session.point,
    servicePoint: App.session.service_point,
    userLevel: 'PLATINUM',
    myPlanCount: 0,
    mySiteCount: 0,
    myTypeCount: 0,
  }

  componentWillMount = async () => {
    this.setSiteCount();
    this.setBuildingPlanCount();
    this.setBuildingTypeCount();
  }

  setSiteCount = async () => {
    const r = await App.search({
      table: App.DDBTable.MyBuildingSite,
      "query": `stage.keyword:${App.stage} AND email.keyword:${this.state.userEmail} AND deleted:false`
    });
    this.setState({
      mySiteCount: r.data.hits.total,
    })
  }

  setBuildingPlanCount = async () => {
    const r = await App.search({
      table: App.DDBTable.MyBuildingPlan,
      "query": `stage.keyword:${App.stage} AND email.keyword:${this.state.userEmail} AND deleted:false`,
    });
    this.setState({
      myPlanCount: r.data.hits.total,
    })
  }

  setBuildingTypeCount = async () => {
    const r = await App.search({
      table: App.DDBTable.MyBuildingType,
      "query": `stage.keyword:${App.stage} AND email.keyword:${this.state.userEmail} AND deleted:false`,
    });
    this.setState({
      myTypeCount: r.data.hits.total,
    })
  }

  render = () => {
    let titleString = ""
    let infoString = ""
    let buttons = <div/>

    switch (this.props.pageContent) {
      case pageContent.myBuildingType:
      case pageContent.myPlan:
      case pageContent.mySite:
        titleString = "나의 파일"
        infoString = "나의 파일"

        buttons = <div className='buttons'>
        <Button className='emailButton' disableRipple={true}>{this.props.userEmail}</Button>
        <div className='switchTabs' style={{width:"262px"}}>
          <Button className={`switchButton ${this.props.pageContent === pageContent.mySite && 'enable'}`}
          onClick={e => this.props.changePageContent!(pageContent.mySite)}
          style={{width:"86px"}} disableRipple={true}>
            나의 사업영역
          </Button>
          <Button className={`switchButton ${this.props.pageContent === pageContent.myBuildingType && 'enable'}`}
          onClick={e => this.props.changePageContent!(pageContent.myBuildingType)}
          style={{width:"86px", marginLeft:"2px", marginRight:"2px"}} disableRipple={true}>
            나의 동평면
          </Button>
          <Button className={`switchButton ${this.props.pageContent === pageContent.myPlan && 'enable'}`}
          onClick={e => this.props.changePageContent!(pageContent.myPlan)}
          style={{width:"86px"}} disableRipple={true}>
            나의 배치안
          </Button>
        </div>
      </div>
        break;
      case pageContent.pointUsageList:
      case pageContent.purchaseHistory:
        titleString = "구매/포인트 내역"
        infoString = "구매/포인트"

        buttons = <div className='buttons'>
        <Button className='emailButton' disableRipple={true} disabled={true}><span className="text">{this.props.userEmail}</span> </Button>
        <Button className='pointButton' disableRipple={true} disabled={true}>
          <div className='svg'>
            <object type="image/svg+xml" data="/img/my_page/icon_point.svg"/>
          </div>
          <div className='text'>사용 가능 포인트</div>
          <div className='point'>{new Intl.NumberFormat().format(this.state.buildITPoint + this.state.servicePoint)}P</div>
        </Button>
        {/* <div className='switchTabs' style={{width:"174px"}}>
          <Button className={`switchButton ${this.props.pageContent === pageContent.purchaseHistory && 'enable'}`}
          onClick={e => this.props.changePageContent!(pageContent.purchaseHistory)}
          style={{width:"86px"}} disableRipple={true}>
            구매 내역
          </Button>
          <Button className={`switchButton ${this.props.pageContent === pageContent.pointUsageList && 'enable'}`}
          onClick={e => this.props.changePageContent!(pageContent.pointUsageList)}
          style={{width:"86px", marginLeft:"2px"}} disableRipple={true}>
            포인트 내역
          </Button>
        </div> */}
      </div>
        break;
      case pageContent.myInformation:
        titleString = "계정 관리"
        infoString = "계정 관리"

        buttons = <div className='buttons'>
        <Button className='emailButton' disableRipple={true}>{this.props.userEmail}</Button>
      </div>

        break;
    }
    return (
      <div className='MyPageHead'>
        <div className='content'>
          <div className='pageInfoLayer font bg-navy font-secondary font-12px font-noto'>
            <Link to="/" style={{textDecoration:"none", color:"#999"}}>
            HOME
            </Link>
            <NextIcon className='nextIcon' />
            <div>{infoString}</div>
          </div>
          <div className='buttonLayer'>
            <div className='title font bg-navy font-emphasis font-30px'>{titleString}</div>
            {buttons}
          </div>
        </div>
      </div>
    )
  }
}