import React, { Fragment, Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, } from '@material-ui/core';
import queryString from 'query-string';
import { default as _ } from 'lodash';

import './css/TOSPage.scss';
import PageFoot from './pageFoot';

export type 약관타입 = "개인 정보 처리 방침" | "서비스 이용 약관";

export interface TOSPageProps extends RouteComponentProps {
}

export interface TOSPageState {
  current_type: 약관타입,
  qString: {type?: 약관타입},
}

export default class TOSPage extends Component<TOSPageProps, TOSPageState> {
  state: TOSPageState = {
    current_type: "개인 정보 처리 방침",
    qString: queryString.parse(location.search),
  }

  componentWillMount = async () => {
    this.getQueryString();
  }

  componentDidUpdate = (pp: Readonly<TOSPageProps>, ps: Readonly<TOSPageState>) => {
    if (_.isEqual(queryString.parse(location.search), ps.qString) === false) {
      this.getQueryString();
    }
  }

  getQueryString = async () => {
    const parsed = queryString.parse(location.search);
    if (parsed && parsed.type) {
      if (this.state.current_type !== (parsed.type as 약관타입)) {
        this.setState({current_type: parsed.type as 약관타입, qString: parsed});
      }
    } else {
      this.setState({current_type: "개인 정보 처리 방침", qString: parsed});
    }
  }

  changeListType = (type: 약관타입) => {
    this.props.history.push(`${location.pathname}?type=${type}`);
  }

  getListContent = () : JSX.Element => {
    let ret: JSX.Element = <div></div>;

    switch(this.state.current_type) {
      default:
      case "개인 정보 처리 방침":
        ret = 
        <Fragment>
          <div className="list-content">
            <div className="sub-title">
              {`1. 총칙`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              {`㈜텐일레븐(이하 “회사”라 한다)은 이용자의 개인정보보호를 소중하게 생각하고, 이용자의 개인정보를 보호하기 위하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 비롯한 모든 개인정보보호 관련 법률규정을 준수하고 있으며, 「개인정보처리방침」을 제정하여 이를 준수하고 있습니다. 이를 인터넷사이트에 공개하여 이용자가 언제나 용이하게 열람할 수 있도록 하여 소비자 권익보호를 위해 최선을 다하고 있습니다.`}
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`2. 개인정보의 수집방법 및 항목`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              {`"회사"는 회원가입과 상담, 각종 서비스의 제공 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.`}
              <ol type={"1"}>
                <li>
                  {`회원가입 시점에서 수집하는 항목`}
                  <ul>
                    <li>{`필수 : E-mail 주소, 비밀번호`}</li>
                    <li>{`선택 : 이름 또는 기업명, 소속회사, 연락처`}</li>
                  </ul>
                </li>
                <li>
                  {`서비스 이용과정에서 수집될 수 있는 개인정보의 범위`}
                  <ul>
                    <li>{`서비스 이용과정에서 이용자가 입력한 정보 및 작성한 저작물`}</li>
                    <li>{`모든 기능을 이용하는 과정에서 휴대폰 번호 또는 전화번호`}</li>
                    <li>{`고객센터 통화내용 녹취본`}</li>
                  </ul>
                </li>
                <li>
                  {`개인정보의 수집방법`}
                  <ul>
                    <li>{`웹페이지, 고객센터를 통한 전화와 온라인 상담, 이벤트 응모 등`}</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`3. 개인정보의 수집 및 이용목적`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>
                  {`서비스의 원활한 제공 및 운영`}
                </li>
                <li>
                  {`회원관리`}
                  <br/>
                  {`회원제 서비스 이용, 개인식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달`}
                </li>
                <li>
                  {`재화 또는 서비스 제공`}
                  <br/>
                  {`서비스 제공, 맞춤 서비스 제공, 요금결제, 정산 등`}
                </li>
                <li>
                  {`마케팅 및 광고에의 활용`}
                  <br/>
                  {`고객별 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 정기 간행물 발송, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스 이용에 대한 통계`}
                </li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`4. 개인정보 수집, 이용, 제공에 대한 동의`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              {`"회사"는 "이용자"가 최초 회원가입 시 또는 서비스 이용과 관련해 개인정보를 입력할 시 개인정보취급방침 및 이용약관 등의 내용에 대해 동의 여부를 체크할 수 있는 절차를 마련하여, 이를 체크하면 개인정보수집에 대해 동의한 것으로 취급합니다.`}
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`5. 개인정보의 목적 외 이용 및 위탁`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>{`"회사"는 "이용자"의 개인정보를 "3. 개인정보의 수집 및 이용목적"에서 고지한 범위 내에서 사용하며 동의 범위를 초과하여 이용하거나 원칙적으로 제 3자에게 제공하지 않습니다.`}</li>
                <li>{`다만 제휴관계나 위탁처리를 위한 경우 합당한 절차를 통한 회원의 동의를 얻어 개인정보를 제공 또는 이용할 수 있습니다.`}</li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`6. 개인정보의 보유기간 및 파기`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>
                  {`"회사"가 "회원"의 개인정보를 수집하는 경우 그 보유기간은 원칙적으로 회원가입 후 약관 제10조에 의한 ‘계약 기간 및 이용계약의 종료 시’까지며, 이용계약 종료의 경우 회사는 회원의 개인정보를 즉시 파기하며 제3자에게 기 제공된 정보에 대해서도 지체 없이 파기하도록 조치합니다. (단, 재가입유예기간 동안의 재가입 방지 등을 위해 이용계약이 종료한 날로부터 2개월 경과 후에 파기하도록 합니다.) 또한, 다음의 경우 각각 명시한 기간 동안 개인정보를 보유합니다.`}
                  <ol type={"1"}>
                    <li>{`상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유`}</li>
                    <li>{`보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 회원의 동의를 받을 경우에는 약정한 기간 동안 보유`}</li>
                    <li>
                      {`다음의 경우 명시한 기간 동안 개인정보를 보유`}
                      <ul className="dashed">
                        <li>{`계약 또는 청약철회 등에 관한 기록: 5년`}</li>
                        <li>{`소비자의 불만 또는 분쟁처리에 관한 기록: 3년`}</li>
                        <li>{`부정이용 등에 관한 기록: 5년`}</li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  {`"회사"는 고객의 개인정보를 보호하여 개인정보 유출로 인한 피해가 발생하지 않도록 하기 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.`}
                  <ul>
                    <li>{`종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.`}</li>
                    <li>{`전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.`}</li>
                  </ul>
                </li>
                <li>{`"회원"의 동의를 받아 보유하고 있는 개인정보에 대한 당해 회원의 열람요구가 있을 경우 회사는 지체 없이 이를 열람, 확인할 수 있도록 조치합니다.`}</li>
                <li>
                  {`정보통신망법에 의한 개인정보 보유`}
                  <br/>
                  {`"회원"이 1년간 서비스 이용기록이 없는 경우 "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 제 29조 "개인정보 유효기간제"에 따라 회원에게 사전 통지하고 별도로 분리하여 저장합니다.`}
                </li>
                <li>
                  {`개인정보의 국외 보관에 대한 내용`}
                  <br/>
                  {`"회사"는 이용자로부터 취득 또는 생성한 개인정보를 "AWS"가 보유하고 있는 데이터베이스(물리적보관소: 한국, 일본)에 저장합니다. "AWS"는 해당 서버의 물리적인 관리만을 행하고, 원칙적으로 회원님의 개인정보에 접근하지 않습니다.`}
                </li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`7. 이용자 및 법정대리인의 권리와 그 행사방법`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>{`"이용자" 및 “법정대리인”은 언제든지 등록되어 있는 자신 또는 대리자의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.`}</li>
                <li>{`"이용자" 혹은 “법정대리인”의 개인정보 조회, 수정을 위해서는 ‘내 정보’를, 가입 해지(동의철회)를 위해서는 고객센터를 통해 본인확인 절차를 거친 후 직접 열람, 정정 또는 탈퇴 요청이 가능합니다.`}</li>
                <li>{`“이용자” 혹은 “법정대리인”이 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하여 개인정보의 조회, 수정 및 가입 해지 요청이 가능합니다.`}</li>
                <li>{`“이용자” 혹은 “법정대리인”이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제 3자에게 이미 제공한 경우에는 정정 처리결과를 제3자 에게 통지합니다.`}</li>
                <li>{`"회사"는 “이용자” 혹은 “법정대리인”의 요청에 의해 해지 또는 삭제된 개인정보를 "6. 개인정보의 보유기간 및 파기"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`}</li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`8. 개인정보 자동 수집 장치의 설치와 운영 및 거부에 관한 사항`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type={"1"}>
                <li>
                  {`쿠키란?`}
                  <ul>
                    <li>{`“회사”는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키 (cookie)'를 사용합니다.`}</li>
                    <li>{`쿠키는 웹 서비스 제공자의 서버가 이용자의 브라우저에게 보내는 소량의 정보이며, 이용자 컴퓨터의 하드디스크에 저장됩니다. 여기에는 방문한 웹사이트의 정보 등이 담겨있습니다. 이후 이용자가 웹사이트에 방문할 경우 웹사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.`}</li>
                    <li>{`쿠키는 이용자 컴퓨터의 웹브라우저는 식별하지만 이용자 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 웹브라우저의 옵션 메뉴를 통해 쿠키의 수용 여부 등을 설정할 수 있습니다.`}</li>
                  </ul>
                </li>
                <li>
                  {`"회사"의 쿠키 사용 목적`}
                  <ul>
                    <li>{`"이용자"들이 방문한 "빌드잇"의 각 서비스의 접속여부, 필지 확인 여부, 문의하기 등에 사용합니다.`}</li>
                    <li>{`"이용자"는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자"는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 동의 절차를 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 ‘빌드잇’의 일부 서비스는 이용에 어려움이 있을 수 있습니다.`}</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`9. 개인정보의 기술적/관리적 보호 대책`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              {'"회사"는 "이용자"들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다. 단, 이용자 본인의 부주의나 인터넷 또는 통 신상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 "회사"는 일체의 책임을 지지 않습니다.'}
              <ol type={"1"}>
                <li>
                  {`비밀번호 암호화`}
                  <ul>
                    <li>{`회원 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경 도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
`}</li>
                  </ul>
                </li>
                <li>
                  {`해킹 등에 대비한 대책`}
                  <ul>
                    <li>{`"회사"는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해 자료를 수시로 백업하고 있고, 백신프로그램을 이용하여 이용자 들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추기 위해 노력하고 있습니다.`}</li>
                    <li>{`"회사"의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 "빌드잇"의 개인정보취급방침의 준수를 항상 강조하고 있습니다.`}</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`10. 개인정보관리책임자 및 담당자`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>{`개인정보관리책임자는 고객의 개인정보를 보호하고 유출을 방지하는 책임자로서 이용자가 안심하고 회사가 제공하는 서비스를 이용할 수 있도록 도와드리며, 개인정보를 보호하는데 있어 이용자에게 고지한 사항들에 반하여 사고가 발생할 시에는 이에 관한 책임을 집니다.`}</li>
                <li>{`기술적인 보완조치를 취하였음에도 불구하고 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 멸실에 관해서는 책임이 없습니다.`}</li>
                <li>{`"회사"는 정보통신망 이용촉진 및 정보보호 등에 관한 법률에서 규정한 관리책임자를 다음과 같이 지정합니다.`}</li>
              </ol>
              <div className="indent">
                <div>{`[ 개인정보 관리책임자 ]`}</div>
                <div>{`이름 : 김동철`}</div>
                <div>{`소속 : 미래기술연구소`}</div>
                <div>{`직위 : CTO`}</div>
                <div>{`E-mail : dc@1011.co.kr`}</div>
                <div>{`전화번호 : 02-375-1011`}</div>
                <br/>
                <br/>
                <div>{`[ 개인정보 관리담당자 ]`}</div>
                <div>{`이름 : 박누리`}</div>
                <div>{`소속 : 미래기술연구소`}</div>
                <div>{`직위 : 팀장`}</div>
                <div>{`E-mail : nuripark1256@1011.co.kr`}</div>
                <div>{`전화번호 : 02-375-1011`}</div>
              </div>
              <div style={{marginTop:"5px"}}>{`기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.`}</div>
              <ul>
                <li>{`개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)`}</li>
                <li>{`대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)`}</li>
                <li>{`경찰청 사이버안전국 (www.ctrc.go.kr / 국번없이 182)`}</li>
              </ul>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="sub-title">
              {`11. 고지의 의무`}
            </div>
            <div className="content">
              <div>{`현 개인정보취급방침은 2020년 5월 18일에 제정되었으며, 정부 및 회사의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 경우에는 개정 최소 7일 전부터 서비스의 '공지사항'란을 통해 고지하며, 본 정책은 시행일자에 시행됩니다.`}</div>
              <ul>
                <li>{`가. 공고일자 : 2020년 6월 11일`}</li>
                <li>{`나. 시행일자 : 2020년 6월 11일`}</li>
              </ul>
            </div>
          </div>
        </Fragment>
        break;
      case "서비스 이용 약관":
        ret = 
        <Fragment>
          <div className="list-content">
            <div className="title">
              {`제 1장 총칙`}
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 1조 목적`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              {`이 약관은 (주)텐일레븐(이하 "회사"라 한다)이 운영하는 인터넷 사이트 및 PC 어플리케이션(이하 "빌드잇"라 한다)에서 제공하는 제반 서비스의 이용과 관련하여 "회사"와 "이용자"의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.`}
            </div>
            <div className="sub-title" style={{marginTop:"30px"}}>
              {`제 2조 정의`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>{`빌드잇: "회사"가 컴퓨터 등 정보통신설비를 이용하여 서비스를 제공할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 인터넷 사이트 및 PC 애플리케이션을 운영하는 사업자의 의미로도 사용합니다.`}</li>
                <li>{`이용자: "빌드잇"에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 받는 "회원" 또는 "비회원"을 말합니다.`}</li>
                <li>{`회원: "빌드잇"에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 받는 자를 말합니다.`}</li>
                <li>{`비회원: 회원으로 가입하지 않고 "회사"가 제공하는 서비스를 이용하는 자를 말합니다.`}</li>
                <li>{`아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인한 이메일주소를 말합니다.`}</li>
                <li>{`비밀번호: 회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 설정하고 회사가 승인한 문자나 숫자의 조합을 말합니다.`}</li>
                <li>{`게시판: 그 명칭, 형태, 위치와 관계없이 회원 및 비회원 이용자에게 공개할 목적으로 부호•문자•음성•음향 •화상•동영상 등의 정보 (이하 "게시물"이라 합니다)를 회사가 제공하는 서비스상의 가상공간을 말합니다.`}</li>
                <li>{`포인트: “빌드잇”에서 통용되는 가상의 지불 수단으로 서비스를 이용하는데 사용이 됩니다.`}</li>
                <li>{`보너스 포인트: “포인트”와 같은 가상의 지불 수단으로 “포인트” 구매 시 또는 이벤트 시 보너스로 지급되는 포인트입니다.`}</li>
              </ol>
              <div>{`위 항에서 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래 관행에 의합니다.`}</div>
            </div>
            <div className="sub-title" style={{marginTop:"30px"}}>
              {`제 3조 약관 등의 명시와 설명 및 개정`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>{`"회사"는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 "빌드잇"의 인터넷 사이트에 게시합니다. 다만, 약관의 내용은 이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.`}</li>
                <li>{`"회사"는 "약관 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다`}</li>
                <li>{`"회사"가 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 이용자가 알기 쉽도록 표시하여 공지합니다. 변경된 약관은 공지된 시점부터 그 효력이 발생하며, 이용자는 약관이 변경된 후에도 본 서비스를 계속 이용함으로써 변경 후의 약관에 대해 동의를 한 것으로 간주됩니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"30px"}}>
              {`제 4조 약관의 해석`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>{`"회사"는 서비스운영을 위해 별도의 운영정책을 마련하여 운영할 수 있으며, "회사"는 "빌드잇" 인터넷 사이트에 운영정책을 사전 공지 후 적용합니다.`}</li>
                <li>{`본 약관에서 정하지 아니한 사항이나 해석에 대해서는 별도의 운영정책, 관계법령 또는 상관례에 따릅니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"30px"}}>
              {`제 5조 서비스의 제공 및 변경`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>
                  {`"회사"는 다음과 같은 서비스를 제공합니다.`}
                  <ol type={"1"}>
                    <li>{`건축설계 서비스`}</li>
                    <li>{`건축설계 컨설팅 보고서 서비스`}</li>
                    <li>{`기타 "빌드잇"의 이용자를 위하여 제공하는 서비스`}</li>
                    <li>{`유료로 제공하는 부가 서비스`}</li>
                  </ol>
                </li>
                <li>{`"회사"가 제공하는 “포인트”, “보너스 포인트”는 원칙적으로 양도양수가 불가합니다.`}</li>
                <li>{`"회사"가 제공하는 서비스의 내용을 기술적 사양의 변경 등의 이유로 변경할 경우에는 그 사유를 이용자에게 통지하거나, 이용자가 알아볼 수 있도록 공지사항으로 게시합니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"30px"}}>
              {`제 6조 서비스의 중단`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>{`"회사"는 컴퓨터 등 정보 통신 설비의 보수 점검, 교체 및 고장, 통신의 두절, 천재지변 등의 사유가 발생한 경우 에는 서비스의 제공을 제한하거나 일시적으로 중단할 수 있습니다.`}</li>
                <li>{`사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 이용자에게 통지하거나 이용자가 알아볼 수 있도록 공지 사항으로 게시합니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"30px"}}>
              {`제 7조 회원에 대한 통지`}
            </div>
            <div className="content">
              <ol type={"1"}>
                <li>{`"회사"는 이메일, 이동전화 단문메시지서비스(SMS) 등으로 회원에게 통지할 수 있습니다.`}</li>
                <li>{`"회사"는 불특정다수 회원에 대한 통지의 경우 공지사항으로 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.`}</li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="title" >
              {`제 2장 이용계약 및 정보보호`}
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 8조 회원가입 및 회원정보의 변경`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`"이용자"는 회사가 정한 가입 양식에 따라 회원 정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원 가입을 신청합니다.`}</li>
                <li>
                  {`"회사"는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 "회원"으로 등록합니다.`}
                  <ol type="1">
                    <li>{`등록 내용에 허위, 기재누락, 오기가 있는 경우`}</li>
                    <li>{`가입 신청자가 이전에 회원 자격을 상실한 적이 있는 경우(다만 회원 자격 상실 후 회사가 필요하다고 판단하여 회원 재가입에 대한 승낙을 얻은 경우에는 예외로 합니다.)`}</li>
                    <li>{`"회사"로부터 회원 자격 정지 조치 등을 받은 회원이 그 조치 기간 중에 이용 계약을 임의 해지하고 재가입 신청을 하는 경우`}</li>
                    <li>{`회원으로 등록하는 것이 "빌드잇"의 기술상 현저히 지장이 있다고 판단되는 경우`}</li>
                    <li>{`본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우`}</li>
                  </ol>
                </li>
                <li>{`회원 가입 계약의 성립 시기는 "회사"의 승낙이 "회원"에게 도달한 시점으로 합니다.`}</li>
                <li>{`"회원"은 회원 가입 신청 및 이용 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 그 변경사항을 알려야 합니다.`}</li>
                <li>{`제4항의 변경 사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.`}</li>
                <li>{`회원 가입은 반드시 본인의 진정한 정보를 통하여 가입할 수 있으며 "회사"는 "회원"이 등록한 정보에 대하여 확인 조치를 할 수 있습니다. "회원"은 "회사"의 확인 조치에 대하여 적극 협력하여야 하며, 만일 이를 준수하지 아니 할 경우 "회사"는 등록한 정보가 부정한 것으로 간주하여 처리할 수 있습니다.`}</li>
                <li>{`"회사"는 "회원"을 등급 별로 구분하여 이용시간, 이용회수, 포인트 구매, 서비스 메뉴, 매물 등록 건 수 등을 세분하여 서비스 이용에 차등을 둘 수 있습니다.`}</li>
                <li>{`"회원"은 1인 1계정에 한하여 서비스 이용이 가능하며, 중복 가입 시 서비스 이용이 불가할 수 있습니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"30px"}}>
              {`제 9조 이용 계약의 종료`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>
                  {`"회원의 해지`}
                  <ol type="1">
                    <li>{`"회원"은 회사에 언제든지 해지 의사를 통지할 수 있고 회사는 특별한 사유가 없는 한 이를 즉시 수락하여야 합니다. 다만, "회원"은 해지의사를 통지하기 전에 모든 진행중인 절차를 완료, 철회 또는 취소해야만 합니다. 이 경우 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다.`}</li>
                    <li>{`"회원"이 발한 의사표시로 인해 발생한 불이익에 대한 책임은 회원 본인이 부담하여야 하며, 이용계약이 종료되면 "회사"는 "회원"에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.`}</li>
                    <li>{`"회원"의 의사로 이용계약을 해지한 후, 추후 재이용을 희망할 경우에는 재이용 의사가 "회사"에 통지되고, 이에 대한 "회사"의 승낙이 있는 경우에만 서비스 재이용이 가능합니다.`}</li>
                    <li>{`본 항에 따라 해지를 한 "회원"은 이 약관이 정하는 회원가입절차와 관련조항에 따라 신규 회원으로 다시 가입할 수 있습니다. 다만, "회원"이 중복참여가 제한된 이벤트 중복 참여 등 부정한 목적으로 회원탈퇴 후 재이용을 신청하는 경우 "회사"는 가입을 일정기간 동안 제한할 수 있습니다.`}</li>
                    <li>{`본 항에 따라 해지를 한 이후에는 재가입이 불가능하며, 모든 가입은 신규가입으로 처리됩니다.`}</li>
                  </ol>
                </li>
                <li>
                  {`회사의 해지`}
                  <ol type="1">
                    <li>
                      {`"회사"는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다.`}
                      <ol type="1">
                        <li>{`다른 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 공서양속에 위배되는 행위를 한 경우`}</li>
                        <li>{`"회사"가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우`}</li>
                        <li>{`제 8조 제 2항의 승낙거부 사유가 추후 발견된 경우`}</li>
                        <li>{`"회사"가 정한 서비스 운영정책을 이행하지 않거나 위반한 경우`}</li>
                        <li>{`기타 "회사"가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우`}</li>
                      </ol>
                    </li>
                    <li>{`"회사"가 해지를 하는 경우 "회사"는 "회원"에게 이메일, 전화, 기타의 방법을 통하여 해지 사유를 밝혀 해지 의사를 통지합니다. 이용계약은 "회사"의 해지의사를 "회원"에게 통지한 시점에 종료됩니다.`}</li>
                    <li>{`본 항에서 정한 바에 따라 이용계약이 종료될 시에는 "회사"는 "회원"에게 부가적으로 제공한 각종혜택을 회수할 수 있습니다. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 회원이 책임을 부담하여야 하고, "회사"는 일체의 책임을 지지 않습니다.`}</li>
                    <li>{`본 항에서 정한 바에 따라 이용계약이 종료된 경우에는, "회원"의 재이용 신청에 대하여 "회사"는 이에 대한 승낙을 거절할 수 있습니다.`}</li>
                  </ol>
                </li>
                <li>{`이용계약의 종료 시, 제공된 서비스에 대한 환불은 본 규정 제13조에 따릅니다.`}</li>
                <li>{`"회원"이 계약을 해지하는 경우, 관련법 및 "개인정보취급방침"에 따라 "회사"가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 "회원"의 모든 데이터는 소멸됩니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"30px"}}>
              {`제 10조 개인정보보호`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>
                  {`""회사"는 "이용자"의 회원가입시 서비스 제공에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.`}
                  <ol type="1">
                    <li>{`이메일주소`}</li>
                    <li>{`비밀번호`}</li>
                  </ol>
                </li>
                <li>{`"회사"가 "이용자"의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.`}</li>
                <li>
                  {`제공된 개인정보는 당해 이용자의 동의없이 목적 외의 이용이나 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.`}
                  <ol type="1">
                    <li>{`통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우`}</li>
                    <li>{`도용방지를 위하여 본인확인에 필요한 경우`}</li>
                    <li>{`법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우`}</li>
                  </ol>
                </li>
                <li>{`"회사"가 제2항과 제3항에 의해 "이용자"의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 [정보통신망 이용촉진 및 정보보호 등에 관한 법률] 제22조 제2항이 규정 한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.`}</li>
                <li>{`"회사"는 "이용자"의 개인정보를 보호하기 위해 "개인정보취급방침"을 수립하고 개인정보보호책임자를 지정하여 이를 게시하고 운영합니다.`}</li>
                <li>{`"이용자"는 언제든지 "회사"가 갖고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "회사"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에 "회사"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.`}</li>
                <li>
                  {`"회사" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. 다만, 아래의 경우에는 회원 정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원 정보를 그 보관의 목적으로만 이용합니다.`}
                  <ol type="1">
                    <li>{`상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 "회사"는 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다.`}</li>
                    <li>{`"회사"가 이용계약을 해지하거나 "회사"로부터 서비스 이용정지조치를 받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로 이용계약종료 후 5년간 성명, 이메일주소를 비롯하여 이용계약 해지와 서비스 이용정지와 관련된 정보 등의 필요정보를 보관합니다.`}</li>
                  </ol>
                </li>
                <li>{`"회사"는 새로운 업체가 제휴사 또는 제휴영업점의 지위를 취득할 경우 제7조 2항에서 정한 것과 같은 방법을 통하여 공지합니다. 이 때 회원이 별도의 이의제기를 하지 않을 경우 "빌드잇" 서비스 제공이라는 필수적인 목적을 위해 해당 개인 정보 제공 및 활용에 동의한 것으로 간주합니다.`}</li>
                <li>{`모든 "이용자"는 "빌드잇"로부터 제공받은 정보를 다른 목적으로 이용하거나 타인에게 유출 또는 제공해서는 안되며, 위반 사용으로 인한 모든 책임을 부담해야 합니다. 또한 "회원"은 자신의 개인정보를 책임 있게 관리하여 타인이 회원의 개인정보를 부정하게 이용하지 않도록 해야 합니다.`}</li>
                <li>{`"회사"는 "회원"의 개인정보를 보호하기 위해 [정보통신망 이용촉진 및 정보보호 등에 관한 법률] 상의 개인정보 유효기간제도에 따라 1년간 미접속한 회원의 개인정보를 파기 또는 분리하여 별도로 저장/관리하며(휴면계정), "회원"의 별도의 요청이 없는 한 사용이 제한됩니다.`}</li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="title" >
              {`제 3장 서비스의 이용`}
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 11조 필지 등에 관한 정보제공 서비스`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`필지 등에 관한 정보제공 서비스는 "회사"가 이용자 스스로 해당 정보를 확인 및 이용할 수 있도록 관련 정보를 제공하는 것입니다.`}</li>
                <li>{`“회사”는 “빌드잇” 인터넷 사이트 및 PC 애플리케이션 내에서 제공하는 모든 정보에 대해서 정확성이나 신뢰성이 있는 정보를 제공하기 위해 노력하며, 신고 받은 잘못된 정보의 정정, 삭제 조치를 하는 등의 서비스 관리자로서의 책임을 부담합니다.`}</li>
                <li>{`"회사"는 "빌드잇" 인터넷 사이트 및 PC 애플리케이션을 통해 제공되는 정보의 내용을 수정할 의무를 지지 않으나, 필요에 따라 개선할 수는 있습니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 12조 정보의 제공 및 광고의 게재`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`"회사"는 "회원"이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항, 서비스 화면, 전자 우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래 관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 위 정보 제공에 대하여 수신 거절을 할 수 있습니다.`}</li>
                <li>{`"회사"는 서비스 운영과 관련하여 회사가 제공하는 서비스의 화면 및 홈페이지 등에 광고를 게재할 수 있습니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 13조 지급방법`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>
                  {`“빌드잇”의 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가능한 방법으로 할 수 있습니다.`}
                  <ol type="1">
                    <li>{`계좌이체`}</li>
                  </ol>
                </li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 14조 환불 규정`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`“회원”이 서비스 이용계약을 취소할 경우 해당 이용계약에 따라 지급된 잔여 “보너스 포인트”는 소멸되며, 현금으로 구매한 “포인트”의 경우 총 결제금액의 10% 위약금을 공제한 후 잔여 “포인트”는 현금으로 환불합니다. "회원"이 폐업 신고한 후 환불 요청하는 경우도 같습니다.`}</li>
                <li>{`"회원"의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있습니다.`}</li>
                <li>{`위약금이 결제한 금액보다 클 경우 환불이 불가합니다.`}</li>
                <li>{`본 약관에서 정하지 않은 환불에 관한 사항은 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령, 지침 또는 상관례에 의합니다.`}</li>
              </ol>
            </div>
          </div>
          <div className="list-content" style={{marginTop:"40px"}}>
            <div className="title" >
              {`제 4장 책임`}
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 15조 회사의 의무`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`"회사"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 이용자에게 서비스를 제공하는데 최선을 다하여야 합니다.`}</li>
                <li>{`"회사"는 "이용자" 상호간의 거래에 있어서 어떠한 보증도 제공하지 않습니다. 이용자 상호간 거래 행위에서 발생하는 문제 및 손실에 대해서 회사는 일체의 책임을 부담하지 않으며, 거래 당사자 간에 직접 해결해야 합니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 16조 회원의 아이디(ID) 및 비밀번호에 대한 의무`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`"아이디(ID)"와 "비밀번호"에 관한 관리책임은 "회원"에게 있습니다.`}</li>
                <li>{`"회원"은 자신의 "아이디(ID)" 및 "비밀번호"를 제3자에게 이용하게 해서는 안됩니다.`}</li>
                <li>{`"회원"이 자신의 "아이디(ID)" 및 "비밀번호"를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "회사"에 통보하고 "회사"의 안내가 있는 경우에는 그에 따라야 합니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 17조 이용자의 의무`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>
                  {`"이용자"는 다음 행위를 하여서는 안됩니다. 만약 다음 각 호와 같은 행위가 확인되면 회사는 해당 "이용자"에게 서비스 이용에 대한 제재를 가할 수 있으며 민형사상의 책임을 물을 수 있습니다.`}
                  <ol type="1">
                    <li>{`회사 서비스의 운영을 고의 및 과실로 방해하는 경우`}</li>
                    <li>{`신청 또는 변경 시 허위 내용의 등록`}</li>
                    <li>{`타인의 정보 도용`}</li>
                    <li>{`"회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시`}</li>
                    <li>{`"회사"의 저작권 등 지적재산권에 대한 침해`}</li>
                    <li>{`기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우`}</li>
                  </ol>
                </li>
                <li>{`“회사”는 전항의 규정된 위반 행위를 하는 회원에 대해 서비스 일시 중단 등의 조치를 취할 수 있으며, 필요한 경우 이에 대한 시정을 요구할 수 있습니다. 특히, 회원이 시정을 요구받은 기간 내에 시정을 하지 아니한 경우, 동일한 위반 행위를 반복할 경우 또는 다수의 위반 행위가 확인된 경우에 회사는 회원과의 서비스 이용계약을 해지할 수 있습니다. 단, 이 경우 회사는 회원에게 전화, 이메일, SMS 등의 방법으로 개별 통지합니다.`}</li>
                <li>{`"회사"는 회사의 정책에 따라서 회원 간의 차별화된 유료서비스를 언제든지 제공할 수 있습니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 18조 저작권의 귀속 및 이용제한`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`"회원"이 "빌드잇"을 이용하여 작성한 저작물에 대한 저작권 기타 지적 재산권은 "회원"에 귀속합니다.`}</li>
                <li>{`"이용자"는 서비스를 이용함으로써 얻은 정보 중 "회사"에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 19조 책임의 한계 등`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`"회사"는 무료로 제공하는 정보 및 서비스에 관하여 "개인정보취급방침" 또는 관계 법령의 벌칙, 과태료 규정 등 강행 규정에 위배되지 않는 한 원칙적으로 손해를 배상할 책임이 없습니다.`}</li>
                <li>{`“회사”는 천재지변, 불가항력, 서비스용 설비의 보수, 교체, 점검, 공사 등 기타 이에 준하는 사항으로 정보 및 서비스를 제공할 수 없는 경우에 회사의 고의 또는 과실이 없는 한 이에 대한 책임이 면제됩니다.`}</li>
                <li>{`"회사"는 "이용자"의 귀책사유로 인한 정보 및 서비스 이용의 장애에 관한 책임을 지지 않습니다.`}</li>
                <li>{`"빌드잇"에서 제공하는 정보에 대한 신뢰 여부는 전적으로 "이용자" 본인의 책임이며, "회사"는 건축 설계 결과물에 대하여 발생하는 어떠한 직접, 간접, 부수적, 파생적, 징벌적 손해, 손실, 상해 등에 대하여 도덕적, 법적 책임을 부담하지 않습니다.`}</li>
                <li>{`"빌드잇"을 통하여 노출, 배포, 전송되는 정보를 "이용자"가 이용하여 발생하는 부동산 거래 등에 대하여 "회사"는 어떠한 도덕적, 법적 책임이나 의무도 부담하지 않습니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 20조 손해배상 등`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`"회사"는 "회원"이 서비스를 이용함에 있어 "회사"의 고의 또는 과실로 인해 손해가 발생한 경우에는 민법 등 관련 법령이 규율하는 범위 내에서 그 손해를 배상합니다.`}</li>
                <li>{`"회원"이 이 약관을 위반하거나 관계 법령을 위반하여 "회사"에 손해가 발생한 경우에는 "회사"에 그 손해를 배상하여야 합니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 21조 분쟁해결`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`“회사”와 "이용자"간에 서비스 이용과 관련하여 발생한 분쟁에 대해 "이용자"의 피해 구제 신청이 있는 경우에는 공정 거래 위원회 또는 시, 도지사가 의뢰하는 분쟁 조정 기관의 조정에 따를 수 있습니다.`}</li>
              </ol>
            </div>
            <div className="sub-title" style={{marginTop:"40px"}}>
              {`제 22조 재판권 및 준거법`}
            </div>
            <div className="content" style={{marginTop:"10px"}}>
              <ol type="1">
                <li>{`"회사"와 "회원"간 제기된 소송은 대한민국법을 준거법으로 합니다.`}</li>
                <li>{`"회사"와 "회원"간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.`}</li>
              </ol>
              <div>{`본 약관은 2020년 6월 11일부터 적용합니다.`}</div>
            </div>
          </div>
        </Fragment>
        break;
    }

    return ret;
  }

  render() {
    return (
        <div className="buildit-template" style={{ height: "100%", overflow: "auto" }}>
          <div style={{height: "60px", display: "flex", alignItems: "center", padding: "0px 30px", backgroundColor: "#232732"}}>
            <a href="/">
              <img src="/img/buildit_platform_logo.svg" style={{height: "25px", cursor: "pointer"}} />
            </a>
          </div>
          <div className="tos-page">
            <div className="header">
              <div className="content">
                <div className="switchTabs">
                  <Button className={`switchButton ${this.state.current_type === "개인 정보 처리 방침" && `enable`}`}
                    onClick={e => this.changeListType("개인 정보 처리 방침")}
                    disableRipple={true} style={{marginLeft:"2px"}}
                  >
                    개인 정보 처리 방침
                  </Button>
                  <Button className={`switchButton ${this.state.current_type === "서비스 이용 약관" && `enable`}`}
                    onClick={e => this.changeListType("서비스 이용 약관")}
                    disableRipple={true} style={{marginLeft:"2px", marginRight:"2px"}}
                  >
                    서비스 이용 약관
                  </Button>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="content">
                <div className="title">
                  {this.state.current_type}
                </div>
                <div className="line"></div>
                <div className="tos-list">
                  {this.getListContent()}
                </div>
              </div>
            </div>
          </div>
          <PageFoot />
        </div>
    )
  }
}