import React, { Component } from 'react';
import { MakeANewHouse, MakeANewCore, deleteCoreFromCoreList, deleteHouseFromHouseList } from './CoreAndHouseController';
import { BuildingTypeData, HouseData, CoreData, ConverterLayer } from './DataTypes';
import CloseIcon from '@material-ui/icons/Close'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Button } from '@material-ui/core';
import '../css/CADConverter/BuildingType.scss'
import { House } from './House';
import { Core } from './Core';

interface buildingTypeState {
  name: string,
  id: string,
  hideList: boolean,
  selectLayer: ConverterLayer | null,
  selectHouse: HouseData | null,
  totalExclusiveAreas: number,
  totalServiceAreas: number,
  totalCoreAreas: number,
  buildingArea: number,
  groundArea: number,
  settingID: string,
}

interface buildingTypeProp {
  building: BuildingTypeData,
  selectLayer: ConverterLayer | null,
  selectHouse: HouseData | null,
  settingID: string,
  recalculate: () => void,
  showLayerList: (id: string) => void,
  deleteBuilding: (buildingData: BuildingTypeData) => void,
  showHouseList: (id: string, houses: HouseData[]) => void,
}

export class BuildingType extends Component<buildingTypeProp, buildingTypeState> {
  state: buildingTypeState = {
    hideList: this.props.building.hideList,
    name: this.props.building.name,
    id: this.props.building.id,
    selectLayer: null,
    selectHouse: null,
    totalExclusiveAreas: 0,
    totalServiceAreas: 0,
    totalCoreAreas: 0,
    buildingArea: 0,
    groundArea: 0,
    settingID: this.props.settingID,
  }

  addHouse = () => {
    this.props.building.houses.push(MakeANewHouse(`세대 ${this.props.building.houses.length + 1}`));
    this.props.recalculate();
  }

  addCore = () => {
    this.props.building.cores.push(MakeANewCore(`코어 ${this.props.building.cores.length + 1}`));
    this.props.recalculate();
  }

  showHouseList = (id: string) => {
    this.props.showHouseList(id, this.props.building.houses);
  }

  removeCoreFromCoreList = (core: CoreData) => {
    deleteCoreFromCoreList(this.props.building.cores, core);
    this.props.recalculate();
  }

  removeHouseFromHouseList = (house: HouseData) => {
    deleteHouseFromHouseList(this.props.building.houses, house);

    this.props.building.cores.forEach(c => {
      if (c.houses.indexOf(house) > -1) {
        deleteHouseFromHouseList(c.houses, house, false);
      }
    })

    this.props.recalculate();
  }

  toogleList = () => {
    this.props.building.hideList = !this.state.hideList;
    this.setState({ hideList: !this.state.hideList })
  }

  render = () => {
    return (
      <div className='building' key={this.state.id}>
        <div className='nameDiv'>
          <div className='name' onClick={this.toogleList}>{this.state.name}{(this.state.hideList && <UpIcon />) || <DownIcon />}</div>
          <CloseIcon className='closeIcon' onClick={() => this.props.deleteBuilding(this.props.building)} />
        </div>
        <div className='Line' />
        <div className='buildingBody' hidden={this.state.hideList}>
          <div className='roomAndCore'>
            <div className='list'>{this.props.building.houses.map(h =>
              <House
                key={h.id}
                showLayerList={this.props.showLayerList}
                house={h}
                selectLayer={this.props.selectLayer}
                settingID={this.props.settingID}
                removeHouse={this.removeHouseFromHouseList}
                valueChanged={this.props.recalculate}
                isSettingLevel={true}
                editable={true}
                showModel={() => { }}
              />
            )}
            </div>
            <Button onClick={this.addHouse}>+ 세대 추가</Button>

            <div className='list'>{this.props.building.cores.map(c =>
              <Core
                key={c.id}
                coredata={c}
                showLayerList={this.props.showLayerList}
                selectLayer={this.props.selectLayer}
                removeCore={this.removeCoreFromCoreList}
                valueChanged={this.props.recalculate}
                settingID={this.props.settingID}
                showHouseList={this.showHouseList}
                selectHouse={this.props.selectHouse}
                editable={true}
                showModel={() => { }}
              />)}
            </div>
            <Button onClick={this.addCore}>+ 코어 추가</Button>
          </div>
        </div>
      </div >
    )
  }
}
