import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import { ConverterLayer, InputBoxType, mapProjectionData, screenInfo } from './DataTypes';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import '../css/CADConverter/HouseAndCore.scss';
import { Button } from '@material-ui/core';
import { getFieldArea, switchLayerState } from './CoreAndHouseController';
import { ConverterInputBox } from './ConverterInputBox';
import { NaverPoint } from './NaverMapManager';
import { GeotoWKT } from './SceneManager';
const uuid4 = require('uuid/v4');

export enum FieldType {
  site = '대지영역',
  road = '도로영역',
  vacancyOutside = '공지영역',
  vacancyInside = '특수영역',
}

//data controller
export class Field {
  name: string;
  typeName: FieldType;
  private layer: ConverterLayer | null;
  singlePolygon: boolean;
  private area: number;
  id: string;

  constructor(name: string, fieldType: FieldType) {
    this.name = fieldType + ' ' + name;
    this.typeName = fieldType;
    this.layer = null;
    this.singlePolygon = fieldType === FieldType.site ? true : false;
    this.area = 0;
    this.id = uuid4();
  }

  setLayer = (layer: ConverterLayer | null) => {
    let exLayer = this.layer;
    this.layer = layer;

    if (exLayer)
      switchLayerState(exLayer);

    switchLayerState(layer);

    this.area = getFieldArea(this);
  }

  getFieldLayerName = () => {
    if (this.layer)
      return this.layer.name;
    else
      return null;
  }

  getLayer = () => { return this.layer }

  setArea = (area: number) => {
    this.area = area;
  }

  getArea = () => { return this.area; }

  private getLatLonPosition(point: THREE.Vector3, mapProjData: mapProjectionData, screenInfo: screenInfo) {
    let np = point.clone().applyMatrix4(screenInfo.mvp);
    let nx = (np.x + 1) * 0.5 * screenInfo.rendererSize.x;
    let ny = (1 - (np.y + 1) * 0.5) * screenInfo.rendererSize.y;
    return mapProjData.projection.fromPageXYToCoord(NaverPoint(nx + screenInfo.offset.left, ny + screenInfo.offset.top));
  }

  getFieldLatLngs = (matrix: THREE.Matrix4, mapProj: mapProjectionData, screenInfo: screenInfo) => {
    let latlngs: any[] = [];
    if (this.layer) {
      this.layer.polygons.forEach(basePolygon => {
        if (basePolygon.selected && !basePolygon.motherPolygon) {
          let bodyPolygon: any[] = [];
          let polygon: any[] = [];
          basePolygon.vertices.forEach(v => {
            let pos = this.getLatLonPosition(v.clone().applyMatrix4(matrix), mapProj, screenInfo);
            bodyPolygon.push([pos.x + mapProj.mapOffset.x, pos.y + mapProj.mapOffset.y]);
          })

          polygon.push(bodyPolygon);
          this.layer!.polygons.forEach(holePolygon => {
            if (holePolygon.motherPolygon === basePolygon) {
              let hole: any[] = [];
              holePolygon.vertices.forEach(v => {
                let pos = this.getLatLonPosition(v.clone().applyMatrix4(matrix), mapProj, screenInfo);
                hole.push([pos.x + mapProj.mapOffset.x, pos.y + mapProj.mapOffset.y]);
              })
              polygon.push(hole);
            }
          })
          latlngs = latlngs.concat(polygon);
        }
      })
    }

    return latlngs;
  }

  getWKTArray = (matrix: THREE.Matrix4, mapProj: mapProjectionData, screenInfo: screenInfo) => {
    let polygonWKTArray: string[] = [];

    let geoJSON = {
      type: 'Polygon',
      coordinates: this.getFieldLatLngs(matrix, mapProj, screenInfo),
    }

    polygonWKTArray.push(GeotoWKT(geoJSON));

    return polygonWKTArray;
  }

  getFirstPoint = () => {
    if (this.layer) {
      return this.layer.polygons[0].vertices[0];
    }
  }
}

interface fieldProp {
  field: Field,
  selectLayer: ConverterLayer | null,
  settingID: string,
  showLayerList: (id: string) => void,
  removeField: (field: Field) => void,
  valueChanged: () => void,
}

interface fieldState {
  layerName: string | null,
  id: string,
  name: string
  value: number,
}
// user interface
export class FieldUI extends Component<fieldProp, fieldState> {
  state: fieldState = {
    layerName: this.props.field.getFieldLayerName(),
    name: this.props.field.name,
    id: this.props.field.id,
    value: this.props.field.getArea(),
  }

  componentDidMount = async () => {

  }

  componentDidUpdate = (previousProps: Readonly<fieldProp>, previousState: Readonly<fieldState>) => {
    if (this.props.settingID !== this.props.field.id) {
      return;
    }

    if (this.props.selectLayer !== previousProps.selectLayer) {
      this.props.field.setLayer(this.props.selectLayer);
      this.setState({
        layerName: this.props.field.getFieldLayerName(),
        value: this.props.field.getArea()
      });
    }
  }

  valueChanged = (value: number) => {
    this.props.field.setArea(value);
    this.props.valueChanged();
    this.setState({ value: value })
  }

  render = () => {
    return (
      <div className='field' key={this.state.id}>
        <div className='nameDiv'>{this.state.name}<CloseIcon className='closeIcon' onClick={e => this.props.removeField(this.props.field)} /></div>
        <div className='buttonDiv'>
          <Button className={`${this.props.settingID === this.props.field.id && 'selected' || 'unselected'}`} onClick={e => this.props.showLayerList(this.props.field.id)}>{this.props.field.typeName} 선택</Button>
          <CheckIcon className={`CheckIcon ${this.state.layerName && 'checked'}`} />
          {this.state.layerName}
        </div>
        <div className={`inputValues ${(this.props.field.typeName === FieldType.road || this.props.field.typeName === FieldType.vacancyOutside) && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
          <ConverterInputBox name='면적' value={this.state.value} areaType={InputBoxType.fieldArea} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />
        </div>
        <div className='Line' />
      </div>
    )
  }
}
