import AWSModule from '../AWSModule';
import * as THREE from '@teneleven/three';
import * as URI from "uri-js";
const { reproject } = require('reproject');
import wkx from 'wkx'

const projFrom = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
const projTo = "+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs";

export async function getS3Json(uri: any, isJson = true) {
  let s3 = await new AWSModule("S3").connect();

  let fileURI = URI.parse(uri, { absolutePath: true });
  let S3Data = await s3.S3!.getObject({
    Bucket: fileURI.host as string,
    Key: (fileURI.path as string).substring(1),
  }).promise();

  if (isJson) { return JSON.parse(S3Data.Body as string); }
  else { return S3Data.Body as ArrayBuffer }
}

export async function getDBData(serverStage: string, projectID: number, reportID: number) {
  let ddb = await new AWSModule("DDB").connect();

  let dbData = await ddb.Ddb!.get({
    TableName: 'engine-buildit-result',
    Key: {
      base_uuid: `${serverStage}_${projectID}_${reportID}`,
    },
  }).promise();

  return dbData.Item;
}

export function roundTimeData(time: number): number {
  let nt = Math.round(time * 60);
  return nt / 60;
}

export function tm2lonlat(tm: THREE.Vector2) {
  let js = reproject(wkx.Geometry.parse(`POLYGON((${tm.x}, ${tm.y}))`).toGeoJSON(), projTo, projFrom);
  return new THREE.Vector2(js.coordinates[0][0][0], js.coordinates[0][0][1]);
}