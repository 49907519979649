import * as THREE from '@teneleven/three';

export interface DEMData {
  value: number[][];
  pos: THREE.Vector2;
}

function getInterpolationValue(v: THREE.Vector2, value00: number, value01: number, value10: number, value11: number) {
  let vx = v.x % 1;
  let vy = v.y % 1;
  let xx0 = value00 + (value10 - value00) * vx;
  let xx1 = value01 + (value11 - value01) * vx;
  return xx0 + (xx1 - xx0) * vy;
}

export function getDemHeight(demData: DEMData[], pos: THREE.Vector2) {
  //00-----10
  //  |   |
  //01-----11
  let height = 0;
  demData.forEach(dem => {
    if (pos.x > dem.pos.x && pos.x < dem.pos.x + 2560 && pos.y > dem.pos.y && pos.y < dem.pos.y + 2560) {
      let indexX = (pos.x - dem.pos.x) / 10;
      let indexY = (pos.y - dem.pos.y) / 10;
      let floorIX = Math.floor(indexX);
      let floorIY = Math.floor(indexY);

      height = getInterpolationValue(new THREE.Vector2(indexX, indexY),
        dem.value[floorIX][floorIY],
        dem.value[floorIX][floorIY + 1],
        dem.value[floorIX + 1][floorIY],
        dem.value[floorIX + 1][floorIY + 1]);
    }
  });

  return height;
}

