import React, { Component, Fragment } from 'react';
import './css/MyCostPage.scss';
import SessionComponent from './SessionComponent';
import NextIcon from '@material-ui/icons/NavigateNext'
import Pagination from './Pagination';
import { Button } from '@material-ui/core'
import { pageContent } from './MyPage';
import App from './App';
import Tooltip from './Tooltip';
import moment from 'moment';
import AWSModule from './AWSModule';
import ProjectTypeTag from './ProjectTypeTag';
import { ProjectType } from './model/Project';
import { Link } from 'react-router-dom'
import RightIcon from '@material-ui/icons/KeyboardArrowRight';

interface costState {
  pageContent: pageContent,
}

interface costProps {

}

interface consumptionData {
  serialNum: string,
  date: string,
  purchaseState: purchaseState,
  purchaseDetial: purchaseDetial,
  price: string,
  remarks: string,
}

export enum purchaseState {
  bought = '구매',
  refund = '환불',
  all = '전체'
}

export enum purchaseDetial {
  gold = 'GOLD',
  platinum = 'PLATINUM',
}

export default class MyCostPage extends SessionComponent<costState, costProps> {
  render = () => {
    return (
      <div className='PointUsageListContent'>
        {this.props.pageContent === pageContent.purchaseHistory &&
          <PurchaseTable userName={App.session.email} /> ||
          <BuildITPointTable userName={App.session.email} />}
      </div>
    )
  }
}

interface PurchaseTableProps {
  userName: string,
}

interface PurchaseTableState {
  data: consumptionData[]
  purchaseState: purchaseState,
  viewNum: number,
  curPage: number,
}

class PurchaseTable extends Component<PurchaseTableProps, PurchaseTableState>{
  state: PurchaseTableState = {
    data: [],
    purchaseState: purchaseState.all,
    curPage: 1,
    viewNum: 10,
  }

  buttonOnClick = (purchaseState: purchaseState) => {
    App.stage !=="prod" && console.log(purchaseState);
    this.setState({
      purchaseState: purchaseState,
    })
  }

  componentWillMount = async () => {
    let data: consumptionData[] = [];

    this.setState({
      data: data,
    })
  }

  onPageChange = (page: number) => {
    this.setState({
      curPage: page,
    })
  }

  render = () => {
    const detailInfo = `구매 내역이 없습니다.`
    let infoText = <div className='noItemText font bg-navy font-secondary font-14px font-roboto'>{detailInfo}</div>

    if (this.state.data.length > 0) {
      infoText = <div className='pagination'>
        <Pagination totalNum={this.state.data.length} viewNum={this.state.viewNum} curPage={this.state.curPage} onChange={this.onPageChange} />
      </div>
    }

    return (
      <div>
        <div className='header'>
          <div className='description font bg-navy font-primary font-noto font-18px'>
            <div>구매 내역</div>
            <Tooltip msg={"미정"} className="tooltip">
            <div className='icon'>
              <object type="image/svg+xml" data="/img/my_page/icon_help.svg"/>
            </div>
            </Tooltip>
          </div>
          <div className='switchTabs btn'>
            <Button className={`switchButton ${this.state.purchaseState === purchaseState.all && 'enable'}`} onClick={() => this.buttonOnClick(purchaseState.all)} disableRipple={true}>전체</Button>
            <Button className={`switchButton ${this.state.purchaseState === purchaseState.bought && 'enable'}`} onClick={() => this.buttonOnClick(purchaseState.bought)} style={{ marginRight: "2px", marginLeft: "2px" }} disableRipple={true}>구매</Button>
            <Button className={`switchButton ${this.state.purchaseState === purchaseState.refund && 'enable'}`} onClick={() => this.buttonOnClick(purchaseState.refund)} disableRipple={true}>환불/취소</Button>
          </div>
        </div>
        
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th className='purchase serialNum'>거래번호</th>
                <th className='purchase date'>거래일</th>
                <th className='purchase purchaseState'>내역</th>
                <th className='purchase purchaseDetail'>상세 내역</th>
                <th className='purchase price'>결제 금액</th>
                <th className='purchase remarks'>비고</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map(d => {
                if (this.state.purchaseState === purchaseState.all ||
                  this.state.purchaseState === d.purchaseState)
                  {
                    return (
                      <tr key={d.serialNum}>
                        <td className='serialNum'>{d.serialNum}</td>
                        <td className='date'>{d.date}</td>
                        <td className='purchaseState'>{d.purchaseState}</td>
                        <td className='purchaseDetail'>패키지[{d.purchaseDetial}]</td>
                        <td className='price rightAlign'>{new Intl.NumberFormat().format(Number(d.price))}원</td>
                        <td className='remarks'>{d.remarks}</td>
                      </tr>
                    )
                  }
              })}
            </tbody>
          </table>
          {infoText}
        </div>
      </div>
    )
  }
}

export enum pointState {
  all = '전체',
  recharge = '충전',
  use = '사용'
}

interface BITPointTableProps {
  userName: string,
}

interface BITPointTableState {
  data: pointData[],
  pointState: pointState,
  viewNum: number,
  curPage: number,
  totalNum: number,
}

interface pointData {
  serialNum?: string,
  date?: string,
  state?: pointState,
  detail?: string,
  BIPoint?: number,
  servicePoint?: number,
  remarks?: JSX.Element,
  project_id?: number,
  project_type?: ProjectType,
  deleted?: boolean,
}

class BuildITPointTable extends Component<BITPointTableProps, BITPointTableState>{
  state: BITPointTableState = {
    data: [],
    pointState: pointState.all,
    curPage: 1,
    viewNum: 10,
    totalNum: 0,
  }

  componentWillMount = async () => {
    this.onPageChange(1);
  }

  getPointLog = async (startNum: number) => {
    let q = `stage.keyword:${App.stage} AND user_id:${App.session.id}`

    if (this.state.pointState === pointState.recharge) {
      q = `stage.keyword:${App.stage} AND user_id:${App.session.id} AND amount: >0`;
    } else if (this.state.pointState === pointState.use) {
      q = `stage.keyword:${App.stage} AND user_id:${App.session.id} AND amount: <=0`;
    }

    const r = await App.search({
      "table": "platform-buildit-global-dev-pointlog-1rwn7i66cxo8b",
      "query": q,
      "from": startNum,
      "size": this.state.viewNum,
      "sort": [
        {
          "timestamp": "desc",
        },
      ],
    });

    return {
      Payload: JSON.stringify(r.data),
    }
  }

  buttonOnClick = (pointState: pointState) => {
    App.stage !=="prod" && console.log(pointState);
    this.setState({
      pointState: pointState,
    }, () => {
      this.onPageChange(1)
      return;
    })
  }

  makeRemarkString = async (s: any) => {
    let returnString: JSX.Element = <div>-&nbsp;-</div>;

    if (s) {
      if(s.v3) {
        const r = await App.search({
          "table": 'platform-buildit-project',
          "query": `user_project_id:${s.v3.user_project_id}`,
        });
        const rr = r.data;
        if (rr.hits && rr.hits.hits && rr.hits.hits.length > 0) {
          if (rr.hits.hits[0]._source) {
            let project = rr.hits.hits[0]._source;
            returnString = <Fragment>
                            <Tooltip msg={project.deleted ? "삭제 된 프로젝트 입니다." : "프로젝트 결과로 이동합니다."}>
                              <div className={`project-number ${project.deleted && "disabled"}`}>
                                <a className={`result-link `} href={project.deleted ? `` : `/project/result?pid=${project.user_project_id}`}>
                                  {`프로젝트 번호: ${project.user_project_id},`}
                                </a>
                              </div>
                            </Tooltip>
                            
                            <div className="project-type">
                              <div className="type-title">
                                {`프로젝트 타입: `}
                              </div>
                              <ProjectTypeTag className="type-tag" background="DARK" type={project.project_type}></ProjectTypeTag>
                            </div>
                          </Fragment>
          }
        }
      }
    } else {
      returnString = <div>-</div>;
    }

    return returnString;
  }

  onPageChange = async (page: number) => {
    const r = await this.getPointLog((page - 1) * this.state.viewNum);
    
    let hits = JSON.parse(r.Payload as string);
    let data: pointData[] = [];
    if (hits && hits.hits && hits.hits.hits) {
      let logs = hits.hits.hits;

      if (logs.length === 0) {
        page -= 1;
        if (page < 1) {
          data =[];
        } else {
          const r = await this.getPointLog((page - 1) * this.state.viewNum);
          let hits = JSON.parse(r.Payload as string);
          logs = hits.hits.hits;
        }
      }
      
      const totalCnt = Math.ceil(hits.hits.total);

      data = await Promise.all(logs.map(async (e: any, i: number) => {
        let project_id: number | undefined = undefined;
        let project_type: string | undefined = undefined;
        let deleted: boolean | undefined = undefined;
        if (e._source.info) {
          if(e._source.info.v3) {
            const r = await App.search({
              "table": 'platform-buildit-project',
              "query": `user_project_id:${e._source.info.v3.user_project_id}`,
            });
            const rr = r.data;
            if (rr.hits && rr.hits.hits && rr.hits.hits.length > 0) {
              if (rr.hits.hits[0]._source) {
                let project = rr.hits.hits[0]._source;
                project_id = project.user_project_id;
                project_type = project.project_type;
                deleted = project.deleted;
              }
            }
          }
        } 
        
        return {
          serialNum: (totalCnt - (((page - 1) * this.state.viewNum) + i)).toString(),
          date: e._source.timestamp as string,
          state: (e._source.amount > 0) ? pointState.recharge : pointState.use as pointState,
          BIPoint: e._source.amount as number,
          project_id: project_id,
          project_type: project_type,
          deleted: deleted,
        };;
      }));

      this.setState({
        curPage: page,
        data: data,
        totalNum: totalCnt,
      })
    } else {
      this.setState({
        curPage: 1,
        data: [],
        totalNum: 0,
      })
    }
  }

  getProjectIDtd = (data: pointData) => {
    let ret = <td className="point project-number">{`- -`}</td>

    if (data.state === pointState.use && data.project_id !== undefined) {
      if (data.deleted) {
        ret = (
          <Tooltip msg={"삭제 된 프로젝트 입니다."}>
            <td className={`point project-number`}>
              <div className="content-wrapper">
                <div className="contnet">
                  {data.project_id}
                </div>
                <RightIcon className="icon"></RightIcon>
              </div>
            </td>
          </Tooltip>
        ) 
      } else {
        ret = (
          <Tooltip msg={"프로젝트 결과로 이동합니다."}>
            <Link style={{textDecoration:"none"}} to={`/project/result?pid=${data.project_id}`}> 
              <td className={`point project-number hoverable`}>
                <div className="content-wrapper">
                  <div className="contnet">
                    {data.project_id}
                  </div>
                  <RightIcon className="icon"></RightIcon>
                </div>
              </td>  
            </Link>
          </Tooltip>
        )
      }
    }
    return ret
  }

  render = () => {
    const detailInfo = `${this.state.pointState} 포인트 내역이 없습니다.`
    let infoText = <div className='noItemText font bg-navy font-secondary font-14px font-roboto'>{detailInfo}</div>

    if (this.state.data.length > 0) {
      infoText = <div className='pagination'>
        <Pagination totalNum={this.state.totalNum} viewNum={this.state.viewNum} curPage={this.state.curPage} onChange={this.onPageChange} />
      </div>
    }

    return (
      <div>
        <div className='header'>
          <div className='description font bg-navy font-primary font-noto font-18px'>
            <div>포인트 내역</div>
            <Tooltip msg={"프로젝트 번호 클릭시 해당 프로젝트 결과로 이동합니다."} className="tooltip">
            <div className='icon'>
              <object type="image/svg+xml" data="/img/my_page/icon_help.svg"/>
            </div>
            </Tooltip>
          </div>
          { <div className='switchTabs btn'>
            <Button className={`switchButton ${this.state.pointState === pointState.all && 'enable'}`} onClick={() => this.buttonOnClick(pointState.all)} disableRipple={true}>전체</Button>
            <Button className={`switchButton ${this.state.pointState === pointState.recharge && 'enable'}`} onClick={() => this.buttonOnClick(pointState.recharge)} style={{ marginRight: "2px", marginLeft: "2px" }} disableRipple={true}>충전</Button>
            <Button className={`switchButton ${this.state.pointState === pointState.use && 'enable'}`} onClick={() => this.buttonOnClick(pointState.use)} disableRipple={true}>사용</Button>
          </div> }
        </div>

        {/* <div className='table'>
          <table>
            <thead>
              <tr>
                <th className='point serialNum'>거래번호</th>
                <th className='point date'>거래일</th>
                <th className='point state'>내역</th>
                <th className='point detial'>상세 내역</th>
                <th className='point BIPoint'>빌드잇 포인트</th>
                <th className='point servicePoint'>서비스 포인트</th>
                <th className='point totalPoint'>포인트 합계</th>
                <th className='point remarks'>비고</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map(d => {
                if (this.state.pointState === pointState.all || 
                  d.state === this.state.pointState) {
                  return (
                    <tr key={d.serialNum}>
                      <td className='point serialNum'>{d.serialNum}</td>
                      <td className='point date'>{d.date}</td>
                      <td className='point state'>{d.state}</td>
                      <td className='point detail'>{d.detail}</td>
                      <td className='point BIPoint rightAlign'>{d.BIPoint > 0 && '+' || '-'} {new Intl.NumberFormat().format(d.BIPoint)}P</td>
                      <td className='point servicePoint rightAlign'>{d.BIPoint > 0 && '+' || '-'}{new Intl.NumberFormat().format(d.servicePoint)}P</td>
                      <td className='point totalPoint rightAlign'>{d.BIPoint > 0 && '+' || '-'}{new Intl.NumberFormat().format(d.BIPoint + d.servicePoint)}P</td>
                      <td className='point remarks'>{d.remarks}</td>
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
          {infoText}
        </div> */}

        <div className='table'>
          <table>
            <thead>
              <tr>
                <th className='point serialNum'>거래번호</th>
                <th className='point date'>거래일</th>
                <th className='point state'>내역</th>
                <th className='point BIPoint '>포인트</th>
                <th className='point project-number '>프로젝트 번호</th>
                <th className='point project-type '>프로젝트 타입</th>
                <th className='point remarks'>비고</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map(d => {
                if (this.state.pointState === pointState.all || 
                  d.state === this.state.pointState) {
                  return (
                    <Fragment>
                      <tr key={d.serialNum}>
                        <td className='point serialNum'>{d.serialNum}</td>
                        <td className='point date'>{moment(d.date).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td className='point state'>{d.state}</td>
                        <td className='point BIPoint'>{d.BIPoint && d.BIPoint > 0 && '+' || ''} {d.BIPoint && new Intl.NumberFormat().format(d.BIPoint)}P</td>
                        {
                          this.getProjectIDtd(d)
                        }
                        <td className='point project-type '>
                          {
                            d.project_type ?
                            <ProjectTypeTag className="type-tag" background="DARK" type={d.project_type}></ProjectTypeTag>
                            :
                            `- -`
                          }
                        </td>
                        <td className='point remarks'>{`- -`}</td>
                      </tr>
                    </Fragment>
                    
                  )
                }
              })}
            </tbody>
          </table>
          {infoText}
        </div>
      </div>
    )
  }
}
