import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import './css/MyInformationPage.scss';
import { Button, InputAdornment, Checkbox } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import BuilditInput, { BuilditInputProps, InputAttr } from './BuilditInput';
import App from './App';
// import { FaceNormalsHelper } from '@teneleven/three';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { RegisterInput } from './RegistrationUser';
import { default as _ } from 'lodash';
import AWS, { CognitoIdentityServiceProvider } from 'aws-sdk';
import { User } from './model/User';
import AWSModule from './AWSModule';
import Modal, { ModalOptions } from './Modal';

interface infoState {
  checked: boolean,
  password: string,
  confirmPassword: string,
  name: string,
  company: string,
  contact: string,
  passwordInput: InputAttr,
  confirmInput: InputAttr,
  passwordRequiredList: Array<{key: string, checked: boolean, regex: RegExp}>,
  prevPassword: string,
  accessToken: string,
  modalOptions?: ModalOptions;
  openModal: boolean;
  openPassword: boolean;
  checkedPrivacy: boolean;
}

interface infoProps {
  userEmail: string,
}

type TOSModalType = "Privacy";

export default class MyInformationPage extends SessionComponent<infoProps, infoState> {

  state: infoState = {
    checked: false,
    password: "",
    confirmPassword: "",
    name: App.session.name,
    company: App.session.company,
    contact: App.session.contact_number,
    passwordInput: {
      requiredCheck: false,
      error: false,
    },
    confirmInput: {
      requiredCheck: false,
      error: false,
    },
    passwordRequiredList: [
      {key: "대문자", checked: false, regex: /[A-Z]/},
      {key: "소문자", checked: false, regex: /[a-z]/},
      {key: "숫자", checked: false, regex: /[0-9]/},
      {key: "특수문자", checked: false, regex: /[!@#$%^\&\*\(\)\-\+]/},
      {key: "8글자이상", checked: false, regex: /.{8}/},
    ],
    prevPassword: "",
    accessToken: "",    
    openModal: false,
    openPassword: true,
    checkedPrivacy: false,
  }

  componentWillMount = () => {
    
  } 


  componentDidUpdate = (pp: Readonly<infoProps>, ps: Readonly<infoState>) => {
    if (this.state.checked === false) { 
      return
    }

    if (!_.isEqual(this.state.password, ps.password)) {
      this.setState({
        passwordRequiredList: this.state.passwordRequiredList.map(v => {
          v.checked = Boolean(this.state.password.match(v.regex));
          return v;
        }),
      })
    }
  }

  checkPasswordValidation = () => {
    if(this.state.password.length === 0) {
      return false
    } 

    if(this.state.passwordRequiredList.findIndex(e => e.checked === false) > -1) {
      this.setState({
        passwordInput: {
          error: true,
          requiredCheck: false,
        }
      })
      return false
    } else {
      if(this.state.confirmPassword.length > 0) {
        this.checkConfirmValidation()
      }
      return true
    }
  }

  checkConfirmValidation = () => {
    if(this.state.confirmPassword.length === 0) {
      return false
    }

    if(this.state.passwordRequiredList.findIndex(e => e.checked === false) > -1) {
      return false
    }

    let error = true
    if (this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0 && this.state.password === this.state.confirmPassword) {
      error = false;
    }
    this.setState({
      confirmInput: {
        error: error,
        requiredCheck: !error,
        msg: error && "입력한 비밀번호가 일치하지 않습니다" || undefined
      }
    })
    return !error
  }

  checkPassword = () => {
    if(this.state.password.length === 0) {
      this.setModal(true, "비밀번호 확인", "비밀번호를 입력해 주십시오.");
    } else {
      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: App.USER_POOL_ID,
        ClientId: App.COGNITO_CLIENT_ID,
      })

      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: this.props.userEmail,
        Password: this.state.password,
      })

      const userData = {
        Username: this.props.userEmail,
        Pool: userPool,
      }

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: this.successConfirm,
        onFailure: this.failConfirm,
      })
    }
  }

  failConfirm = async (err: any) => {
    switch(err.code) {
      case "NotAuthorizedException":
        this.setModal(true, "비밀번호 확인", "등록하신 비밀번호와 일치하지 않습니다.");
        break
      default:
        this.setModal(true, "비밀번호 확인", "알 수 없는 오류로 실패했습니다.");
        break
    }
  }

  successConfirm = async (result: AmazonCognitoIdentity.CognitoUserSession) => {
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: App.IDENTITY_POOL_ID,
        Logins: {
          [`cognito-idp.ap-northeast-2.amazonaws.com/${App.USER_POOL_ID}`]: result.getIdToken().getJwtToken()!,
        }
      })
      this.setState({accessToken: result.getAccessToken().getJwtToken()})
      this.resetState(true)
  }

  reviseInformation = async () => {
    if (this.checkPasswordValidation() && this.checkConfirmValidation()) {
        const params = {
          AccessToken: this.state.accessToken,
          PreviousPassword: this.state.prevPassword,
          ProposedPassword: this.state.password,
        }
        const cognitoService = new CognitoIdentityServiceProvider()
        cognitoService.changePassword(params, async (err, data) => {
          if (err) {
            this.setState({
              password: "",
              confirmPassword: "",
              passwordInput: {requiredCheck: false, error: false},
              confirmInput: {requiredCheck: false, error: false},
            })
            if(err.code === "LimitExceededException") {
              this.setModal(true, "회원 정보 수정", "잠시 후 다시 시도해주시기 바랍니다..");
            } else {
              this.setModal(true, "회원 정보 수정", "비밀번호 변경에 실패했습니다.");
            }
          } else {
            // let preCompany: string = "";
            // let preContact: string = "";
            // let preName: string = "";

            // if (!(this.state.company.length > 0 && this.state.name.length > 0 && this.state.contact.length > 0)) {
              
            //   const aws = await new AWSModule("DDB").connect();
            //   const r = await aws.Ddb!.get({
            //     TableName: 'platform-buildit-user',
            //     Key: {
            //       "id": aws.id!,
            //     }
            //   }).promise();

            //   if (r.Item) {
            //     preCompany = r.Item.company;
            //     preContact = r.Item.contact_number;
            //     preName = r.Item.name;
            //   }
            // } 

            // const user: User = {
            //   id: App.session.id,
            //   email: this.props.userEmail,
            //   company: this.state.company.length > 0 ? this.state.company : preCompany,
            //   contact_number: this.state.contact.length > 0 ? this.state.contact : preContact,
            //   name: this.state.name.length > 0 ? this.state.name : preName,
            //   point: App.session.point,
            //   service_point: App.session.service_point,
            // };
            // const ddb = new AWS.DynamoDB.DocumentClient();
            // const dr = await ddb.put({
            //   TableName: "platform-buildit-user",
            //   Item: {...user, stage: App.stage},
            // }).promise();
            this.resetState(true)
            await App.updateSession();
            this.setModal(true, "회원 정보 수정", "비밀번호가 변경 되었습니다.");
          } 
        })
    } else {
      this.setModal(true, "회원 정보 수정", "대문자/소문자/숫자/특수문자/글자수를 확인해주세요");
    }
  }

  revisePrivacyInfo = async () => {
    if (this.state.checkedPrivacy || App.session.optional_consent) {
      let preCompany: string = "";
      let preContact: string = "";
      let preName: string = "";
      const aws = await new AWSModule("DDB").connect();

      //if (!(this.state.company.length > 0 && this.state.name.length > 0 && this.state.contact.length > 0)) {
        const r = await aws.Ddb!.get({
          TableName: 'platform-buildit-user',
          Key: {
            "id": aws.id!,
          }
        }).promise();

        if (r.Item) {
          preCompany = r.Item.company;
          preContact = r.Item.contact_number;
          preName = r.Item.name;
        }
      //} 

      const user: User = {
        id: App.session.id,
        email: this.props.userEmail,
        company: this.state.company.length > 0 ? this.state.company : this.state.company,
        contact_number: this.state.contact.length > 0 ? this.state.contact : this.state.contact,
        name: this.state.name.length > 0 ? this.state.name : this.state.name,
        point: App.session.point,
        service_point: App.session.service_point,
        optional_consent: true,
      };

      const lambda = await new AWSModule("LAMBDA").connect();
      const dr = await lambda.Lambda!.invoke({
        FunctionName: "platform-buildit-fn-UpdateDynamodb-r1-v20190816",
          Payload: JSON.stringify([{
            TableName: "platform-buildit-user",
            Item: {
              stage: App.stage,
              ...user,
            }
          }])
        }).promise();
        
      this.setModal(true, "회원 정보 수정", "회원 정보가 변경 되었습니다.");
      await App.updateSession();
      this.resetState(true);

    } else {
      this.setModal(true, "개인정보 수집 및 이용(선택)", "약관 동의가 필요합니다.")
    }
  }

  resetState = (isChecked: boolean, prevPassword?: string) => {
    this.setState({
      checked: isChecked,
      password: "",
      confirmPassword: "",
      name: App.session.name,
      company: App.session.company,
      contact: App.session.contact_number,
      passwordInput: {requiredCheck: false, error: false},
      confirmInput: {requiredCheck: false, error: false},
      prevPassword: isChecked ? this.state.password : "",
      openPassword: true,
    })
  }

  setModal = (open: boolean, title?: string, content?: string) => {
    this.setState({
      openModal: open,
      modalOptions: {
        color: "DARK",
        type: "SIMPLE",
        positive: () => {
          this.setModal(false);
        },
        negative: "hidden",
        content: <div style={{ display: "flex", alignItems: "center" }}>{content}</div>,
        title: title,
      },
    });
  }

  setTOSModal = (open: boolean, type: TOSModalType) => {
    let title: string = "";

    switch (type) {
      case "Privacy":
        title = "개인정보 수집 및 이용 (선택)";
        break;
      default:
        title = "약관 동의";
    }

    this.setState({
      openModal: open,
      modalOptions: {
        title: title,
        color: "DARK",
        negative: () => { this.setState({openModal: false}) },
        positive: () => {
          this.setState({
            openModal: false,
            checkedPrivacy: true,
          })
        },
        content: this.getTOSContent(type),
        
      },
    });
  }

  getTOSContent = (type: TOSModalType) => {
    let content = <div></div>
    switch(type) {
      case "Privacy":
        content = 
        <div>
          제 1 조 (목적)
          이 약관은 네이버(주)(이하 ‘회사’라 합니다)가 제공하는 네이버 유료서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항 기타 필요한 사항을 규정함을 목적으로 합니다.
          제 2 조 (약관의 게시와 개정)
          ①회사는 이 약관을 회원이 그 전부를 인쇄할 수 있고 확인할 수 있도록 기술적 조치를 취합니다.
          ②회사는 회원이 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회 등과 같은 중요한 내용을 회원이 쉽게 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공합니다.
          ③회사는 ‘콘텐츠산업진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’ 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
          ④회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 서비스 초기 화면에 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 30일 전부터 적용일자 전일까지 공지하며, 공지 외에 일정기간 서비스 내 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
          ⑤회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 전항의 공지기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
          ⑥회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
          ⑦회원은 전항에 따른 이용계약의 해지로 손해가 발생한 경우 회사에 이에 대한 배상을 청구할 수 있습니다.
          제3조 (약관의 해석)
          이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 ‘콘텐츠진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’, 문화체육관광부장관이 정하는 ‘콘텐츠이용자보호지침’, 기타 관계법령, ‘네이버 이용약관’ 또는 상관례에 따릅니다.
          제4조 (회원에 대한 통지)
          ①회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.
          ②회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 통지를 합니다.
          제5조 (유료서비스의 내용 등의 게시)
          ①회사는 다음 사항을 해당 네이버 유료서비스의 이용 초기화면이나 FAQ 등에 회원이 알기 쉽게 표시합니다.
          1.네이버 유료서비스의 명칭 또는 제호
          2.네이버 유료서비스 제공자의 성명(법인인 경우에는 법인의 명칭), 주소, 전화번호
          3.네이버 유료서비스의 내용, 이용방법, 이용료, 기타 이용조건
          ②회사의 유료서비스의 이용 가능 기기 및 이용에 필요한 최소한의 기술사양은 권장사양정보에 따릅니다.
          ③회사는 유료서비스를 제공함에 있어 유료 서비스의 교환·반품·보증과 그 대금 환불의 조건 및 절차에 관한 사항을 제공합니다.
          제6조 (이용계약의 성립 등)
          ①회원은 회사가 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. 회사는 계약 체결 전에 각 호의 사항에 관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.
          1.유료서비스의 확인 및 선택
          2.결제방법의 선택 및 결제정보의 입력
          3.유료서비스의 이용신청에 관한 확인 또는 회사의 확인에 대한 동의
          ②회사는 회원의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.
          1.실명이 아니거나 타인의 명의를 이용한 경우
          2.허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
          3.미성년자가 ‘청소년보호법’ 등 관련 법령에 의해서 이용이 금지되는 유료서비스를 이용하고자 하는 경우
          4.서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우
          ③이용계약의 성립시기는 ‘가입완료’ 또는 ‘구매완료’를 신청절차 상에서 표시한 시점으로 합니다.
          ④회원이 유료서비스를 이용하기 위해서는 이 약관에 동의 후 각 서비스에 따른 이용조건에 따라 이용요금을 지급하여야 합니다.
          제7조 (유료서비스의 중단 및 변경)
          ①회사는 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 네이버 유료서비스를 제공할 수 없게 되는 경우에는 회사는 이 약관에서 정한 방법으로 회원에게 통지하고 당초 회사에서 제시한 조건 또는 ‘콘텐츠이용자보호지침’ 및 관련 법령 규정에서 정한 바에 따라 회원에게 보상합니다.
          ②회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부의 네이버 유료서비스를 변경할 수 있고, 변경 전 해당 서비스 초기 화면에 관련 사항을 게시합니다. 다만, 변경된 내용이 중대하거나 회원에게 불리한 경우에는 이 약관에서 정한 방법으로 통지하고, 중대하거나 회원에게 불리한 변경 내용에 동의하지 않는 회원은 제8조에서 정한 바에 따라 네이버 유료서비스 이용계약을 해지할 수 있습니다.
          제8조 (회원의 청약철회 및 계약해지)
          ①회사와 네이버 유료서비스 이용계약을 체결한 회원은 관련 법령에서 정한 바에 따라 일정 기간 내에 청약을 철회할 수 있습니다. 단, 네이버 유료서비스에는 ‘콘텐츠산업진흥법’, ‘전자상거래등에서의소비자보호에관한법률’ 등에서 규정하는 청약철회가 불가능한 서비스가 포함되어 있습니다. 이 경우 회사는 청약철회권 제한을 위해 관련 법령상 필요한 조치를 취합니다.
          ②회원은 다음 각 호의 사유가 있을 때 네이버 유료서비스 이용계약을 해지 또는 해제할 수 있습니다.
          1.네이버 유료서비스의 하자를 회사가 보완, 수정할 수 없는 경우 네이버 유료서비스를 공급받은 날로부터 1개월 이내
          2.네이버 유료서비스 회원이 이 약관의 개정 또는 제7조 제2항 단서에 따른 서비스 변경에 동의하지 않아 회원탈퇴 또는 네이버 유료 서비스 이용계약을 해지하는 경우
          ③회원이 전항에 따라 네이버 유료서비스 이용계약을 해지 또는 해제하는 경우 회사는 회원으로부터 지급받은 대금을 당초 회사에서 정한 조건 또는 ‘콘텐츠이용자보호지침’ 및 관련 법령 규정, 이 약관에서 정한 바에 따라 회원에게 환불합니다.
          ④회사, 네이버 유료서비스의 대금을 지급 받은 자 또는 회원과 네이버 유료서비스 이용계약을 체결한 자가 동일인이 아닌 경우에 각자는 청약철회 등과 관련한 의무의 이행에 있어서 연대하여 책임을 집니다.
          제9조 (회사의 계약해제, 해지 및 이용제한)
          ①회사는 회원이 네이버 이용약관에서 정한 금지행위를 하였을 경우 해당 조항에 따라 사전통지 없이 계약을 해제, 해지하거나 또는 기간을 정하여 서비스이용을 제한할 수 있습니다.
          ②제1항의 사유로 환불이 필요할 경우 회사는 회원이 유료서비스 이용으로부터 얻은 이익 및 환불수수료(10% 또는 1,000원 중 큰 금액)에 해당하는 금액을 공제하고 다음 각호에 따라 환불합니다.
          1.회사는 회원에게 계약해제, 해지의 의사표시에 대하여 회신한 날로부터 3영업일 이내에 대금의 결제와 동일한 방법으로 이를 환불하여야 하고 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지하여야 합니다. 다만, 수납확인이 필요한 결제수단의 경우에는 수납확인일로부터 3영업일 이내에 이를 환불하도록 합니다.
          2.회사는 위 대금을 환불함에 있어서 회원이 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 제2항 각 호외의 본문 및 제2항 제1호 단서의 경우에는 그러하지 아니할 수 있습니다.
          ③제1항의 해제, 해지는 회사가 정한 통지방법에 따라 회원에게 그 의사를 표시한 때에 효력이 발생합니다.
          ④회사의 해제, 해지 및 이용제한에 대하여 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우, 회사는 즉시 서비스의 이용을 재개합니다.
          제10조 (사용기간 등)
          네이버 유료서비스의 사용기간은 사전에 별도로 표시되지 않는 한 구매일로부터 1년입니다. 해당 기간이 경과한 네이버 유료서비스는 이용권이 소멸되며 보유목록에서 삭제됩니다.
          제11조 (유료서비스 하자 등에 의한 회원피해보상)
          회사는 유료서비스의 하자 등에 의한 회원의 피해보상 기준, 범위, 방법 및 절차에 관한 사항을 ‘콘텐츠이용자보호지침’에 따라 처리합니다.
          제12조 (책임 제한)
          ①회사는 관계법령의 변경, 천재지변 또는 이에 준하는 불가항력으로 인하여 유료서비스를 제공할 수 없는 경우에는 유료서비스 제공에 관한 책임이 면제됩니다.
          ②회사는 회원의 귀책사유로 인한 유료서비스 이용의 장애에 대하여는 회사의 귀책사유가 없는 한 책임을 지지 않습니다.
          ③회사는 회원 상호간 또는 회원과 제3자간에 유료서비스를 매개로 하여 발생한 분쟁 등에 대하여 회사의 귀책사유가 없는 한 책임을 지지 않습니다.
          [부칙]
          1.이 약관은 2019년 11월 1일부터 적용됩니다.
          2.2019년 7월 31일부터 시행되던 종전의 ‘네이버페이 이용약관’은 네이버페이(Naver Pay) 서비스 사업부문의 물적분할에 따라 본 약관으로 대체합니다.
          3.종전의 ‘네이버페이 이용약관’에 동의한 회원의 경우, 해당 약관 제1장(네이버페이 서비스) 및 제3장(네이버페이 안전결제)에 따른 효력은 그대로 유지되며, 11월 1일자로 개정되는 ‘네이버 유료서비스 이용약관’, ‘네이버페이 이용약관’(11월 1일자로 분사되는 네이버파이낸셜㈜가 제공)에 대한 별도의 동의는 필요하지 않습니다.
          
          이전 이용약관 보기 (2019년 7월 31일 ~ 2019년 10월 31일 적용)
        </div>
        break;
      default:
        content = <div></div> 
        break;
    }
    return content;
  }

  render = () => {
    let contentDiv = 
    <Fragment>
      <div className='title font bg-navy font-primary font-18px font-noto'>비밀번호 확인</div>
      <div>
        <div className='tips font bg-navy font-primary font-18px font-noto'>개인정보를 안전하게 보호하기 위하여 비밀번호를 한번 더 입력해주세요.</div>
      
        <div className='pwDiv'>
          <RegisterInput type='password' className='inputValue' placeholder='비밀번호' value={this.state.password} 
          onChange={e => {this.setState({password: e as string})}} onKeyDown={e => {
            if (e.keyCode === 13) {
              this.checkPassword();
            }
            return
          }} capsLockTooltip={true}/>
        </div>
        <div className='buttonGroup'>
          <Button className='btn bg-navy btn-primary enter' onClick={this.checkPassword} disableRipple={true}>확인</Button>
        </div>
      </div>
      
      </Fragment>

    if (this.state.checked) {
      contentDiv = 
      <Fragment>
        <div className='title font bg-navy font-primary font-18px font-noto'>회원 정보 수정</div>
        <div>
          <div>
            <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "28px"}}>이메일 주소</div>
            <div className='itemValue font bg-navy font-primary font-14px font-roboto'>{this.props.userEmail}</div>
          </div>
          <div className="wrap-password">
            {
              this.state.openPassword && 
              <Fragment>
                <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "25px"}}>비밀번호 변경</div>
                <div className='pwDiv' style={{marginTop: "8px"}}>
                  <RegisterInput id="password-input" type='password' className='register-input inputValue' placeholder='새 비밀번호' value={this.state.password} 
                    onChange={e => this.setState({
                      password: e as string,
                      passwordInput: {
                        error: false,
                      }
                    })}
                    onBlur={this.checkPasswordValidation}
                    error={this.state.passwordInput.error}
                    requiredCheck= {
                      this.state.passwordRequiredList && this.state.passwordRequiredList.length > 0 &&
                      this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0
                    }
                    requiredList={this.state.passwordRequiredList}
                    required={true} capsLockTooltip={true}/>
                  
                </div>
                <div className='pwDiv' style={{marginTop: "10px"}}>
                  <RegisterInput id="confirm-input" type='password' className='register-input inputValue' placeholder='새 비밀번호 확인' value={this.state.confirmPassword} 
                    onChange={e => this.setState({
                      confirmPassword: e as string,
                      confirmInput: {
                        error: false,
                        msg: undefined,
                        requiredCheck: false,
                      }
                    })}
                    onBlur={this.checkConfirmValidation}
                    error={this.state.confirmInput.error}
                    errMsg={this.state.confirmInput.msg}
                    requiredCheck={
                      this.state.passwordRequiredList && this.state.passwordRequiredList.length > 0 &&
                      this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0 &&
                      this.state.password === this.state.confirmPassword
                    }
                    required={true} capsLockTooltip={true}>
                  </RegisterInput>
                </div>
                <div className='buttonGroup' style={{marginTop: "10px",}}>
                  {/* <Button className='btn bg-navy btn-cancel cancel' onClick={() => {
                    this.setState({openPassword: false, 
                      password: "", 
                      confirmPassword: "",
                      passwordInput: {
                        error: false,
                      }});
                    } } disableRipple={true}>변경 취소</Button> */}
                  <Button className='btn bg-navy btn-primary revise' onClick={this.reviseInformation} disableRipple={true}>변경</Button>
                </div>
              </Fragment>
              ||
              <Fragment>
                <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "25px"}}>비밀번호</div>
                <Button className="large-button btn bg-navy btn-primary" style={{marginTop: "8px"}}
                  disableRipple={true}
                  onClick={e => {
                    this.setState({openPassword: true});
                  }}
                >
                  비밀번호 변경
                </Button>
              </Fragment>
            }
          </div>
          {/* <div className="wrap-privacy">
            <div>
              <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "18px"}}>이름 또는 기업명</div>
              <div className='pwDiv' style={{marginTop: "8px"}}><BuilditInput type='text' className='inputValue' placeholder='이름 또는 기업명' value={this.state.name} onChange={e => {this.setState({name: e as string})}}></BuilditInput></div>
            </div>
            <div>
              <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "18px"}}>소속 회사</div>
              <div className='pwDiv' style={{marginTop: "8px"}}><BuilditInput type='text' className='inputValue' placeholder='소속 회사' value={this.state.company} onChange={e => {this.setState({company: e as string})}}></BuilditInput></div>
            </div>
            <div>
              <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "18px"}}>연락처</div>
              <div className='pwDiv' style={{marginTop: "8px"}}><BuilditInput type='text' className='inputValue' placeholder='연락처' value={this.state.contact} onChange={e => {this.setState({contact: e as string})}}></BuilditInput></div>
            </div>
            {
              !App.session.optional_consent &&
              <div className="wrap-tos">
                <div className="header">
                  <div className="content">
                    약관 동의
                  </div>
                </div>
                <div className="row">
                  <div className="title">개인정보 수집 및 이용 (선택)</div>
                  <div className="content">
                    <div className="detail-link" onClick={e => {
                      this.setTOSModal(true, "Privacy");
                    }}>
                      내용보기
                    </div>
                    <Checkbox
                      className={`m-l-md checkbox ${this.state.checkedPrivacy && "checked" || ""}`} checked={this.state.checkedPrivacy} 
                      onChange={e => {
                        this.setState({
                          checkedPrivacy: !this.state.checkedPrivacy,
                        })
                      }} 
                    />
                  </div>
                </div>
              </div>
            }
            <div className='buttonGroup' style={{marginTop: "30px", marginBottom: "202px"}}>
              <Button className='large-button btn bg-navy btn-primary' 
              onClick={this.revisePrivacyInfo} 
              disableRipple={true}>
                수정
              </Button>
            </div>
          </div> */}
        </div>
        
      </Fragment>
    }

    return (
      <Fragment>
        <div className='userInformation'>
          {contentDiv}
        </div>
        {
          this.state.openModal && 
          <Modal
            open={this.state.openModal}
            type={this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type || "SIMPLE"}
            positive={this.state.modalOptions && this.state.modalOptions.positive}
            negative={this.state.modalOptions && this.state.modalOptions.negative}
            title={this.state.modalOptions && this.state.modalOptions.title}
            negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
            positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions && this.state.modalOptions.content}
            color={this.state.modalOptions && this.state.modalOptions.color || "DARK"}
          />
        }
      </Fragment>
      
    )
  }
}