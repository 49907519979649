import * as THREE from '@teneleven/three';
import { HouseData, CoreData, BuildingTypeData, ConverterLayer, autoHouseData, autoCoreData, CompletenessType, BuildingTypeAlertType, makeHouseState } from "./DataTypes";
import { switchLineDashedState } from './FileParser';
import { areaProportion } from './resultLocationDataStruct';
import { loc_building_stories_avg } from './resultDataStruct';
import * as jsts from 'jsts';
import App from '../App';
import { Field } from './Field';
const uuid4 = require('uuid/v4');

export function MakeANewHouse(name: string, autoSetting = false) {
  let house: HouseData = {
    id: uuid4(),
    wall: null,
    lightWindow: null,
    normalWindow: null,
    exclusiveArea: 0,
    balconyOver150cm: 0,
    balconyLess150cm: 0,
    serviceArea: 0,
    name: name,
    outputPolygon: [],
    wall3DGroup: new THREE.Group(),
    level: [true],
    levelHeights: [2.8],
    piloti: 0,
    finalLines: [],
    hideList: false,
    selected: false,
    autoSetting: autoSetting,
    centerOfAllLine: new THREE.Vector3(0, 0, 0),
    complete: CompletenessType.warning,
    makeState: makeHouseState.Finish,
  }

  return house;
}

export function MakeANewCore(name: string, autoSetting = false) {
  let core: CoreData = {
    id: uuid4(),
    core: null,
    houses: [],
    name: name,
    area: 0,
    outputPolygon: [],
    wall3DGroup: new THREE.Group(),
    level: [true],
    levelHeights: [2.8],
    finalLines: [],
    hideList: false,
    autoSetting: autoSetting,
    centerOfAllLine: new THREE.Vector3(0, 0, 0),
    complete: CompletenessType.warning,
  }

  return core;
}

export function MakeANewBuilding(name: string, hideList = false) {
  let building: BuildingTypeData = {
    id: uuid4(),
    cores: [],
    houses: [],
    name: name,
    showList: false,
    totalExclusiveAreas: 0,
    totalServiceAreas: 0,
    totalCoreAreas: 0,
    buildingArea: 0,
    groundArea: 0,
    hideList: hideList,
  }
  return building;
}

export function deleteHouseFromHouseList(houses: HouseData[], house: HouseData, change = true) {
  let i = houses.indexOf(house)
  if (i > -1) {
    houses[i].wall3DGroup.children = [];
    house.selected = false;
    if (house.wall && change) { house.wall.polygons.forEach(p => p.innerMesh.visible = false); switchLayerState(house.wall); }
    if (house.lightWindow && change) { switchLayerState(house.lightWindow); }
    if (house.normalWindow && change) { switchLayerState(house.normalWindow); }
    houses.splice(i, 1);
  }
}

export function deleteCoreFromCoreList(cores: CoreData[], core: CoreData) {
  let i = cores.indexOf(core);
  if (i > -1) {
    cores[i].wall3DGroup.children = [];
    if (core.core) { core.core.polygons.forEach(p => p.innerMesh.visible = false); switchLayerState(core.core) }
    core.houses = [];
    cores.splice(i, 1);
  }
}

export function deleteFieldFromFieldList(fields: Field[], field: Field) {
  let i = fields.indexOf(field);
  if (i > -1) {
    if (field.getLayer()) { switchLayerState(field.getLayer()) }
    fields.splice(i, 1);
  }
}

export function deleteBuildingFromBuildingList(buildings: BuildingTypeData[], building: BuildingTypeData) {
  let i = buildings.indexOf(building);
  if (i > -1) {
    while (building.houses.length > 0) {
      deleteHouseFromHouseList(building.houses, building.houses[0]);
    }

    while (building.cores.length > 0) {
      deleteCoreFromCoreList(building.cores, building.cores[0]);
    }
    buildings.splice(i, 1);
  }
}

export function switchLayerState(l: ConverterLayer | null, isSinglePolygon: boolean = false) {
  if (!l)
    return;

  if (isSinglePolygon) {
    let p = l.polygons[0];
    for (let i = 1; i < l.polygons.length; i++) {
      p = p.area < l.polygons[i].area ? l.polygons[i] : p;
    }
    p.selected = !l.selected;
    App.stage !== "prod" && console.log(p, l);
    switchLineDashedState(p.lineMesh.material, l.selected);
  }
  else {
    l.polygons.forEach(p => {
      p.selected = !l.selected;
      switchLineDashedState(p.lineMesh.material, l.selected);
    });
  }

  l.selected = !l.selected;

  if (!l.selected) {
    l.polygons.forEach(p => {
      p.lineMesh.renderOrder = 0;
      p.innerMesh.visible = false;
    })
  }

  l.polygons.forEach(p => {
    p.lineMesh.material.color = new THREE.Color().set(l.color);
  })
}

export function getFieldsArea(fields: Field[]) {
  let totalArea = 0;

  fields.forEach(f => {
    if (f.getLayer())
      totalArea += f.getArea();
  })

  return Number(totalArea.toFixed(2));
}

export function getHouseArea(house: HouseData) {
  if (house.wall) {
    return getConverterLayerArea(house.wall);
  }
  else
    return 0;
}

export function getCoreArea(core: CoreData) {
  if (core.core) {
    return getConverterLayerArea(core.core);
  }
  return 0;
}

export function getFieldArea(field: Field) {
  let layer = field.getLayer();
  if (layer) {
    return getConverterLayerArea(layer);
  }
  else {
    return 0;
  }
}

function getConverterLayerArea(layer: ConverterLayer) {
  return Number(GetJSTSUnionPolygonFormLayer(layer).getArea().toFixed(2));
}

export function GetJSTSUnionPolygonFormLayer(layer: ConverterLayer) {
  let polygons = GetJSTSPolygonFormLayer(layer);
  let unionPolygon = GetEmptyJSTSGeometry();
  polygons.forEach((p: jsts.geom.Geometry) => {
    unionPolygon = unionPolygon.union(p);
  });
  return unionPolygon;
}

export function getTotalHousehold(buildings: BuildingTypeData[]) {
  let totalHousehold = 0;
  buildings.forEach(b => {
    b.cores.forEach(c => {
      c.houses.forEach(h => {
        totalHousehold += (h.level.length - h.piloti);
      })
    })
  });
  return totalHousehold;
}

export function calculateAreaProPortion(buildings: BuildingTypeData[]) {
  let areaProportions: areaProportion[] = [];

  buildings.forEach(b => {
    b.cores.forEach(c => {
      c.houses.forEach(h => {
        let ap = areaProportions.find(e => e.housingPlanTypeArea === h.exclusiveArea)
        if (!ap) {
          areaProportions.push({
            housingPlanTypeArea: h.exclusiveArea,
            housingPlanTypeNumberMin: (h.level.length - h.piloti),
            housingPlanTypeProportion: 0,
            numberOfBay: 0,
            templateName: '',
          })
        }
        else {
          ap.housingPlanTypeNumberMin += (h.level.length - h.piloti);
        }
      })
    })
  });

  let totalHousehold = getTotalHousehold(buildings);

  areaProportions.forEach(a => {
    a.housingPlanTypeProportion = Number((a.housingPlanTypeNumberMin / totalHousehold).toFixed(12));
  });

  return areaProportions;
}

function fixedNumber(n: number, length: number) {
  return Number(n.toFixed(length));
}


export function buildingStoriesAvg(buildings: BuildingTypeData[]) {
  let totalHouseLine = 0;
  let totalHouseHold = 0;
  let totalLevel = 0;
  let totalBaseArea = 0;
  let totalBuildingArea = 0;

  buildings.forEach(b => {
    let maxLevel = 0;
    let baseArea = 0;
    b.cores.forEach(c => {
      totalHouseLine += c.houses.length;
      c.houses.forEach(h => {
        totalHouseHold += (h.level.length - h.piloti);
        maxLevel = Math.max(maxLevel, h.level.length);
        baseArea += h.exclusiveArea;
        totalBuildingArea += h.exclusiveArea + h.serviceArea;
      })
      baseArea += c.area;
      totalBuildingArea += c.area;
    })
    totalLevel += maxLevel;
    totalBaseArea += baseArea / maxLevel;
  });

  let floorAvg_totalHouse = fixedNumber(totalHouseHold / totalHouseLine, 12);
  let floorAvg_Math = fixedNumber(totalLevel / buildings.length, 12);
  let floorAvg_area = fixedNumber(totalBuildingArea / totalBaseArea, 12);

  let buildingAvg: loc_building_stories_avg = {
    AREA: floorAvg_area,
    HOUSE: floorAvg_totalHouse,
    NUMERICAL: floorAvg_Math,
  }

  return buildingAvg;
}

export function mouseOverLayerTag(layers: ConverterLayer[], layer: ConverterLayer) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      if (l.name !== layer.name) {
        p.lineMesh.material.uniforms.opacity = { value: 0.2 };
        p.lineMesh.renderOrder = 0;
      }
      else {
        p.lineMesh.material.uniforms.opacity = { value: 0.8 };
        p.lineMesh.renderOrder = 1;
      }
    })
  })
}

export function mouseOutLayerTag(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: 1 };
      p.lineMesh.renderOrder = l.z_index;
    })
  })
}

export function mouseOverHouseTag(layers: ConverterLayer[], house: HouseData) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      if ((house.wall && l.name === house.wall.name) ||
        (house.lightWindow && l.name === house.lightWindow.name) ||
        (house.normalWindow && l.name === house.normalWindow.name)) {
        p.lineMesh.material.uniforms.opacity = { value: 0.8 };
      }
      else {
        p.lineMesh.material.uniforms.opacity = { value: 0.2 };
      }
    })
  })
}

export function allLayerSetToBaseColor(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.color = new THREE.Color().set(l.color);
    })
  })
}

export function changeAllLayerOpacity(layers: ConverterLayer[], opacity: number) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: opacity };
    })
  })
}

export function findHouseData(houses: autoHouseData[], name: string): autoHouseData | null {
  let house: autoHouseData | null = null;
  houses.forEach(h => {
    if (h.name === name) {
      house = h;
    }
  });
  return house;
}

export function findCoreData(cores: autoCoreData[], name: string): autoCoreData | null {
  let core: autoCoreData | null = null;
  cores.forEach(c => {
    if (c.name === name) {
      core = c;
    }
  })
  return core;
}

export function findCore(cores: CoreData[], name: string): CoreData | null {
  let core: CoreData | null = null;
  cores.forEach(c => {
    if (c.name === name) {
      core = c;
    }
  })

  return core;
}

export function findHouse(houses: HouseData[], name: string): HouseData | null {
  let house: HouseData | null = null;
  houses.forEach(h => {
    if (h.name === name) {
      house = h;
    }
  })

  return house;
}

export function findBuilding(buildings: BuildingTypeData[], name: string): BuildingTypeData | null {
  let building: BuildingTypeData | null = null;
  buildings.forEach(b => {
    building = b.name === name ? b : null;
  })

  return building;
}

export function setHouseLevel(house: HouseData, level: number) {
  let height = house.levelHeights[0];
  house.level = [];
  house.levelHeights = [];
  for (let i = 0; i < level; i++) {
    house.level.push(true);
    house.levelHeights.push(height);
  }
}

export function setHousePiloti(house: HouseData, value: number) {
  value = value > house.level.length ? house.level.length : value;

  for (let i = 0; i < house.level.length; i++) {
    house.level[i] = i < value ? false : true;
  }

  house.piloti = value;
}

export function GetPolygonCentroid(layer: ConverterLayer) {
  let center = new THREE.Vector3(0, 0, 0);

  if (layer.polygons.length <= 0) {
    return center;
  }

  let polygons = GetJSTSPolygonFormLayer(layer);
  polygons.forEach(p => {
    //@ts-ignore
    let c = p.buffer(-1).getCentroid();
    center.add(new THREE.Vector3(c.getX(), c.getY(), 0));
  })
  return center.divideScalar(polygons.length);
}

export function CheckHouseCompleteness(house: HouseData) {
  let completeness = CompletenessType.complete;

  if (!house.wall) { completeness = CompletenessType.error; }

  else if (house.wall.polygons.length <= 0) { completeness = CompletenessType.error; }

  else if (!house.lightWindow && !house.normalWindow) { completeness = CompletenessType.error }

  else if (house.exclusiveArea === 0) { completeness = CompletenessType.error; }

  if (house.makeState !== makeHouseState.Finish) { completeness = CompletenessType.warning; }

  let area = getHouseArea(house);
  let offset = Math.abs(area - house.serviceArea - house.exclusiveArea) / area;
  if (offset >= 0.5) {
    completeness = CompletenessType.error;
  }

  house.complete = completeness;
  if (completeness === CompletenessType.complete) {
    house.wall!.polygons.forEach(p => {
      p.innerMesh.visible = true;
      p.lineMesh.material.color = new THREE.Color(0xffffff);
    })
  }
  else {
    if (house.wall) {
      house.wall.polygons.forEach(p => {
        p.innerMesh.visible = false;
        //@ts-ignore
        p.lineMesh.material.color = new THREE.Color().set(house.wall.color);
      })
    }
  }
  return completeness;
}

export function GetHouseErrorInformation(house: HouseData) {
  let information: string[] = [];

  if (!house.wall) { information.push(`${house.name}세대의 벽 레이어를 추가해 주세요.`) }

  else if (house.wall.polygons.length <= 0) { information.push(`${house.name}세대의 벽 레이어에 폴리곤이 없습니다.`) }

  if (!house.lightWindow && !house.normalWindow) { information.push(`${house.name}세대의 창문 레이어를 추가해 주세요.`) }

  if (house.exclusiveArea === 0) { information.push(`${house.name}세대의 전용면적이 0입니다.`) }

  if (house.makeState !== makeHouseState.Finish) { information.push(`${house.name}새대에 생성 안 된 창문 라인이 있습니다.`) }

  let area = getHouseArea(house);
  let offset = Math.abs(area - house.serviceArea - house.exclusiveArea) / area;
  if (offset >= 0.5) {
    information.push(`${house.name}의 입력면적과 실제 폴리곤 면적이 서로 상이합니다. 단위를 확인 하신 후 다시 진행해 주세요.`)
  }

  return information;
}

export function GetLayerOverlapState(layer: ConverterLayer) {
  let polygons: jsts.geom.Geometry[] = [];
  polygons = polygons.concat(GetJSTSPolygonFormLayer(layer));
  let overlap = polygons.length > 1 ? CheckPolygonOverlap(polygons) : false;
  if (overlap) {
    return `${layer.name}에 폴리곤이 중복되어 있습니다. 확인 시 계속 진행 됩니다.`;
  }
  else
    return null;
}

export function CheckPolygonOverlap(polygons: jsts.geom.Geometry[]) {
  let overlap = false;
  for (let i = 0; i < polygons.length - 1; i++) {
    for (let j = i + 1; j < polygons.length; j++) {
      let interPoly = polygons[i].intersection(polygons[j]);
      if (interPoly.getArea() > 0.0001)
        overlap = true;
    }
  }
  return overlap;
}

export function ChekPolygonsApart(core: jsts.geom.Geometry[], houses: jsts.geom.Geometry[]) {
  let apart = false;
  //@ts-ignore
  let geoNumber = core[0].union(houses[0].buffer(0.2)).getNumGeometries();
  if (geoNumber > 1) apart = true;
  return apart;
}

export function CheckCoreAndHouseApart(cores: CoreData[]) {
  cores.forEach(c => {
    if (CheckCoreCompleteness(c) === CompletenessType.complete) {
      let corePoly = GetJSTSPolygonFormLayer(c.core!);
      c.houses.forEach(h => {
        if (h.complete === CompletenessType.complete) {
          let housePoly = GetJSTSPolygonFormLayer(h.wall!);
          console.log(h.name, ChekPolygonsApart(corePoly, housePoly));
        }
      })
    }
  });
}

export function CheckCoreCompleteness(core: CoreData) {
  let completeness = CompletenessType.complete;

  if (!core.core) { console.log('no core area'); completeness = CompletenessType.error; }

  else if (core.core.polygons.length <= 0) { console.log('core polygon is 0', core.core.polygons); completeness = CompletenessType.error; }

  else if (core.area === 0) { console.log('core area is 0'); completeness = CompletenessType.error; }

  else if (core.houses.length <= 0) { console.log('no conneted house'); completeness = CompletenessType.error; }

  let area = getCoreArea(core);
  let offset = Math.abs(area - core.area) / area;
  if (offset >= 0.5) {
    completeness = CompletenessType.error;
  }

  if (completeness === CompletenessType.complete) {
    core.core!.polygons.forEach(p => {
      p.innerMesh.visible = true;
      p.lineMesh.material.color = new THREE.Color(0xffffff);
    })
  }
  else if (completeness === CompletenessType.error) {
    if (core.core) {
      core.core.polygons.forEach(p => {
        p.innerMesh.visible = false;
        //@ts-ignore
        p.lineMesh.material.color = new THREE.Color().set(core.core.color);
      })
    }
  }

  core.complete = completeness;
  return completeness;
}

export function GetCoreErrorInformation(core: CoreData) {
  let information: string[] = [];
  if (!core.core) { information.push(`${core.name}의 코어를 선택해주세요.`); }

  else if (core.core.polygons.length <= 0) { information.push(`${core.name}의 코어 레이어에 폴리곤이 없습니다.`) }

  if (core.area === 0) { information.push(`${core.name}의 면젹을 입력해 주세요.`) }

  if (core.houses.length <= 0) { information.push(`${core.name}와 연결된 세대가 없습니다.`) }

  let area = getCoreArea(core);
  let offset = Math.abs(area - core.area) / area;
  if (offset >= 0.5) {
    information.push(`${core.name}의 입력면적과 실제 폴리곤 면적이 서로 상이합니다. 단위를 확인 하신 후 다시 진행해 주세요.`)
  }

  return information;
}

export function GetJSTSPolygonFormLayer(layer: ConverterLayer) {
  let polygons: jsts.geom.Geometry[] = [];
  layer.polygons.forEach(p => {
    if (p.shape) {
      let coords: jsts.geom.Coordinate[] = [];
      p.vertices.forEach(v => {
        coords.push(new jsts.geom.Coordinate(v.x, v.y));
      })
      let geoFac = new jsts.geom.GeometryFactory();
      let linearRing = geoFac.createLinearRing(coords);
      //@ts-ignore
      polygons.push(geoFac.createPolygon(linearRing, []).buffer(0));
    }
  })
  return polygons;
}

function GetEmptyJSTSGeometry() {
  let geoFac = new jsts.geom.GeometryFactory();
  let linearRing = geoFac.createLinearRing([]);
  //@ts-ignore
  return geoFac.createPolygon(linearRing, []).buffer(0);
}

export function CheckColsedPolygon(layer: ConverterLayer) {
  let shape = true;
  layer.polygons.forEach(p => {
    if (!p.shape)
      shape = false;
  })
  return shape;
}

export function setErrorColorForLayer(layer: ConverterLayer | null) {
  if (layer) {
    layer.polygons.forEach(p => {
      p.lineMesh.material.color = new THREE.Color(0xff0000);
    })
  }
}
