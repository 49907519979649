import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import BuilditTemplate from './BuilditTemplate';
import './css/ProjectPayment.scss';
import { Project, ProjectType } from './model/Project';
import queryString from 'query-string';
import AWSModule from './AWSModule';
import App from './App';
import ProjectSummary from './ProjectSummary';
import { Button, Checkbox } from '@material-ui/core';
import { default as _ } from 'lodash';
import ProjectTypeTag from './ProjectTypeTag';
import Modal, { ModalOptions } from './Modal';
import Tooltip from './Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { ProjectFormPropsBase } from './ProjectInput';
import ProjectFieldInput from './ProjectFieldInput';
import BuilditInput from './BuilditInput';
import ProjectSelectOptions from './SelectOptions';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';


export interface ProjectPaymentProps {
}
export interface ProjectPaymentState {
  userProjectId: number;
  project: Project;
  legalConsent: boolean;
  isPaid: boolean;
  payingPoint: number;
  discountPoint: number;
  loading: boolean;
  openModal: boolean;
  modalOptions?: ModalOptions;
}

export default class ProjectPayment extends SessionComponent<ProjectPaymentProps, ProjectPaymentState> {
  state: ProjectPaymentState = {
    userProjectId: 0,
    project: {},
    legalConsent: false,
    payingPoint: 0,
    discountPoint: 0,
    isPaid: false,
    loading: false,
    openModal: false,
  }
  
  componentWillMount = async () => {
    const qs = queryString.parse(location.search);
    const userProjectId = qs.pid;
    
    const r = await App.search({
      table: "platform-buildit-project",
      query: `stage:${App.stage} AND email.keyword:${App.session.email} AND user_project_id:${userProjectId} AND discarded: false AND deleted: false`,
    });
    
    const rr = r.data;
    if (rr.hits.hits && rr.hits.hits.length > 0) {
      const p = rr.hits.hits[0]._source as Project;

      if (p.status !== "CONFIG") { // 결제된것
        this.setState({ isPaid: true });
        // return;
      }

      const ddb = await new AWSModule("DDB").connect();
      const dr = await ddb.Ddb!.get({
        ConsistentRead: true,
        TableName: "platform-buildit-project",
        Key: {
          project_id: p.project_id,
          stage: App.stage
        }
      }).promise();
      if (dr.$response.error === null && dr.Item) {
        const project: Project = dr.Item;
        this.setState({ project: project }, () => {
          this.setPayingPoint();
        });
      } else {
        console.log(dr.$response.error);
      }
    }
  }

  setPayingPoint = (callback?: Function) => {
    let payingPoint = 1000;
    if (this.state.project.project_type === "AI") {
      payingPoint += Math.floor(Number(this.state.project.project_site_area! * 1));
    } else if (this.state.project.project_type === "DESIGNER" || this.state.project.project_type === "SITE_PLAN") {
      payingPoint += Math.floor(Number(this.state.project.project_site_area! * 0.5));
    }

    let disCountPoint = 0;
    if (this.state.project.discountable) {
      disCountPoint = Math.ceil(payingPoint * 0.9);
    }

    this.setState({payingPoint: payingPoint, discountPoint: disCountPoint,}, () => {
      if (callback) {
        callback();
      }
    });
  }
  render() {
    return (
      <BuilditTemplate footer={true} loading={this.state.loading}  {...this.props}>
        <div className="ProjectPayment">
          <div className="breadcrumb-wrap">
            <div className="content">
              <div className="breadcrumb">
              {
                `HOME > 프로젝트 생성 > ${this.state.isPaid && "결제" || "설정"} 완료`
              }
              </div>
            </div>
          </div>
          <div className="payment-wrapper">
            <div className="content">
              <div className="title">
                프로젝트 설정 완료
              </div>
              <div className="pay-info-wrapper">
                <div className="title">결제 정보</div>
                <div className="body">
                  <div className="pay-wrap">
                    {/* <div className="project-setting">
                    {
                      <Fragment>
                        <div className="project-type-wrap">
                          <span className="font font-emphasis">프로젝트 구분</span>
                          <Tooltip
                            className="m-l-sm m-r-md"
                            msg={
                              <Fragment>
                                AI : 건물 자동배치 + 환경분석 + 사업성분석 <br />
                                DESIGNER : 건물 직접배치 + 사업성분석
                              </Fragment>
                            }
                            id="ProjectFormBuilding-MyBuildingPlan"
                          >
                            <HelpIcon className="icon" />
                          </Tooltip>
                          <div className="project-type-btns">
                          {
                            this.state.project.project_type === "AI" &&
                            <Button disableRipple={true} className={`project-type m-r-md ${["AI"].includes(this.state.project.project_type!) && "select"}`}
                              disabled={true}
                            >
                              <div className="type-btn-wrap">
                                <div className="type">AI</div>
                                <div className="icon-wrap">
                                  { this.state.project.project_type! === "AI" && <CheckIcon className="icon" /> }
                                </div>
                              </div>
                            </Button>
                            ||
                            <Button disableRipple={true} className={`project-type ${["SITE_PLAN", "DESIGNER"].includes(this.state.project.project_type!) && "select"}`}
                              disabled={true}
                            >
                              <div className="type-btn-wrap">
                                <div className="type">DESIGNER</div>
                                <div className="icon-wrap"><CheckIcon className="icon" /></div>
                              </div>
                            </Button>
                          }
                          </div>
                        </div>
                      </Fragment>
                    }
                    </div> */}
                    <div className="pay-info">
                      <div className="info-wrap left">
                        <div className="key-value">
                          <div className="key">프로젝트 구분</div>
                          <div className="value">
                            <ProjectTypeTag type={this.state.project.project_type!} background="DARK" className="" />
                          </div>
                        </div>
                        <div className="key-value">
                          <div className="key">사용 가능 포인트</div>
                          <div className="value">
                            {Number(App.session.point + App.session.service_point).toLocaleString()}P
                          </div>
                        </div>
                        <div className="key-value">
                          <div className="key">결제 후 포인트</div>
                          <div className="value">
                            {Number(App.session.point + App.session.service_point - this.state.payingPoint + this.state.discountPoint).toLocaleString()}P
                          </div>
                        </div>
                      </div>
                      <div className="info-wrap-divider" />
                      <div className="info-wrap right">
                        <div className="key-value">
                          <div className="key">프로젝트 결제 포인트</div>
                          <div className="value large">
                            { Number(this.state.payingPoint).toLocaleString()}P
                          </div>
                        </div>
                        <div className="key-value">
                          <div className="key">할인 포인트</div>
                          <div className="value large">
                            {
                              this.state.discountPoint === 0 ?
                              this.state.discountPoint :
                              `-${Number(this.state.discountPoint).toLocaleString()}`
                            }P 
                          </div>
                        </div>
                        <div className="key-value total">
                          <div className="key">총 결제 포인트</div>
                          <div className="value large">
                            { Number(this.state.payingPoint - this.state.discountPoint).toLocaleString() }P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="pay-btns">
                  {
                    this.state.isPaid === false &&
                    <div className="agree-wrap font font-primary bg-navy font-14px m-l-a"
                      onClick={e => this.setState({ legalConsent: !this.state.legalConsent })}
                    >
                      <Checkbox 
                        checked={this.state.legalConsent}
                        className={`checkbox m-r-sm ${this.state.legalConsent && "checked" || ""}`} 
                      />
                      결제 정보를 확인하였으며, 취소 환불규정에 동의 합니다.
                    </div> ||
                    <div className="after-pay">
                      결제가 완료되었습니다!<br />
                      {
                        this.state.project.project_type === "AI" && "분석을 기다려 주세요." || "잠시후 확인해주세요."
                      }
                    </div>
                  }
                    <Button 
                      disabled={!this.state.legalConsent}
                      disableRipple={true} 
                      className={`bg-navy btn btn-large pay-btn ${this.state.isPaid && "btn-primary" || "btn-run"} ${!this.state.legalConsent && "disabled"}`}
                      onClick={async (e) => {
                        if (this.state.isPaid === false) {
                          this.runProject();
                        } else {
                          // @ts-ignore
                          this.props.history.push(`/project/list`);
                        }
                      }}
                      >
                      {
                        this.state.isPaid && "목록 바로가기" || "결제하고 실행하기"
                      }
                    </Button>
                    {
                      this.state.isPaid === false &&
                      <Button 
                        disableRipple={true} 
                        className="btn btn-secondary btn-large bg-navy back-btn"
                        onClick={e => {
                          if (this.state.project.user_project_id === undefined) {
                            alert('잠시 후 다시 시도해주세요');
                          } else {
                            // @ts-ignore
                            this.props.history.push(`/project/edit?pid=${this.state.project.user_project_id}`);
                          }
                        }}
                      >
                        설정으로 돌아가기
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProjectSummary project={this.state.project} showProjectName={true} showProjectExplanation={true} className="summary" />
        </div>
        {
          this.state.openModal &&
          <Modal
            open={this.state.openModal}
            type={this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type || "SIMPLE"}
            positive={this.state.modalOptions && this.state.modalOptions.positive}
            negative={this.state.modalOptions && this.state.modalOptions.negative}
            title={this.state.modalOptions && this.state.modalOptions.title}
            negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
            positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions && this.state.modalOptions.content}
            color={this.state.modalOptions && this.state.modalOptions.color || "DARK"}
          />
        }
      </BuilditTemplate>
    )
  }
  runProject = async () => {
    {
      if (this.state.payingPoint <= 0 || (App.session.point + App.session.service_point) < this.state.payingPoint) {
        this.setState({ 
          openModal: true,
          modalOptions: {
            color: "DARK",
            positive: () => { this.setState({ openModal: false })},
            negative: () => { this.setState({ openModal: false })},
            title: "결제 오류",
            content: <div>
              포인트가 부족합니다<br /><br />
                <Link target="_blank" style={{ textDecoration: "none", color: "#48DDF2" }} to={{
                  pathname: '/landing',
                  search: "?type=SERVICE",
                }}> 포인트 안내 링크 </Link>
              </div>
          }
        });
        return;
      }
      

      if (this.state.legalConsent) {
        this.setState({ loading: true }, async () => {
          const lambda = await new AWSModule("LAMBDA").connect();
          const p: Project = _.cloneDeep(this.state.project);
          const ddb = (await new AWSModule('DDB').connect()).Ddb!;
  
          // const rr = JSON.parse(r.Payload as string);
  
          //if (rr.success) 
          {
            
            const rp: Project = {};
            Object.keys(p).forEach(e => {
              if (p[e as keyof Project] !== "" && p[e as keyof Project] !== undefined) {
                // @ts-ignore
                rp[e as keyof Project] = p[e as keyof Project];
              }
            });
            // rp.project_type = this.state.projectType;

            if (rp.project_type === "AI") {
              if (rp.finished_ai === undefined || p.finished_ai!.length === 0) {
                const report_cnt = p.building_type === "아파트" ? 30 : 10;
                rp.finished_ai = new Array(report_cnt).fill(false);
                rp.reports_number = report_cnt;
                rp.run_at = new Date().toISOString();
              }
              const r = await lambda.Lambda!.invoke({
                FunctionName: "platform-buildit-fn-UpdateDynamodb-r1-v20190816",
                Payload: JSON.stringify({
                  TableName: 'platform-buildit-project',
                   Item: rp,
                })
              }).promise();
    
              const rr = JSON.parse(r.Payload as string);
    
              if (rr.success) {
                const r = await lambda.Lambda!.invoke({
                  FunctionName: "engine-buildit-run-project-latest",
                  Payload: JSON.stringify({
                    stage: App.stage,
                    project_id: this.state.project.project_id,
                    user_id: App.session.id,
                    type: "point",
                    amount: (this.state.payingPoint - this.state.discountPoint),
                  })
                }).promise();

                await ddb.update({
                  TableName: 'platform-buildit-project',
                  Key: {
                    stage: App.stage,
                    project_id: this.state.project.project_id,
                  },
                  UpdateExpression: 'SET #run_project_request_id = :run_project_request_id',
                  ExpressionAttributeNames: {
                    '#run_project_request_id': 'run_project_request_id',
                  },
                  ExpressionAttributeValues: {
                    ':run_project_request_id': r.$response.requestId,
                  },
                }).promise();

                const rr = JSON.parse(r.Payload as string);
                if (rr.success) {
                  this.setState({ isPaid: true });
                } else {
                  // temp err
                  alert(r.$response.error);
                }
              } else {
                this.setState({ 
                  openModal: true,
                  modalOptions: {
                    color: "DARK",
                    positive: () => { this.setState({ openModal: false })},
                    negative: () => { this.setState({ openModal: false })},
                    title: "결제 오류",
                    content: <div>{rr.reason}</div>
                  }
                });
              }
            } else if (rp.project_type === "DESIGNER" || rp.project_type === "SITE_PLAN") {
              const r = await lambda.Lambda!.invoke({
                FunctionName: "platform-buildit-fn-UpdateDynamodb-r1-v20190816",
                Payload: JSON.stringify({
                  TableName: 'platform-buildit-project',
                   Item: {
                     ...rp,
                     run_at: new Date().toISOString()
                    },
                })
              }).promise();
    
              const rr = JSON.parse(r.Payload as string);

              if (rr.success) {
                const r = await lambda.Lambda!.invoke({
                  FunctionName: "engine-buildit-run-project-latest",
                  Payload: JSON.stringify({
                    stage: App.stage,
                    project_id: this.state.project.project_id,
                    user_id: App.session.id,
                    type: "point",
                    amount: (this.state.payingPoint - this.state.discountPoint),
                  })
                }).promise();
  
                await ddb.update({
                  TableName: 'platform-buildit-project',
                  Key: {
                    stage: App.stage,
                    project_id: this.state.project.project_id,
                  },
                  UpdateExpression: 'SET #run_project_request_id = :run_project_request_id',
                  ExpressionAttributeNames: {
                    '#run_project_request_id': 'run_project_request_id',
                  },
                  ExpressionAttributeValues: {
                    ':run_project_request_id': r.$response.requestId,
                  },
                }).promise();
  
                const rr = JSON.parse(r.Payload as string);
                if (rr.success) {
                  this.setState({ isPaid: true });
                } else {
                  // temp err
                  alert(r.$response.error);
                }
              } else {
                console.log(rr.reason);
                this.setState({ 
                  openModal: true,
                  modalOptions: {
                    color: "DARK",
                    positive: () => { this.setState({ openModal: false })},
                    negative: () => { this.setState({ openModal: false })},
                    title: "결제 오류",
                    content: <div>{rr.reason}</div>
                  }
                });
              }
            }
          } 
          // else {
          //   console.log(rr.reason);
          //   this.setState({ 
          //     openModal: true,
          //     modalOptions: {
          //       color: "DARK",
          //       positive: () => { this.setState({ openModal: false })},
          //       negative: () => { this.setState({ openModal: false })},
          //       title: "결제 오류",
          //       content: <div>{rr.reason}</div>
          //     }
          //   });
          // }
          await App.updateSession();
          this.setState({ loading: false });
        })
      } else {
        alert('동의 체크');
      }
    }
  }
  updateProject = async (update: Project, callback?: Function) => {
    const newProject = new Project({
      ..._.cloneDeep(this.state.project),
      ..._.cloneDeep(update),
      modified_at: new Date().toISOString()
    });

    this.setState({
      project: newProject
    }, async () => {
      await this.saveProject(this.state.project);
        if (callback) {
          callback();
        }
    });
  }

  saveProject = async (project: Project) => {
    const saveProject: Project = {};
    Object.keys(project).forEach(e => {
      if (project[e as keyof Project] !== "" && project[e as keyof Project] !== undefined) {
        // @ts-ignore
        saveProject[e as keyof Project] = project[e as keyof Project];
      }
    });

    const lambda = await new AWSModule("LAMBDA").connect();
    const r = await lambda.Lambda!.invoke({
      FunctionName: "platform-buildit-fn-UpdateDynamodb-r1-v20190816",
      Payload: JSON.stringify([{
        TableName: "platform-buildit-project",
        Item: {
          stage: App.stage,
          ...saveProject,
        }
      }])
    }).promise();
  }
}