import React, { Component } from 'react';
import { CoreData, ConverterLayer, HouseData, InputBoxType, autoCoreData, PartOfHouse, CompletenessType } from './DataTypes';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import '../css/CADConverter/HouseAndCore.scss';
import { Button } from '@material-ui/core';
import { switchLayerState, deleteHouseFromHouseList, GetPolygonCentroid, CheckCoreCompleteness, CheckColsedPolygon, setErrorColorForLayer } from './CoreAndHouseController';
import * as MeshMaker from './MeshMaker';
import { ConverterInputBox } from './ConverterInputBox';

interface coreState {
  name: string,
  id: string,
  core: ConverterLayer | null,
  houses: HouseData[],
  coreArea: number,
  selectCore: boolean,
  hideList: boolean,
  partOfHouse: PartOfHouse,
}

interface coreProp {
  coredata: CoreData,
  selectLayer: ConverterLayer | null,
  selectHouse: HouseData | null,
  showLayerList: (id: string) => void,
  showHouseList: (id: string) => void,
  removeCore: (core: CoreData) => void,
  valueChanged: () => void,
  showModel: (information: string) => void,
  autoSetData?: autoCoreData | null,
  settingID: string,
  editable: boolean,
}

export class Core extends Component<coreProp, coreState> {
  state: coreState = {
    name: this.props.coredata.name,
    core: this.props.coredata.core,
    houses: this.props.coredata.houses,
    coreArea: this.props.coredata.area,
    id: this.props.coredata.id,
    hideList: this.props.coredata.hideList,
    selectCore: (this.props.coredata.core && true) || false,
    partOfHouse: PartOfHouse.core,
  }

  componentDidMount = async () => {
    if (this.state.selectCore) {
      this.makeCorePolygon();
    }
  }

  componentDidUpdate = (previousProps: Readonly<coreProp>, previousState: Readonly<coreState>) => {
    if (this.props.coredata.id !== this.props.settingID)
      return;

    if (previousProps.selectLayer !== this.props.selectLayer) {
      this.setCoreLayer(this.props.selectLayer);
      CheckCoreCompleteness(this.props.coredata);
    }

    if (previousProps.selectHouse !== this.props.selectHouse && this.props.selectHouse) {
      if (this.props.coredata.houses.indexOf(this.props.selectHouse) > -1) {
        this.props.selectHouse.selected = false;
        deleteHouseFromHouseList(this.props.coredata.houses, this.props.selectHouse, false);
      }
      else {
        this.props.selectHouse.selected = true;
        this.props.coredata.houses.push(this.props.selectHouse!);
        this.props.coredata.houses.sort((a, b) => Number(a.name.match(/\d+/g)) - Number(b.name.match(/\d+/g)));
      }
      this.props.valueChanged();
      CheckCoreCompleteness(this.props.coredata);
      this.setState({ houses: this.props.coredata.houses });
    }
  }

  setCoreLayer = (layer: ConverterLayer | null) => {
    if (!layer) return;

    let exLayer = this.props.coredata.core;

    if (this.props.coredata.core === this.props.selectLayer) {
      this.props.coredata.core = null;
      this.setState({ core: null, selectCore: false });
      if (exLayer) switchLayerState(exLayer);
    }
    else if (this.props.selectLayer!.selected) {
      this.props.showModel('이미 선택되어 있는 레이어입니다.');
      return;
    }
    else {
      if (CheckColsedPolygon(layer)) {
        this.props.coredata.core = layer;
        switchLayerState(layer);
        this.setState({ core: layer, selectCore: true });

        if (exLayer) switchLayerState(exLayer);
        this.makeCorePolygon();
      }
      else {
        setErrorColorForLayer(layer);
        this.props.showModel('닫친 폴리곤이 아닙니다.');
      }
    }
  }

  makeCorePolygon = () => {
    MeshMaker.remakeCorePolygons(this.props.coredata);
    if (this.props.coredata.core) {
      this.props.coredata.centerOfAllLine = GetPolygonCentroid(this.props.coredata.core);
    }

    CheckCoreCompleteness(this.props.coredata);
  }

  showLayerList = () => {
    this.props.showLayerList(this.props.coredata.id);
  }

  valueChanged = (value: number) => {
    this.props.coredata.area = value;
    CheckCoreCompleteness(this.props.coredata);
    this.setState({ coreArea: value });
    this.props.valueChanged();
  }

  toogleList = () => {
    this.props.coredata.hideList = !this.state.hideList;
    this.setState({ hideList: !this.state.hideList })
  }

  render = () => {
    return (
      <div className='core'>
        <div className='nameDiv' onClick={this.toogleList}>{this.state.name}{(this.state.hideList && <UpIcon />) || <DownIcon />}
          <span className='notice' hidden={this.props.coredata.complete === CompletenessType.complete}>레이어를 선택 후 면적 정보를 입력해주세요</span>
          <CloseIcon className='closeIcon' onClick={() => { if (this.props.editable) this.props.removeCore(this.props.coredata) }} />
        </div>
        <div hidden={this.state.hideList}>
          <div className='buttonDiv'>
            <Button className={`${(this.props.settingID === this.props.coredata.id && this.state.partOfHouse === PartOfHouse.core) && 'selected' || 'unselected'}`} onClick={e => { this.props.showLayerList(this.props.coredata.id); this.setState({ partOfHouse: PartOfHouse.core }) }} disabled={!this.props.editable}>코어 선택</Button>
            <CheckIcon className={`CheckIcon ${this.state.core && 'checked'}`} />
            {this.state.core && this.state.core.name}
          </div>
          <div className='buttonDiv alignTop'>
            <Button className={`${(this.props.settingID === this.props.coredata.id && this.state.partOfHouse === PartOfHouse.house) && 'selected' || 'unselected'}`} onClick={e => { this.props.showHouseList(this.props.coredata.id); this.setState({ partOfHouse: PartOfHouse.house }) }} disabled={!this.props.editable}>연결된 세대 선택</Button>
            {(this.state.houses.length > 0 && <div className='houseList'>
              {this.state.houses.map(h =>
                <div key={h.id} style={{ height: '40px', display: 'flex', alignItems: 'center' }}>
                  <CheckIcon className={`CheckIcon checked`} />
                  {h.name}
                </div>
              )}
            </div>) ||
              <div style={{ height: '40px', display: 'flex', alignItems: 'center' }}><CheckIcon className={`CheckIcon`} /></div>
            }
          </div>
          <ConverterInputBox name='코어 면적' value={this.state.coreArea} areaType={InputBoxType.coreArea} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />

        </div>
        <div className='Line' />
      </div>
    )
  }
}