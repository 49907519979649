import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import './css/Landing.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';
// @ts-ignore
import { ScrollTo, ScrollArea } from 'react-scroll-to';
// @ts-ignore
import Typing from 'react-typing-animation';
import CheckIcon from '@material-ui/icons/Check';
import AnnounceIcon from '@material-ui/icons/AnnouncementOutlined';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Animated} from "react-animated-css";
import jquery from 'jquery';
// @ts-ignore
import Swiper from 'react-id-swiper';
import DownloadButton from './DownloadButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Button, AppBar, Toolbar, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { default as _ } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import App from './App';
import Typed from 'react-typed';
import ScrollTopIcon from '@material-ui/icons/KeyboardCapslock';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import queryString from 'query-string';
const $ = jquery;


type LandingMenu = "INFO" | "SAMPLE" | "SERVICE";

export interface LandingProps {
  
}
export interface LandingState {
  scrollTop: number,
  mainContainerHeight: number,
  showSubTitle: boolean,
  showShortIntroText: boolean,
  selectMenu: LandingMenu;
  dim: boolean;
  showMainTitle1: boolean;
  showMainTitle2: boolean;
  showCursor1: boolean;
  showCursor2: boolean;
  startBtnMsg: string;
}
export default class Landing extends Component<LandingProps, LandingState> {
  state: LandingState = {
    mainContainerHeight: $(window).height()!,
    showSubTitle: false,
    scrollTop: 0,
    showShortIntroText: false,
    selectMenu: "INFO",
    dim: false,
    showMainTitle1: true,
    showMainTitle2: false,
    showCursor1: true,
    showCursor2: true,
    startBtnMsg: "BUILDIT START"
  }
  onScroll = (e: any) => {
    const windowHeight = $(window).height()!;    
    // if ($('.short-introduction').offset()!.top)
    let curScroll = $('.buildit-template').scrollTop()!;
    let sampleOffset = curScroll! + $('.buildit-sample').offset()!.top;
    let priceOffset = curScroll! + $('.price').offset()!.top;

    if (curScroll >= 0 && curScroll < sampleOffset) {
      if (this.state.selectMenu !== "INFO") {
        this.setState({ selectMenu: "INFO" })
      }
    } else if (curScroll >= priceOffset) {
      if (this.state.selectMenu !== "SERVICE") {
        this.setState({ selectMenu: "SERVICE" })
      }
    } else {
      if (this.state.selectMenu !== "SAMPLE") {
        this.setState({ selectMenu: "SAMPLE" })
      }
    }
    
  }
  componentDidMount = () => {
    // window.addEventListener('scroll', this.onScroll);
    $('.Landing').on('scroll', this.onScroll);
    this.onScroll = _.debounce(this.onScroll, 100);
    window.addEventListener('resize', () => {
      this.setState({ mainContainerHeight: $(window).height()! })
    })
    
    // Hook doAnimations on scroll, and trigger a scroll
    $('.buildit-template').on('scroll', this.doAnimations);
    $('.buildit-template').trigger('scroll');


    // 포인트 안내로 바로 가기
    
    const parsed = queryString.parse(location.search);
    if (parsed && parsed.type) {
      this.clickMenu(parsed.type);
    }
  
  }

  doAnimations = () => {
    var bottom = $('.buildit-template').scrollTop()! + $('.buildit-template').height()!,
        $animatables = $('.animatable');
    
    if ($animatables.length == 0) {
      // $(window).off('scroll', doAnimations);
    }
    
    // Check all animatables and animate them if necessary
    $animatables.each(function(i) {
       var $animatable = $(this);
      if ($('.buildit-template').height()! - $animatable.offset()!.top > 200) {
        $animatable.removeClass('animatable').addClass('animated');
      }
    });


    if (this.state.selectMenu === "INFO") {
      if ($(window).width()! > 1200) {
        if ($('.buildit-reason1 > .wrapper > .content').offset()!.top > 100) {
          
          $('.buildit-reason1 > .wrapper > .title').css('position', 'relative');
          $('.buildit-reason1 > .wrapper > .title').css('margin-top', '0px');
          $('.buildit-reason1 > .wrapper > .title').css('bottom', 'auto');
          $('.buildit-reason1 > .wrapper > .title').css('top', 'auto');
        } else if ($('.buildit-reason1 > .wrapper > .content').offset()!.top < 100 && $('.buildit-reason1 > .wrapper > .content').offset()!.top + $('.buildit-reason1 > .wrapper > .content').height()! > 275) {
          
          $('.buildit-reason1 > .wrapper > .title').css('position', 'fixed');
          $('.buildit-reason1 > .wrapper > .title').css('margin-top', '0px');
          $('.buildit-reason1 > .wrapper > .title').css('bottom', 'auto');
          $('.buildit-reason1 > .wrapper > .title').css('top', '100px');
        } else {
          
          $('.buildit-reason1 > .wrapper > .title').css('top', 'auto');
          $('.buildit-reason1 > .wrapper > .title').css('bottom', '0px');
          $('.buildit-reason1 > .wrapper > .title').css('position', 'relative');
          $('.buildit-reason1 > .wrapper > .title').css('margin-top', 'auto');
        }
      }
    } else {
      $('.buildit-reason1 > .wrapper > .title').css('position', 'relative');
          $('.buildit-reason1 > .wrapper > .title').css('margin-top', '0px');
          $('.buildit-reason1 > .wrapper > .title').css('bottom', 'auto');
          $('.buildit-reason1 > .wrapper > .title').css('top', 'auto');
    }    
      // $('.buildit-reason1 > .wrapper > .title').css('position', 'fixed');
      // $('.buildit-reason1 > .wrapper > .title').css('top', '100px');
  };

  componentDidUpdate = () => {
  }
  clickMenu = (menu: any) => {
    // console.log('sample', $('.buildit-sample').offset()!.top)
    // console.log('price', $('.price').offset()!.top)
    // console.log('scroll', $('.buildit-template').scrollTop())
    this.setState({ selectMenu: menu }, () => {
      switch(menu) {
        case "INFO":
          $('.buildit-template').animate({ scrollTop: 0 });
          break;
        case "SAMPLE":
          $('.buildit-template').animate({ scrollTop: $('.buildit-sample').offset()!.top + $('.buildit-template').scrollTop()! });
          break;
        case "SERVICE":
          $('.buildit-template').animate({ scrollTop: $('.price').offset()!.top + $('.buildit-template').scrollTop()! });
          break;
        default:
          $('.buildit-template').animate({ scrollTop: 0 });
          break;
      }
    })
  }
  render() {
    return (
      <div className="Landing buildit-template" style={{ overflow: this.state.dim && "hidden" || "scroll" }}>
        <Button disableRipple={true} className="bg-navy btn btn-primary scroll-top-btn" onClick={() => $('.buildit-template').animate({ scrollTop: 0 })}>
          <ScrollTopIcon className="icon" />
        </Button>
        <ExpansionPanel className="mobile-appbar" classes={{root: "exp-root", expanded: "expanded"}}
          expanded={this.state.dim} 
          onChange={(e: any, expanded: boolean) => {
            this.setState({ dim: expanded })
          }
        }>
          <ExpansionPanelSummary disableRipple={true} expandIcon={<ExpandMoreIcon className="icon" style={{color: "#333"}} />} classes={{root: "summary-root", expanded: "expanded", content: "content" }}>
            <img className="logo" src="/img/landing_buildit_logo.svg" style={{height: "25px"}} />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="detail">
            <div className="menu" onClick={() => this.setState({ dim: false }, () => this.clickMenu("INFO"))}>소개</div>
            <div className="menu" onClick={() => this.setState({ dim: false }, () => this.clickMenu("SAMPLE"))}>샘플 프로젝트</div>
            <div className="menu" onClick={() => this.setState({ dim: false }, () => this.clickMenu("SERVICE"))}>서비스 플랜 및 가격</div>
            <div className="info">
              <div>BUILDIT은 PC 최적화로 개발되었습니다.</div>
              <div>BUILDIT 이용은 PC에서 확인해 주세요.</div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <AppBar
          position="absolute"
          className="app-bar"
        >
          <img className="logo" src="/img/landing_buildit_logo.svg" style={{height: "25px",cursor: "pointer" }}
            onClick={() => {
              $('.buildit-template').scrollTop(0);
            }}
          />
          <Toolbar className="toolbar">
            <div className="menus">
              <div onClick={() => this.clickMenu('INFO')} className={`bg-navy font font-primary items ${this.state.selectMenu === "INFO" && "selected"}`}>소개</div>
              <div onClick={() => this.clickMenu('SAMPLE')} className={`bg-navy font font-primary items ${this.state.selectMenu === "SAMPLE" && "selected"}`}>샘플 프로젝트</div>
              <div onClick={() => this.clickMenu('SERVICE')} className={`bg-navy font font-primary items ${this.state.selectMenu === "SERVICE" && "selected"}`}>서비스 플랜 및 가격</div>
              <Link to="/login" className="text-deco-none">
                <div className="bg-navy font font-special display-flex">
                  <Button className="bg-navy btn btn-run buildit-start-btn"
                   onMouseEnter={() => this.setState({ startBtnMsg: "LOGIN" })}
                   onMouseLeave={() => this.setState({ startBtnMsg: "BUILDIT START" })}
                   style={{width: "136px", fontSize: "12px"}}
                  >
                    { this.state.startBtnMsg }
                    <ArrowForwardIcon className="icon m-l-a" />
                  </Button>
                </div>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <div className="landing-wrapper">
          { this.state.dim && <div className="dim" onClick={() => this.setState({ dim: false })} />}
          <div className="l-container main" >
            <Carousel 
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              stopOnHover={true}
              interval={5000}
            >
              <div className="content main">
                <div className="title-box">
                  {
                    this.state.showMainTitle1 && 
                    <Typed 
                      className={`main-title-1 ${this.state.showCursor1 ? "" : "blink-off"}`}
                      strings={['스마트한']}
                      typeSpeed={150}
                      autoInsertCss={true}
                      onComplete={() => this.setState({ showCursor1: false, showMainTitle2: true })}
                    />
                  }
                  <br />
                  {
                    this.state.showMainTitle2 && 
                    <Typed 
                      className={`main-title-2 ${this.state.showCursor2 ? "" : "blink-off"}`}
                      strings={['건축설계의 시작']}
                      typeSpeed={150}
                      autoInsertCss={true}
                      onComplete={() => this.setState({ showCursor2: false, showSubTitle: true })}
                    />
                  }
                  {
                    <Animated animationIn="fadeInUp" animationOut="fadeIn" isVisible={this.state.showSubTitle} className={`${!this.state.showSubTitle && 'visibility-hidden'}`}>
                      <p className="sub-title">지금까지 없던 새로운 건축 설계<br />솔루션, BUILDIT</p>
                    </Animated>
                  }
                  {
                    <Animated animationIn="fadeInUp" animationOut="fadeIn" isVisible={this.state.showSubTitle} className={`${!this.state.showSubTitle && 'visibility-hidden'}`}>
                      <div className="btn-groups">
                        <DownloadButton className="bg-navy btn btn-primary" 
                          onClick={(callback?: Function) => {
                            callback && callback();
                            // @ts-ignore
                            window.open("https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Manual/BUILDIT_MANUAL.pdf");
                          }}
                        >
                          USER GUIDE</DownloadButton>
                        <Link to="/login" className="text-deco-none">
                          <Button className="bg-navy btn btn-run buildit-start-btn">
                            BUILDIT START
                            <ArrowForwardIcon className="icon m-l-a" />
                          </Button>
                        </Link>
                      </div>
                    </Animated>
                  }
                </div>
                <Animated animationIn="fadeInUp" animationOut="fadeIn" isVisible={this.state.showSubTitle} className={`launching-img ${!this.state.showSubTitle && 'visibility-hidden'}`}>
                  <img src="/img/designer_launching_img.png" />
                </Animated>
              </ div>
              <div className="content config">
                <div className="wrapper">
                  <div className="info">
                    <div className="title">
                      설정은 1분 안에 끝.
                    </div>
                    <div className="content">
                      <div>대지영역 설정만으로 건축에 필요한</div>
                      <div>복잡한 건축법을 자동으로 적용하고</div>
                      <div>최대의 용적률과 일조량을 확보할 수 있는</div>
                      <div>다양한 배치안을 제공합니다.</div>
                    </div>
                  </div>
                  <div className="display">
                    <div className="tablet-wrapper">
                      <div className="tablet-body">
                        <video src="https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/videos/01_%EC%84%A4%EC%A0%9510%EC%B4%88_2020_07_20.mp4"  loop muted autoPlay />
                      </div>
                    </div>
                  </div>
                </ div>
                </div>
                <div className="content result">
                  <div className="wrapper">
                    <div className="info">
                      <div className="AI-title">
                        BUILDIT AI 이용 시 (2020.12 출시예정)
                      </div>
                      <div className="title">
                        결과는 30분 만에 도출.
                      </div>
                      <div className="content">
                        <div>2,000 세대 대규모 아파트 단지도 30분 안에</div>
                        <div>CAD, 3차원 모델 뷰어, 환경 분석 자료,</div>
                        <div>건축 개요 등 다양한 결과물을 제공합니다.</div>
                      </div>
                    </div>
                    <div className="display">
                      <div className="tablet-wrapper">
                        <div className="tablet-body">
                          <video src="https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/videos/02_%EA%B2%B0%EA%B3%BC30%EB%B6%84_2020_07_20.mp4"  loop muted autoPlay />
                        </div>
                      </div>
                    </div>
                  </ div>
                </div>
                <div className="content designer">
                  <div className="wrapper">
                    <div className="info">
                      <div className="title">
                        3D 편집도 손쉽게.
                      </div>
                      <div className="content">
                        <div>3차원 환경으로 주변 지형, 건물과 함께</div>
                        <div>결과 확인 및 층별 조망까지 볼 수 있습니다.</div>
                        <div>건물의 회전, 이동, 층수 조절 등으로 배치안을</div>
                        <div>편집하여 더 멋진 결과물을 만들어 보세요</div>
                      </div>
                    </div>
                    <div className="display">
                      <div className="tablet-wrapper">
                        <div className="tablet-body">
                          <video src="https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/videos/03_%EB%94%94%EC%9E%90%EC%9D%B4%EB%84%88_2020_07_10.mp4"  loop muted autoPlay />
                        </div>
                      </div>
                    </div>
                  </ div>
                </div>
            </Carousel>
          </div>
          {/* 내용물 div 수정 */}
          <div className="l-container short-introduction">
            <div className={`wrapper`} >
              <div className="animatable fadeInUp">
                <div className="main-title" >
                  <div>BUILDIT은 여러분의 효율적인</div>
                  <div>업무 환경을 만들어 드립니다.</div>
                </div>
                <br/>
                <div className="sub-title" >
                  <div>BUILDIT 시스템으로 다양하게 초기 검토를 한 후,</div>
                  <div>결과를 빠르게 편집하여 설계를 이어나갈 수 있습니다.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-container buildit-system">
            <div className="wrapper">
              <div className="title  animatable fadeInUp">
                BUILDIT<br />
                시스템
              </div>
              <div className="cards">
                <div className="AI card  animatable fadeInUp">
                  <img src="/img/landing_img_system1.png" />
                  <div className="title">
                    <div>BUILDIT AI</div>
                    <div className="sub-title">(2020.12 출시예정)</div>
                  </div>
                  <div className="info">
                    <div>사람이 하던 반복적인 작업을 대신해</div>
                    <div>주는 도구로써 입력한 조건에 맞는</div>
                    <div>최적의 건축설계 결과물을 제공합니다.</div>
                  </div>
                  {/* <Button className="bg-navy btn btn-run buildit-start-btn"
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                  >
                    BUILDIT START
                    <ArrowForwardIcon className="icon m-l-a" />
                  </Button> */}
                </div>
                <div className="DESIGNER card  animatable fadeInUp">
                  <img src="/img/landing_img_system2.png" />
                  <div className="title">BUILDIT DESIGNER</div>
                  <div className="info">
                    <div>사용자가 빠르고 정확하게 건축설계를</div>
                    <div>수행할 수 잇는 도구입니다.</div>
                  </div>
                  {/* <DownloadButton className="bg-navy btn btn-primary" 
                    onClick={(callback?: Function) => {
                      callback && callback();
                      // @ts-ignore
                      window.location.href = "https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Manual/BUILDIT_MANUAL.pdf";
                    }}
                  >
                    BUILDIT DESIGNER</DownloadButton> */}
                </div>                
              </div>
            </div>
          </div>
          <div className="l-container buildit-reason1">
            <div className="wrapper">
              <div className="title">
                BUILDIT을<br />
                선택하는 이유
              </div>
              <div className="content  animatable fadeInUp">
                <div className="info-box">
                  <div className="title">빠른 건축설계 초기 검토</div>
                  <div className="info">
                  보통의 건축설계는 초기단계에서 건축의 형태를 잡기위해 소모적인 과정을 수반합니다. 빌드잇은 이 소모적인 과정을 혁신적으로 줄여주고 클릭 몇 번과 대략 30분의 시간이 지나면 건축 설계 결과물을 받아볼 수 있습니다.
                  </div>
                </div>
                <div className="info-box">
                  <div className="title">
                    AI 기술을 활용
                    <div className="sub-title">BUILDIT AI(2020.12 출시 예정)</div>
                  </div>
                  <div className="info">
                  AI기술을 적용하여 기존 건축 설계 방식에서 확인 할 수 없었던 초기 건축 설계 결과물을 도출합니다. 빌드잇은 최적의 용적률과 세대수를 확보하는 다양한 결과를 제공하여 사업성에 도움을 줍니다.
                  </div>
                </div>
                <div className="info-box">
                  <div className="title">초기부터 3차원으로 수행</div>
                  <div className="info">
                  빌드잇은 대한민국 전국의 지형과 기존 건물 정보를 가지고 있기에 3차원으로 결과물을 제공합니다. 초기 건축설계 부터 내 주변에 산과 높은 건물을 피해서 건축 설계를 수행할 수 있습니다. 
                  </div>
                </div>
                <div className="info-box">
                  <div className="title">친환경 건축설계</div>
                  <div className="info">
                  친환경 분석은 설계 초기 단계 적용 될수록 그 효과를 극대화 할 수 있습니다. 건축 초기 단계에 사용되는 빌드잇은 일조 시간, 조망 분석 결과를 제공하여 겨울에 해가 잘 드는 집, 조망이 좋은 집을 설계할 수 있도록 도와드립니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="l-container buildit-reason2">
            <div className="wrapper">
              <div className="title animatable fadeInUp">
                BUILDIT 서비스 사용을<br />
                고민하지 않으셔도 됩니다.
              </div>
              <div className="content animatable fadeInUp">
                <div className="img-wrap">
                  <video src="https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/videos/04_%EA%B2%B0%EA%B3%BC%EC%9E%90%EC%84%B8%ED%9E%88_2020_07_20.mp4"  loop muted autoPlay />
                </div>
                <div className="info-wrap">
                  <div className="info">
                    <CheckIcon className="icon" />
                    건축 설계 분야의 초급자도 빠르게 초기 건축 설계 계획안을 추천 받을 수 있습니다.
                  </div>
                  <div className="info">
                    <CheckIcon className="icon" />
                    기존 건축 설계안을 빠르게 모델링 할 수 있으며, 주변 지형과 건물의 볼륨을 확인하면서 설계를 도출할 수 있습니다.
                  </div>
                  <div className="info">
                    <CheckIcon className="icon" />
                    일조, 일사, 조망 시뮬레이션 결과를 받아볼 수 있고 이를 통해 좀 더 지속 가능한 건축 설계 계획안을 작성 할 수 있습니다.
                  </div>
                  <div className="info">
                    <CheckIcon className="icon" />
                    건축 설계 과정에서 필수적으로 반복되는 의사 결정 과정의 시간을 혁신적으로 줄일 수 있습니다.
                  </div>
                  <div className="info">
                    <CheckIcon className="icon" />
                    기존 건축설계안을 개선하여 더 높은 효율의 계획안 도출을 검토할 수 있습니다.
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="l-container buildit-sample ">
            <div className="wrapper animatable fadeInUp">
              <div className="main-title">
                BUILDIT 결과를<br />
                경험해 보세요.
              </div>
              <div className="sample">
                <div className="title">
                  <div className="img-category">
                    BUILDIT DESIGNER
                  </div>
                  <div className="img-main-title">
                    BUILDIT DESIGNER 동작 예시
                  </div>
                  <div className="img-sub-title">
                    BUILDIT DESIGNER를 통해서 빠르게 초기 건축배치안을<br/>
                    3차원으로 도출할 수 있습니다.
                  </div>
                  {/* <a target="_blank" href="/sample?pid=1">보러 가기<ArrowForwardIcon className="icon" /></a> */}
                  <Button className="button btn bg-navy btn-primary" onClick={e => window.open("https://youtu.be/1qoxDpYQ4CU")}>
                    유튜브에서 보기
                    <ArrowForwardIcon className="icon" />
                  </Button>
                </div>
                <iframe className="content" width="712" height="403" src={`https://www.youtube.com/embed/1qoxDpYQ4CU${(this.state.selectMenu === "SAMPLE") ? "?autoplay=1&mute=1" : ""}`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen={true}/>
              </div>
              <div className="sample" style={{marginTop:"50px"}}>
                <div className="title">
                  <div className="img-category">
                    BUILDIT AI 활용 사례 (2020.12 출시 예정)
                  </div>
                  <div className="img-main-title">
                    불광5구역 프로젝트
                  </div>
                  <div className="img-sub-title">
                    약 2,000여 세대 대규모의 대단지 재개발 사업으로 약 10년간<br/>
                    지지부진한 재개발 추진에 인공지능 건축설계를 적용했습니다.
                  </div>
                  {/* <a target="_blank" href="/sample?pid=1">보러 가기<ArrowForwardIcon className="icon" /></a> */}
                  <Link to="/sample?pid=1" className="text-deco-none">
                    <Button className="button btn bg-navy btn-primary">
                      프로젝트 보러 가기
                      <ArrowForwardIcon className="icon" />
                    </Button>
                  </Link>
                </div>
                <Link to="/sample?pid=1" className="text-deco-none">
                  <div className="content">
                    <div className="cover">
                      <div className="info">
                        <div>프로젝트 보러 가기</div>
                        <ArrowForwardIcon className="icon" />
                      </div>
                    </div>
                    <img src="/img/landing_img_sample.png" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="l-container price">
            <div className="wrapper animatable fadeInUp">
              <div className="title">
                <div className="main-title">서비스 플랜 및 가격</div>
                <div className="sub-title"><span>고객님께 딱 맞는 패키지를 </span><span>찾아 드리겠습니다.</span></div>
              </div>
              <div className="content">
                <div className="price-cards">
                  <PriceCard discount={true} title="BRONZE" 
                    info={<div style={{ display: "flex", flexFlow: "column"}}><span>아파트 단지의 계획 설계 가능</span></div>} 
                    price={10000000} firstOptions="컨설팅 의뢰 50% 할인" secondOptions="-" builditPoint={100000} servicePoint={100000} 
                  />
                  <PriceCard discount={true} title="SILVER" 
                    info={<div style={{ display: "flex", flexFlow: "column"}}><span>대규모 아파트 단지의 계획 설계 가능</span></div>}
                    price={50000000} firstOptions="1:1전화 상담 가능 / 방문 교육 1회 가능" 
                    secondOptions="컨설팅 의뢰 1회 가능" builditPoint={500000} servicePoint={1000000} 
                  /> 
                  <PriceCard discount={true} title="GOLD" 
                    info={<div style={{ display: "flex", flexFlow: "column"}}><span>판교, 위례 신도시 정도의</span><span>계획 설계 가능</span></div>} 
                    price={100000000} firstOptions="1:1전화 상담 가능 / 방문 교육 2회 가능" 
                    secondOptions="컨설팅 의뢰 2회 가능" builditPoint={1000000} servicePoint={4000000} 
                  />
                  <PriceCard discount={true} title="PLATINUM" 
                    info={<div style={{ display: "flex", flexFlow: "column"}}><span>무제한 사용권</span></div>} 
                    price={200000000} firstOptions="1:1전화 상담 가능 / 방문 교육 5회 가능" 
                    secondOptions="컨설팅 의뢰 4회 가능"
                  />
                </div>
                <div className="service-info">
                  <img className="top-line" src="/img/service_card_line.png" style={{ width: "100%" }} />
                  <div className="service-request">
                    <div className="title">서비스 문의</div>
                    <div className="contact">
                      <PhoneIcon className="icon m-r-sm" />
                      02-375-1011
                    </div>
                    <div className="email">
                      <EmailIcon className="icon m-r-sm" />
                      1011@1011.co.kr
                    </div>
                  </div>
                  <div className="info-wrapper">
                  <div className="consulting-info">
                    <div className="title">빌드잇 컨설팅 안내</div>
                    <div className="table">
                      <div className="tr">
                        <div className="th">건물 종류</div>
                        <div className="td">아파트</div>
                      </div>
                      <div className="tr">
                        <div className="th">대지 면적</div>
                        <div className="td">10,000㎡이상 부터</div>
                      </div>
                      <div className="tr">
                        <div className="th">컨설팅 금액</div>
                        <div className="td">기본료 100만원 + ㎡ 당 100원</div>
                      </div>
                      <div className="tr">
                        <div className="th">*피드백 횟수</div>
                        <div className="td">5회</div>
                      </div>
                    </div>
                    <div className="content-wrap">
                      <div className="content">
                        <span className="m-r-sm">컨설팅을 의뢰하시면 인공지능이 내놓은 설계안을 건축 설계사들이</span>
                        <span>다시 검토 및 수정하여 미적인 부분까지 결과물의 퀄리티를 높여드립니다.</span>
                      </div>
                    </div>
                  </div>
                  <div className="info-wrap">
                    <div className="title">서비스 비용 안내</div>
                    <div className="table">
                      <div className="tr">
                        <div className="th">빌드잇 AI<span>(2020출시 예정)</span></div>
                        <div className="td">기본 1,000P + 대지 면적 1㎡당 2P 차감</div>
                      </div>
                      <div className="tr">
                      <div className="th">빌드잇 DESIGNER</div>
                        <div className="td">기본 1,000P + 대지 면적 1㎡당 1P 차감</div>
                      </div>
                      <div className="tr">
                        <div className="th">재계산 및 파라미터 튜닝</div>
                        <div className="td">위 금액의 90% 할인</div>
                      </div>
                    </div>
                  </div>
                    
                  </div>
                </div>
              </div>
              {/* <div className="sub-content">
                <div className="contact">
                  <div className="contact-box">
                    <div className="title">전화 문의</div>
                    <div className="value">02-375-1011</div>
                  </div>
                  <div className="contact-box">
                    <div className="title">이메일 문의</div>
                    <div className="value">1011@1011.co.kr</div>
                  </div>
                </div>
                <div className="detail-infomation">
                  <div className="info-box">
                    <div className="title">서비스 비용 안내</div>
                    <div className="value">기본 1,000p + 연면적 1㎡ 당 1p 차감 동일 영역 파라미터 튜닝 시 위 금액의 90% 할인</div>
                  </div>
                  <div className="info-box">
                    <div className="title">컨설팅 안내</div>
                    <div className="value">컨설팅을 의뢰하시면 인공지능이 내놓은 설계안을 건축 설계사들이 다시 검토하고 수정하여 미적인 부분까지 결과물의 퀄리티를 높여드립니다.</div>
                  </div>
                  <div className="info-box">
                    <div className="title">컨설팅 비용 안내</div>
                    <div className="value">연면적 1,000㎡ 당 100만원</div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="l-container finish">
            <div className="wrapper">
              <div className="animatable fadeInUp">
                <div className="main-title">
                  {/* <div>앞으로가 더 기대되는 BUILDIT은</div>
                  <div>지금도 추가 요소기술이 개발중이며, </div>
                  <div>내년의 빌드잇은 올해보다 더 놀라워질 것입니다.</div> */}
                  <div>Build it better,</div>
                  <div>Build it smarter.</div>
                  <div>BUILDIT</div>
                </div>
                <div className="btn-groups">
                  <DownloadButton className="bg-navy btn btn-primary" 
                    onClick={(callback?: Function) => {
                      callback && callback();
                      // @ts-ignore
                      window.open("https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Manual/BUILDIT_MANUAL.pdf");
                    }}
                  >
                    USER GUIDE</DownloadButton>
                  <Link to="login" className="text-deco-none">
                    <Button className="bg-navy btn btn-run buildit-start-btn">
                      BUILDIT START
                      <ArrowForwardIcon className="icon m-l-a" />
                    </Button>
                  </Link>
                </div>
              </div>
              </div>
          </div>
          <div className="l-container footer">
            <div className="wrapper">
              <div className="content">
                <div className="links">
                  <a href="https://www.1011.co.kr" target="_blank" className="link">회사 소개</a>
                  <div className="link-divider" />
                  <a href="/termofservice?type=개인%20정보%20처리%20방침" target="_blank" className="link">개인정보처리방침</a>
                  <div className="link-divider" />
                  <a href="/termofservice?type=서비스%20이용%20약관" target="_blank" className="link">서비스 이용약관</a>
                </div>
                {/* <DownloadButton className="bg-navy btn btn-primary" 
                  onClick={(callback?: Function) => {
                    callback && callback();
                    // @ts-ignore
                    window.location.href = "https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Manual/BUILDIT_MANUAL.pdf";
                  }}
                >
                  BUILDIT DESIGNER</DownloadButton> */}
              </div>
              <div className="footer-foot">
                <div className="address">
                  <span>서울특별시 마포구 월드컵북로 39 (상암동, 누리꿈스퀘어)</span><span>연구개발타워 1008호 (주) 텐일레븐 | 1011@1011.co.kr</span>
                </div>
                <div className="copyright">
                  Copyright ⓒ 2020 BUILDIT
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    )
  }
}

const PriceCard = (props: any) => {
  return (
    <div className={`card ${props.title}`}>
      <img className="top-line" 
        style={{ width: "100%" }}
        src={
          (props.title === "BRONZE" && '/img/bronze_card_line.png') ||
          (props.title === "SILVER" && '/img/silver_card_line.png') ||
          (props.title === "GOLD" && '/img/gold_card_line.png') || 
          (props.title === "PLATINUM" && '/img/platinum_card_line.png') || ""
        }
      />
      <div className="price-title">{props.title}</div>
      <div className="price-info">{props.info}</div>
      <div className="price">
        <div className="origin-price">{Number(props.price).toLocaleString()}원</div>
        <div className="discount-price">
          { props.discount && <span style={{color: "#E81C1C", fontSize: "12px", marginRight: "5px"}}>런칭 기념 50% 할인가</span> }
          <span style={{color: "#E81C1C"}}>{Number(props.price / 2).toLocaleString()}원</span>
        </div>
      </div>
      <div className="point-info">
        {Number(props.builditPoint + props.servicePoint).toLocaleString()}P
      </div>
      <div className="price-table">
        <div className="icon-value">
          <div className="icon-wrap"><AnnounceIcon className="icon" /></div>
          <div className="value">{props.firstOptions}</div>
        </div>
        <div className="icon-value">
          <div className="icon-wrap"><img src="/img/landing_icon_person.svg" /></div>
          <div className="value">{props.secondOptions}</div>
        </div>
        <div className="icon-value">
          <div className="icon-wrap"><CalendarTodayIcon className="icon" /></div>
          <div className="value display-flex" >
            <div>사용기간</div>
            <div className="m-l-a m-r-lg">1년</div>
          </div>
        </div>
        <div className="icon-value-table">
          <div className="icon-wrap">
            <img src="/img/landing_icon_point.svg" />
          </div>
          <div className="value">
            <div className="key-value all">
              <div className="key">제공되는 총 포인트</div>
              <div className="value">
                {
                  (props.builditPoint === undefined && props.servicePoint === undefined) &&
                  "무제한" || 
                  (Number(props.builditPoint + props.servicePoint).toLocaleString() + "P")
                }
              </div>
            </div>
            <div className="key-value buildit">
            {
              props.builditPoint &&
              <Fragment>
                <div className="key">빌드잇 포인트</div>
                <div className="value">
                  {
                    Number(props.builditPoint).toLocaleString()
                  }P
                </div>
              </Fragment>
              
            }
            </div>
            <div className="key-value service">
            {
              props.servicePoint &&
              <Fragment>
                <div className="key">서비스 포인트</div>
                <div className="value">
                  {
                    Number(props.servicePoint).toLocaleString()
                  }P
                </div>
              </Fragment>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
