import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import BuilditTemplate from './BuilditTemplate';
import './css/EnergyPage.scss';
import { Project } from './model/Project';
import AWSModule from './AWSModule';
import App from './App';
import queryString from 'query-string';
import { default as _ } from 'lodash';
import Tabs from './Tabs';
import { Button, } from '@material-ui/core';

export interface EnergyPagePros {
  
}

export interface EnergyPageStates {
  project: Project; 
  data: any;
  cur_grade: any;
  cur_category: any;
  show_result: boolean;
  report_number?: number;
}

export default class EnergyPage extends SessionComponent<EnergyPagePros, EnergyPageStates> {
  state: EnergyPageStates = {
    project: {},
    data: {},
    cur_grade: undefined,
    cur_category: undefined,
    show_result: false,
    report_number: undefined,
  }

  componentWillMount = async () => {
    const ddb = await new AWSModule("DDB").connect();
    const qs = queryString.parse(location.search);
    console.log("qs", qs)
    console.log("qs.num", qs.num)

    this.setState({report_number: Number(qs.num)});

    const r = await ddb.Ddb!.get({
      TableName: "platform-buildit-project",
      Key: {
        stage: App.stage,
        project_id: Number(qs.id),
      }
    }).promise();

    if (r.Item) {
      let project = r.Item;
      this.setState({project: project});
      console.log(r.Item)
    }
    const s3 = await new AWSModule("S3").connect();
    let output = await s3.S3!.getObject({
      Bucket: `teneleven-engine-result`,
      Key: `dev/${qs.id}/${qs.idx}/energy/output.json`,
    }).promise();

    let json_list = output.Body!.toString().split('\n').map(e => JSON.parse(e));

    const cats = _(json_list).groupBy(x => [x.자립률_솔버_출력값.category, x.자립률_솔버_출력값.자립률_구분]).mapValues(x => x[0]).value() as any;
    console.log(cats);

    const cgs = Object.keys(cats).map(x => x.split(","));

    const cgsUnique = _(cgs).map(x => Number(x[0])).uniq().value();

    const categories = {} as any;

    const passives = _(cgsUnique).filter(x => x <= 4).sort().take(2).value();

    categories['패시브 최우선'] = passives[0];
    categories['패시브 우선'] = passives[1];

    if (cgsUnique.includes(5)) {
      categories['적정 액티브'] = 5;
    }

    if (cgsUnique.includes(7)) {
      categories['내단열 우선'] = 7;
    }

    for (let k in categories) {
      const i = categories[k];

      categories[k] = [];

      for (let g = 1; g <= 5; ++g) {
        categories[k].push(cats[`${i},G${g}`]);
      }
    }

    console.log("CATEGORIES", categories);
    this.setState({data: categories});
  }

  flatten = (x: any, prefix?: string) => {
    let ret = {};

    for (let k in x) {
      const v = x[k];
      const key = prefix ? prefix + "_" + k : k;

      if (typeof(v) == 'object') {
        if (Array.isArray(v)) {

        } else {
          ret = {
            ...ret,
            ...this.flatten(v, key),
          }
        }
      } else {
        //@ts-ignore
        ret[key] = v;
      }
    }

    return ret;
  }

  download = () => {
    const fs = require('fs');
    const xlsx = require('xlsx');
    const data = this.state.data[this.state.cur_category][this.state.cur_grade - 1];
    console.log("data", data)
    const output = this.flatten(data);
    console.log("output", output)
    
    const book = new xlsx.utils.book_new();

    for (let k of Object.entries(data)) {
      const for_xlsx = [this.flatten(k[1])];
      console.log("k[1]", k[1]);
      console.log("for_xlsx", for_xlsx)
      const sheet = xlsx.utils.json_to_sheet(for_xlsx);
      xlsx.utils.book_append_sheet(book, sheet, k[0]);
    }

    xlsx.writeFile(book, this.state.project.project_name +  ".xlsx");
    
  }

  render() {
    let cur_data = undefined;
    if (this.state.show_result) {
      cur_data = this.state.data[this.state.cur_category][this.state.cur_grade - 1];

      if (this.state.project.project_id === 15566) {
        // @ts-ignore
        cur_data = tempData[this.state.cur_category][this.state.cur_grade - 1];
      }
    }
    
    return (
      <BuilditTemplate footer={true} {...this.props}>
        <div className="EnergyPage">
          <pre style={{color:"white"}} className="font font-18px">{`${this.state.project.project_name}${(this.state.report_number !== undefined && !Number.isNaN(this.state.report_number)) ? ` - ${this.state.report_number + 1}번 결과` : ``}`}</pre>
          <pre style={{color:"white"}} className="font font-14px">목표 제로에너지 건축물 인증 등급</pre>
          <Tabs className="tabs bg-navy">
            <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_grade === 1 ? "active" : ""}`} onClick={e => {
              this.setState({cur_grade: 1})
            }}>
              1등급
            </Button>
            <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_grade === 2 ? "active" : ""}`} onClick={e => {
              this.setState({cur_grade: 2})
            }}>
              2등급
            </Button>
            <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_grade === 3 ? "active" : ""}`} onClick={e => {
              this.setState({cur_grade: 3})
            }}>
              3등급
            </Button>
            <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_grade === 4 ? "active" : ""}`} onClick={e => {
              this.setState({cur_grade: 4})
            }}>
              4등급
            </Button>
            <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_grade === 5 ? "active" : ""}`} onClick={e => {
              this.setState({cur_grade: 5})
            }}>
              5등급
            </Button>
          </Tabs>
          <pre style={{color:"white"}} className="font font-14px">목표 건물 에너지 특성</pre>
          <Tabs className="tabs bg-navy">
            {
              this.state.data["패시브 최우선"] && 
              <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_category === "패시브 최우선" ? "active" : ""}`} onClick={e => {
                this.setState({cur_category: "패시브 최우선"})
              }}>
                패시브 최우선
              </Button>
            }
            {
              this.state.data["패시브 우선"] && 
              <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_category === "패시브 우선" ? "active" : ""}`} onClick={e => {
                this.setState({cur_category: "패시브 우선"})
              }}>
                패시브 우선
              </Button>
            }
            {
              this.state.data["적정 액티브"] && 
              <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_category === "적정 액티브" ? "active" : ""}`} onClick={e => {
                this.setState({cur_category: "적정 액티브"})
              }}>
                적정 액티브
              </Button>
            }
            {
              this.state.data["내단열 우선"] && 
              <Button disabled={this.state.show_result} className={`img-btn tab bg-navy tab-primary ${this.state.cur_category === "내단열 우선" ? "active" : ""}`} onClick={e => {
                this.setState({cur_category: "내단열 우선"})
              }}>
                내단열 우선
              </Button>
            }
            
          </Tabs>
          <Button disabled={this.state.show_result} className="run-button img-btn tab bg-navy tab-primary" onClick={e => {
                if (this.state.cur_grade && this.state.cur_category) {
                  this.setState({show_result: true})
                } else {
                  alert("인증등급과 에너지특성을 선택해 주세요");
                }
              }}>
                분석하기
          </Button>
          {/* { <select value={this.state.cur_grade} onChange={e => this.setState({cur_grade: Number(e.target.value) })}>
            <option></option>
            {_.range(5).map(i => {
              return <option value={i + 1}>{i + 1}</option>
            })}
          </select>
          <select value={this.state.cur_category} onChange={e => this.setState({cur_category: e.target.value })}>
            <option></option>
            {Object.keys(this.state.data).map(k => {
              return <option value={k}>{k}</option>
            })}
          </select> */}
          
            {/* {this.state.show_result && this.state.cur_grade && this.state.cur_category && (
              <pre style={{ color: 'white' }}>
                {JSON.stringify(cur_data.자립률_솔버_출력값, null, 2)}
              </pre>
            )} */}
          
          {
            this.state.show_result && this.state.cur_grade && this.state.cur_category &&
            <div className="result-page">
              <div className="full-content">
                <div style={{marginTop: "10px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="button-layer">
                  <Button style={{width: "200px"}} className="img-btn tab bg-navy tab-primary" onClick={e =>
                    this.setState({show_result:false})
                  }>
                    뒤로가기
                  </Button>
                  <Button style={{width: "200px", marginLeft: "10px"}} className="img-btn tab bg-navy tab-primary" onClick={e => {
                    window.print();
                  }}>
                    결과 다운로드
                  </Button>
                </div>
                
                <div style={{justifyContent:"center", height: "50px", fontWeight: "bold"}} className="title font font-16px">빌드잇 에너지 결과</div>
                <div style={{height:"20px", display:"flex", flexDirection: "row", marginTop: "10px", width: "80%", marginLeft: "auto", marginRight: "auto", justifyContent: "space-between"}} className="row">
                  <div style={{height: "100%", width: "48%", display: "flex", justifyContent: "space-between"}}>
                    <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">프로젝트 ID</div>
                    <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{this.state.project.user_project_id}</div>
                  </div>
                  <div style={{height: "100%", width: "48%", display: "flex", justifyContent: "space-between"}}>
                    <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">보고서 번호</div>
                    <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${(this.state.report_number !== undefined && !Number.isNaN(this.state.report_number)) ? `${this.state.report_number + 1}번 결과` : `-`}`}</div>
                  </div>
                </div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">USER ID</div>
                  <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{App.session.email}</div>
                </div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">예상 건물에너지 효율등급</div>
                  <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{cur_data.자립률_솔버_출력값.최종_건물에너지_효율_등급}</div>
                </div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">예상 ZEB 등급</div>
                  <div style={{height: "100%", width: "48%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${cur_data.자립률_솔버_출력값.자립률_구분[1]}등급`}</div>
                </div>
                <div style={{display:"flex", flexDirection: "row", marginTop: "10px", width: "80%", marginLeft: "auto", marginRight: "auto", justifyContent: "space-between"}}>
                  <div style={{height: "100%", width: "48%"}}>
                    <div style={{width: "95%", paddingLeft: "5%"}} className="title font font-16px">추천 패시브 성능</div>
                    <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between"}}>
                      <div style={{width: "48%"}}>
                        <div className="row" style={{display:"flex", justifyContent: "space-between", height: "20px"}}>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-12px">지붕 열관류율</div>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-12px">{`${cur_data.요구량_솔버_입력값.지붕열관류율}W/m2K`}</div>
                        </div>
                        <div className="row" style={{display:"flex", justifyContent: "space-between", height: "20px"}}>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-12px">외벽 열관류율</div>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-12px">{`${cur_data.요구량_솔버_입력값.외벽열관류율}W/m2K`}</div>
                        </div>
                        <div className="row" style={{display:"flex", justifyContent: "space-between", height: "20px"}}>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-12px">바닥 열관류율</div>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-12px">{`${cur_data.소요량_솔버_입력값.바닥_열관류율}W/m2K`}</div>
                        </div>
                        <div className="row" style={{display:"flex", justifyContent: "space-between", height: "20px"}}>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-12px">창 열관류율</div>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-12px">{`${cur_data.요구량_솔버_입력값.창열관류율}W/m2K`}</div>
                        </div>
                      </div>
                      <div style={{width: "48%"}}>
                        <div className="row" style={{display:"flex", justifyContent: "space-between", height: "20px"}}>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-12px">창 투과율</div>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-12px">{`${cur_data.요구량_솔버_입력값.창투과율}`}</div>
                        </div>
                        <div className="row" style={{display:"flex", justifyContent: "space-between", height: "20px"}}>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-12px">침기율</div>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-12px">{`${cur_data.요구량_솔버_입력값.침기율}/h`}</div>
                        </div>
                        <div className="row" style={{display:"flex", justifyContent: "space-between", height: "20px"}}>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-12px">열저장능력</div>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-12px">{cur_data.요구량_솔버_입력값.열저장능력}</div>
                        </div>
                        <div className="row" style={{display:"flex", justifyContent: "space-between", height: "20px"}}>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-12px">단열방식</div>
                          <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-12px">{cur_data.요구량_솔버_입력값.열교가산치}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{height: "100%", width: "48%"}}>
                    <div style={{width: "95%", paddingLeft: "5%"}} className="title font font-16px">추천 엑티브 성능</div>
                    <div style={{display:"flex", justifyContent: "space-between", height: "20px"}} className="row">
                      <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-14px">온열원설비_효율</div>
                      <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-14px">{`${cur_data.소요량_솔버_입력값.온열원설비_효율}%`}</div>
                    </div>
                    <div style={{display:"flex", justifyContent: "space-between", height: "20px"}} className="row">
                      <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#232732", alignItems: "center"}} className="head font font-14px">급탕설비_효율</div>
                      <div style={{display:"flex", width: "48%", justifyContent: "center", height: "100%", backgroundColor:"#1A1E28", alignItems: "center"}} className="content font font-14px">{`${cur_data.소요량_솔버_입력값.급탕설비_효율}%`}</div>
                    </div>
                  </div>
                </div>
                <div style={{width: "78%", paddingLeft: "2%"}} className="title font font-16px">추천 태양광 시스템 정보</div>
                <div style ={{width: "80%", marginTop: "5px", marginLeft: "auto", marginRight: "auto"}}>
                  <div style={{display:"flex", flexDirection: "row"}}>
                    <div className="head font font-14px system-table-cell header" style={{width:"15%", borderRight: "1px solid #cccccc"}}>필요 PV타입</div>
                    <div style={{width:"40%", borderRight: "1px solid #cccccc"}}>
                      <div className="head font font-14px system-table-cell header">방위 별 필요 면적</div>
                      <div style={{display:"flex", flexDirection: "row", borderTop: "1px solid #cccccc"}}>
                        <div className="head font font-14px system-table-cell header" style={{width:"20%"}}>동</div>
                        <div className="head font font-14px system-table-cell header" style={{width:"20%"}}>남동</div>
                        <div className="head font font-14px system-table-cell header" style={{width:"20%"}}>남</div>
                        <div className="head font font-14px system-table-cell header" style={{width:"20%"}}>남서</div>
                        <div className="head font font-14px system-table-cell header" style={{width:"20%"}}>서</div>
                      </div>
                    </div>
                    <div className="head font font-14px system-table-cell header" style={{width:"15%", borderRight: "1px solid #cccccc"}}>PV기울기</div>
                    <div className="head font font-14px system-table-cell header" style={{width:"15%", borderRight: "1px solid #cccccc"}}>모듈적용 타입</div>
                    <div className="head font font-14px system-table-cell header" style={{width:"15%"}}>최대 출력계수</div>
                  </div>
                  <div style={{display:"flex", flexDirection: "row", marginTop: "2px"}}>
                    <div className="content font font-14px system-table-cell info" style={{width:"15%", borderRight: "1px solid #cccccc"}}>단결정</div>
                    <div style={{width:"40%", borderRight: "1px solid #cccccc", display:"flex", flexDirection: "row"}}>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>-</div>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>-</div>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>{`${Number(cur_data.자립률_솔버_출력값.필요면적_45_남).toFixed(2)}m2`}</div>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>-</div>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>-</div>
                    </div>
                    <div className="content font font-14px system-table-cell info" style={{width:"15%", borderRight: "1px solid #cccccc"}}>45도</div>
                    <div className="content font font-14px system-table-cell info" style={{width:"15%", borderRight: "1px solid #cccccc"}}>후면통풍형</div>
                    <div className="content font font-14px system-table-cell info" style={{width:"15%"}}>{`${cur_data.자립률_솔버_출력값.출력계수_45}`}</div>
                  </div>
                  <div style={{display:"flex", flexDirection: "row"}}>
                    <div className="content font font-14px system-table-cell info" style={{width:"15%", borderRight: "1px solid #cccccc"}}>다결정</div>
                    <div style={{width:"40%", borderRight: "1px solid #cccccc", display:"flex", flexDirection: "row"}}>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>{`${Number(cur_data.자립률_솔버_출력값.필요면적_수직_동).toFixed(2)}m2`}</div>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>{`${Number(cur_data.자립률_솔버_출력값.필요면적_수직_남동).toFixed(2)}m2`}</div>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>{`${Number(cur_data.자립률_솔버_출력값.필요면적_수직_남).toFixed(2)}m2`}</div>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>{`${Number(cur_data.자립률_솔버_출력값.필요면적_수직_남서).toFixed(2)}m2`}</div>
                      <div className="content font font-14px system-table-cell info" style={{width:"20%"}}>{`${Number(cur_data.자립률_솔버_출력값.필요면적_수직_서).toFixed(2)}m2`}</div>
                    </div>
                    <div className="content font font-14px system-table-cell info" style={{width:"15%", borderRight: "1px solid #cccccc"}}>수직</div>
                    <div className="content font font-14px system-table-cell info" style={{width:"15%", borderRight: "1px solid #cccccc"}}>밀착형</div>
                    <div className="content font font-14px system-table-cell info" style={{width:"15%"}}>{`${cur_data.자립률_솔버_출력값.출력계수_수직}`}</div>
                  </div>
                </div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">예상 태양광 시스템 1차에너지 생산량</div>
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${Number(cur_data.자립률_솔버_출력값.단위면적당_태양광_생산량).toFixed(2)} kWh/m2.yr`}</div>
                </div>
                <div style={{width: "78%", paddingLeft: "2%"}} className="title font font-16px">예상 에너지 요구량</div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">예상 에너지 요구량</div>
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${Number(Number(cur_data.소요량_솔버_입력값.난방요구량) + Number(cur_data.소요량_솔버_입력값.조명요구량) + Number(cur_data.소요량_솔버_입력값.급탕요구량)).toFixed(2)} kWh/m2.yr`}</div>
                </div>
                <div style={{width: "78%", paddingLeft: "2%"}} className="title font font-16px">예상 등급용 1차 에너지 소요량</div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">예상 등급용 1차 에너지 소요량</div>
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${Number(cur_data.자립률_솔버_출력값.최종_단위면적당_등급용_1차에너지_소요량).toFixed(2)} kWh/m2.yr`}</div>
                </div>
                <div style={{width: "78%", paddingLeft: "2%"}} className="title font font-16px">신재생 생산량 정보</div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">ZEB 목표 등급을 위한  예상 총 필요 1차에너지 생산량</div>
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${Number(cur_data.자립률_솔버_출력값.단위면적당_필요_생산량).toFixed(2)} kWh/m2.yr`}</div>
                </div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">ZEB 목표 등급을 위한 예상 태양광 시스템 1차에너지 생산량</div>
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${Number(cur_data.자립률_솔버_출력값.단위면적당_태양광_생산량).toFixed(2)} kWh/m2.yr`}</div>
                </div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto"}} className="row">
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">ZEB 목표 등급을 위한 예상 추가 필요 1차에너지 생산량</div>
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${Number(cur_data.자립률_솔버_출력값.단위면적당_추가_필요_생산량).toFixed(2)} kWh/m2.yr`}</div>
                </div>
                <div style={{width: "78%", paddingLeft: "2%"}} className="title font font-16px">예상 에너지 자립률</div>
                <div style={{height:"20px", width: "80%", marginLeft: "auto", marginRight: "auto", marginBottom: "20px"}} className="row">
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#232732"}} className="head font font-14px">예상 에너지 자립률</div>
                  <div style={{height: "100%", width: "50%", justifyContent:"center", display:"flex", alignItems:"center", backgroundColor:"#1A1E28"}} className="content font font-14px">{`${Number(cur_data.자립률_솔버_출력값.자립률 * 100).toFixed(2)} %`}</div>
                </div>
              </div>
            </div>
          }
        </div>
      </BuilditTemplate>
    )
  }
}

const tempData = {
  "패시브 최우선": [
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.75,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 75.94,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 1,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 115.43,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 0,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G1",
        "자립률": 1,
        "필요_생산량": 588348.7668311244,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 588348.7668311244,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 221126.09865455504,
        "단위면적당_필요_생산량": 115.43,
        "단위면적당_태양광_생산량": 100.49,
        "단위면적당_추가_필요_생산량": 14.94,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 369.96,
        "필요면적_수직_동": 390.3,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 390.3,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.78,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 75.94,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 1,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 11.54,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G2",
        "자립률": 0.9001951730477952,
        "필요_생산량": 529513.890148012,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 529513.890148012,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 162291.22197144257,
        "단위면적당_필요_생산량": 103.89,
        "단위면적당_태양광_생산량": 85.12,
        "단위면적당_추가_필요_생산량": 18.77,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 354.46,
        "필요면적_수직_동": 301.59,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 301.59,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.75,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 75.94,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 1,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 34.63,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G3",
        "자립률": 0.7001518012593964,
        "필요_생산량": 411844.1367817871,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 411844.1367817871,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 44621.4686052177,
        "단위면적당_필요_생산량": 80.8,
        "단위면적당_태양광_생산량": 80.29,
        "단위면적당_추가_필요_생산량": 0.51,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 310.15,
        "필요면적_수직_동": 301.59,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 301.59,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.75,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 75.94,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 1,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 57.71,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G4",
        "자립률": 0.5001084294709973,
        "필요_생산량": 294174.3834155622,
        "생산량": 295251.44733666495,
        "총_필요_생산량": 294174.3834155622,
        "총_태양광_생산량": 295251.44733666495,
        "추가_필요_생산량": 0,
        "단위면적당_필요_생산량": 57.71,
        "단위면적당_태양광_생산량": 57.28,
        "단위면적당_추가_필요_생산량": 0.43,
        "태양광_생산량_동": 64967.00972349338,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 165317.42788967816,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 64967.00972349338,
        "면적옵션": "O3",
        "필요면적_45_남": 199.38,
        "필요면적_수직_동": 230.63,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 230.63,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.75,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 75.94,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 1,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 80.8,
        "최종_건물에너지_효율_등급": "1++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G5",
        "자립률": 0.3000650576825984,
        "필요_생산량": 176504.6300493373,
        "생산량": 187718.22080944988,
        "총_필요_생산량": 176504.6300493373,
        "총_태양광_생산량": 187718.22080944988,
        "추가_필요_생산량": 0,
        "단위면적당_필요_생산량": 34.63,
        "단위면적당_태양광_생산량": 34.28,
        "단위면적당_추가_필요_생산량": 0.35,
        "태양광_생산량_동": 36543.942969465024,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 114630.33487051984,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 36543.942969465024,
        "면적옵션": "O1",
        "필요면적_45_남": 88.61,
        "필요면적_수직_동": 159.67,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 159.67,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    }
  ],
  "패시브 우선": [
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.3903670049098639,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 80.65,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.403245797713423,
            4.777293614633668,
            1.803149063382454,
            0.07470688041126317,
            0,
            0,
            0,
            0,
            0,
            0,
            1.976736626330304,
            6.416170691542577
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 35.15782352611423,
        "급탕_등급산출_1차에너지_소요량": 29.659460231661797,
        "조명_등급산출_1차에너지_소요량": 35.515887880325316,
        "난방_1차에너지_소요량": 35.185655844877346,
        "급탕_1차에너지_소요량": 29.675330536518086,
        "조명_1차에너지_소요량": 35.515887880325316,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 2,
        "energy_value": 100,
        "단위면적당_1차에너지_소요량": 100.37687426172076,
        "단위면적당_등급용_1차에너지_소요량": 100.33317163810133,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 0,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 610430.8870317119,
        "자립률_구분": "G1",
        "자립률": 1,
        "필요_생산량": 610430.8870317118,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 610430.8870317118,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 243208.21885514236,
        "단위면적당_필요_생산량": 122.59,
        "단위면적당_태양광_생산량": 108.94,
        "단위면적당_추가_필요_생산량": 13.65,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 372.18,
        "필요면적_수직_동": 443.52,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 443.52,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.3903670049098639,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 80.65,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.403245797713423,
            4.777293614633668,
            1.803149063382454,
            0.07470688041126317,
            0,
            0,
            0,
            0,
            0,
            0,
            1.976736626330304,
            6.416170691542577
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 35.15782352611423,
        "급탕_등급산출_1차에너지_소요량": 29.659460231661797,
        "조명_등급산출_1차에너지_소요량": 35.515887880325316,
        "난방_1차에너지_소요량": 35.185655844877346,
        "급탕_1차에너지_소요량": 29.675330536518086,
        "조명_1차에너지_소요량": 35.515887880325316,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 2,
        "energy_value": 100,
        "단위면적당_1차에너지_소요량": 100.37687426172076,
        "단위면적당_등급용_1차에너지_소요량": 100.33317163810133,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 12.26,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 610430.8870317119,
        "자립률_구분": "G2",
        "자립률": 0.9003920175213771,
        "필요_생산량": 549387.7983285406,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 549387.7983285406,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 182165.13015197124,
        "단위면적당_필요_생산량": 110.33,
        "단위면적당_태양광_생산량": 95.26,
        "단위면적당_추가_필요_생산량": 15.09,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 372.18,
        "필요면적_수직_동": 354.82,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 364.82,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.3903670049098639,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 80.65,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.403245797713423,
            4.777293614633668,
            1.803149063382454,
            0.07470688041126317,
            0,
            0,
            0,
            0,
            0,
            0,
            1.976736626330304,
            6.416170691542577
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 35.15782352611423,
        "급탕_등급산출_1차에너지_소요량": 29.659460231661797,
        "조명_등급산출_1차에너지_소요량": 35.515887880325316,
        "난방_1차에너지_소요량": 35.185655844877346,
        "급탕_1차에너지_소요량": 29.675330536518086,
        "조명_1차에너지_소요량": 35.515887880325316,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 2,
        "energy_value": 100,
        "단위면적당_1차에너지_소요량": 100.37687426172076,
        "단위면적당_등급용_1차에너지_소요량": 100.33317163810133,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 36.78,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 610430.8870317119,
        "자립률_구분": "G3",
        "자립률": 0.7003049025166266,
        "필요_생산량": 427301.6209221982,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 427301.6209221982,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 60078.952745628834,
        "단위면적당_필요_생산량": 85.81,
        "단위면적당_태양광_생산량": 85.12,
        "단위면적당_추가_필요_생산량": 0.69,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 354.46,
        "필요면적_수직_동": 301.59,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 301.59,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.3903670049098639,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 80.65,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.403245797713423,
            4.777293614633668,
            1.803149063382454,
            0.07470688041126317,
            0,
            0,
            0,
            0,
            0,
            0,
            1.976736626330304,
            6.416170691542577
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 35.15782352611423,
        "급탕_등급산출_1차에너지_소요량": 29.659460231661797,
        "조명_등급산출_1차에너지_소요량": 35.515887880325316,
        "난방_1차에너지_소요량": 35.185655844877346,
        "급탕_1차에너지_소요량": 29.675330536518086,
        "조명_1차에너지_소요량": 35.515887880325316,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 2,
        "energy_value": 100,
        "단위면적당_1차에너지_소요량": 100.37687426172076,
        "단위면적당_등급용_1차에너지_소요량": 100.33317163810133,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 61.29,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 610430.8870317119,
        "자립률_구분": "G4",
        "자립률": 0.5002177875118762,
        "필요_생산량": 305215.4435158559,
        "생산량": 326939.895436673,
        "총_필요_생산량": 305215.4435158559,
        "총_태양광_생산량": 326939.895436673,
        "추가_필요_생산량": 0,
        "단위면적당_필요_생산량": 61.29,
        "단위면적당_태양광_생산량": 60.34,
        "단위면적당_추가_필요_생산량": 0.95,
        "태양광_생산량_동": 73087.88593893005,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 180764.12355881295,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 73087.88593893005,
        "면적옵션": "O4",
        "필요면적_45_남": 177.23,
        "필요면적_수직_동": 266.11,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 266.11,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.14,
        "외벽열관류율": 0.14,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.3903670049098639,
        "지붕_열관류율": 0.14,
        "바닥_열관류율": 0.14,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 80.65,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.403245797713423,
            4.777293614633668,
            1.803149063382454,
            0.07470688041126317,
            0,
            0,
            0,
            0,
            0,
            0,
            1.976736626330304,
            6.416170691542577
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 35.15782352611423,
        "급탕_등급산출_1차에너지_소요량": 29.659460231661797,
        "조명_등급산출_1차에너지_소요량": 35.515887880325316,
        "난방_1차에너지_소요량": 35.185655844877346,
        "급탕_1차에너지_소요량": 29.675330536518086,
        "조명_1차에너지_소요량": 35.515887880325316,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 2,
        "energy_value": 100,
        "단위면적당_1차에너지_소요량": 100.37687426172076,
        "단위면적당_등급용_1차에너지_소요량": 100.33317163810133,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 85.81,
        "최종_건물에너지_효율_등급": "1++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 610430.8870317119,
        "자립률_구분": "G5",
        "자립률": 0.3001306725071257,
        "필요_생산량": 183129.26610951353,
        "생산량": 187718.22080944988,
        "총_필요_생산량": 183129.26610951353,
        "총_태양광_생산량": 187718.22080944988,
        "추가_필요_생산량": 0,
        "단위면적당_필요_생산량": 36.78,
        "단위면적당_태양광_생산량": 36.13,
        "단위면적당_추가_필요_생산량": 0.65,
        "태양광_생산량_동": 36543.942969465024,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 114630.33487051984,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 36543.942969465024,
        "면적옵션": "O1",
        "필요면적_45_남": 93.04,
        "필요면적_수직_동": 168.54,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 168.54,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    }
  ],
  "적정 액티브": [
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 89.25,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 5,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 0,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G1",
        "자립률": 1,
        "필요_생산량": 588348.7668311244,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 588348.7668311244,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 221126.09865455504,
        "단위면적당_필요_생산량": 135.66,
        "단위면적당_태양광_생산량": 115.54,
        "단위면적당_추가_필요_생산량": 20.12,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 369.96,
        "필요면적_수직_동": 487.87,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 487.87,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 89.25,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 5,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 13.57,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G2",
        "자립률": 0.9001951730477952,
        "필요_생산량": 529513.890148012,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 529513.890148012,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 162291.22197144257,
        "단위면적당_필요_생산량": 122.09,
        "단위면적당_태양광_생산량": 101.86,
        "단위면적당_추가_필요_생산량": 20.24,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 369.96,
        "필요면적_수직_동": 399.17,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 399.17,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 89.25,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 5,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 40.7,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G3",
        "자립률": 0.7001518012593964,
        "필요_생산량": 411844.1367817871,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 411844.1367817871,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 44621.4686052177,
        "단위면적당_필요_생산량": 94.96,
        "단위면적당_태양광_생산량": 94.33,
        "단위면적당_추가_필요_생산량": 0.63,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 369.96,
        "필요면적_수직_동": 350.38,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 350.38,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 89.25,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 5,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 67.83,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G4",
        "자립률": 0.5001084294709973,
        "필요_생산량": 294174.3834155622,
        "생산량": 295251.44733666495,
        "총_필요_생산량": 294174.3834155622,
        "총_태양광_생산량": 295251.44733666495,
        "추가_필요_생산량": 0,
        "단위면적당_필요_생산량": 67.83,
        "단위면적당_태양광_생산량": 67.58,
        "단위면적당_추가_필요_생산량": 0.25,
        "태양광_생산량_동": 64967.00972349338,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 165317.42788967816,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 64967.00972349338,
        "면적옵션": "O3",
        "필요면적_45_남": 243.69,
        "필요면적_수직_동": 266.11,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 266.11,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "외단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.31071628024364806,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 89.25,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            7.187064353330508,
            3.8879132498072146,
            1.3300477238487247,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1.4915802413412562,
            5.394382526880033
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 32.15861773341894,
        "급탕_등급산출_1차에너지_소요량": 29.85631697922945,
        "조명_등급산출_1차에너지_소요량": 34.709866189956664,
        "난방_1차에너지_소요량": 32.171612848703475,
        "급탕_1차에너지_소요량": 29.864297501933315,
        "조명_1차에너지_소요량": 34.709866189956664,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 5,
        "energy_value": 96,
        "단위면적당_1차에너지_소요량": 96.74577654059345,
        "단위면적당_등급용_1차에너지_소요량": 96.72480090260507,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 88.18,
        "최종_건물에너지_효율_등급": "1++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 588348.7668311244,
        "자립률_구분": "G5",
        "자립률": 0.3501,
        "필요_생산량": 176504.6300493373,
        "생산량": 187718.22080944988,
        "총_필요_생산량": 176504.6300493373,
        "총_태양광_생산량": 187718.22080944988,
        "추가_필요_생산량": 0,
        "단위면적당_필요_생산량": 47.48,
        "단위면적당_태양광_생산량": 46.75,
        "단위면적당_추가_필요_생산량": 0.73,
        "태양광_생산량_동": 36543.942969465024,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 114630.33487051984,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 36543.942969465024,
        "면적옵션": "O1",
        "필요면적_45_남": 115.2,
        "필요면적_수직_동": 221.76,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 221.76,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    }
  ],
  "내단열 우선": [
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "내단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.25930144933243177,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 95.24,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.228231582757457,
            4.646346294955861,
            1.7306242438719626,
            0.06921869312633294,
            0,
            0,
            0,
            0,
            0,
            0,
            1.904332716422299,
            6.269023347864474
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 34.43040829077363,
        "급탕_등급산출_1차에너지_소요량": 30.396291841566562,
        "조명_등급산출_1차에너지_소요량": 34.05849375724792,
        "난방_1차에너지_소요량": 34.4399195455078,
        "급탕_1차에너지_소요량": 30.401397033771868,
        "조명_1차에너지_소요량": 34.05849375724792,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 7,
        "energy_value": 98,
        "단위면적당_1차에너지_소요량": 98.8998103365276,
        "단위면적당_등급용_1차에너지_소요량": 98.88519388958812,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 0,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 601448.2857234934,
        "자립률_구분": "G1",
        "자립률": 1,
        "필요_생산량": 601448.2857234934,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 601448.2857234934,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 234225.61754692404,
        "단위면적당_필요_생산량": 144.76,
        "단위면적당_태양광_생산량": 129.22,
        "단위면적당_추가_필요_생산량": 15.55,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 369.96,
        "필요면적_수직_동": 576.58,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 576.58,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "내단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.25930144933243177,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 95.24,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.228231582757457,
            4.646346294955861,
            1.7306242438719626,
            0.06921869312633294,
            0,
            0,
            0,
            0,
            0,
            0,
            1.904332716422299,
            6.269023347864474
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 34.43040829077363,
        "급탕_등급산출_1차에너지_소요량": 30.396291841566562,
        "조명_등급산출_1차에너지_소요량": 34.05849375724792,
        "난방_1차에너지_소요량": 34.4399195455078,
        "급탕_1차에너지_소요량": 30.401397033771868,
        "조명_1차에너지_소요량": 34.05849375724792,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 7,
        "energy_value": 98,
        "단위면적당_1차에너지_소요량": 98.8998103365276,
        "단위면적당_등급용_1차에너지_소요량": 98.88519388958812,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 21.71,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 601448.2857234934,
        "자립률_구분": "G2",
        "자립률": 0.8501,
        "필요_생산량": 541303.4571511442,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 541303.4571511442,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 174080.78897457477,
        "단위면적당_필요_생산량": 123.05,
        "단위면적당_태양광_생산량": 108.7,
        "단위면적당_추가_필요_생산량": 14.35,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 369.96,
        "필요면적_수직_동": 443.52,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 443.52,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "내단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.25930144933243177,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 95.24,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.228231582757457,
            4.646346294955861,
            1.7306242438719626,
            0.06921869312633294,
            0,
            0,
            0,
            0,
            0,
            0,
            1.904332716422299,
            6.269023347864474
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 34.43040829077363,
        "급탕_등급산출_1차에너지_소요량": 30.396291841566562,
        "조명_등급산출_1차에너지_소요량": 34.05849375724792,
        "난방_1차에너지_소요량": 34.4399195455078,
        "급탕_1차에너지_소요량": 30.401397033771868,
        "조명_1차에너지_소요량": 34.05849375724792,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 7,
        "energy_value": 98,
        "단위면적당_1차에너지_소요량": 98.8998103365276,
        "단위면적당_등급용_1차에너지_소요량": 98.88519388958812,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 43.43,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 601448.2857234934,
        "자립률_구분": "G3",
        "자립률": 0.7001034686028836,
        "필요_생산량": 421013.8000064454,
        "생산량": 367222.6681765694,
        "총_필요_생산량": 421013.8000064454,
        "총_태양광_생산량": 367222.6681765694,
        "추가_필요_생산량": 53791.13182987599,
        "단위면적당_필요_생산량": 101.34,
        "단위면적당_태양광_생산량": 100.49,
        "단위면적당_추가_필요_생산량": 0.85,
        "태양광_생산량_동": 81208.76215436672,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 204805.14386783593,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 81208.76215436672,
        "면적옵션": "O5",
        "필요면적_45_남": 369.96,
        "필요면적_수직_동": 390.3,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 390.3,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "내단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.25930144933243177,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 95.24,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.228231582757457,
            4.646346294955861,
            1.7306242438719626,
            0.06921869312633294,
            0,
            0,
            0,
            0,
            0,
            0,
            1.904332716422299,
            6.269023347864474
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 34.43040829077363,
        "급탕_등급산출_1차에너지_소요량": 30.396291841566562,
        "조명_등급산출_1차에너지_소요량": 34.05849375724792,
        "난방_1차에너지_소요량": 34.4399195455078,
        "급탕_1차에너지_소요량": 30.401397033771868,
        "조명_1차에너지_소요량": 34.05849375724792,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 7,
        "energy_value": 98,
        "단위면적당_1차에너지_소요량": 98.8998103365276,
        "단위면적당_등급용_1차에너지_소요량": 98.88519388958812,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 72.38,
        "최종_건물에너지_효율_등급": "1+++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 601448.2857234934,
        "자립률_구분": "G4",
        "자립률": 0.5000739061449169,
        "필요_생산량": 300724.1428617467,
        "생산량": 302618.011313712,
        "총_필요_생산량": 300724.1428617467,
        "총_태양광_생산량": 302618.011313712,
        "추가_필요_생산량": 0,
        "단위면적당_필요_생산량": 72.38,
        "단위면적당_태양광_생산량": 72.01,
        "단위면적당_추가_필요_생산량": 0.37,
        "태양광_생산량_동": 64967.00972349338,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 172683.99186672526,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 64967.00972349338,
        "면적옵션": "O3",
        "필요면적_45_남": 221.54,
        "필요면적_수직_동": 310.46,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 340.46,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    },
    {
      "metadata": {
        "stage": "dev",
        "email": "sunhookim@1011.co.kr",
        "project_id": 15566,
        "report_id": 1,
        "user_project_id": 3029,
        "report_number": 1
      },
      "요구량_솔버_입력값": {
        "stage": "dev",
        "project_id": 15566,
        "rnum": 1,
        "도시": "서울",
        "지붕열관류율": 0.15,
        "외벽열관류율": 0.15,
        "창열관류율": 0.89,
        "외벽투과율": 0,
        "창투과율": 0.89,
        "차양": [
          "없음"
        ],
        "블라인드": [
          "없음"
        ],
        "침기율": 6,
        "열저장능력": 90,
        "열교가산치": "내단열",
        "조명부하": 7.5,
        "점등시간": 5,
        "난방설정온도": 20,
        "운전방식": "정상가동"
      },
      "소요량_솔버_입력값": {
        "세대수": 33,
        "연면적": 6081.3896778662975,
        "대지면적": 3015.305121797566,
        "창면적비": 26.550241555405297,
        "창면적비_남": 64.76087460403306,
        "창면적비_남동": 0,
        "창면적비_남서": 0,
        "외벽_평균_열관류율": 0.25930144933243177,
        "지붕_열관류율": 0.15,
        "바닥_열관류율": 0.15,
        "기밀성능": 6,
        "조명부하": 7.5,
        "난방요구량": 95.24,
        "조명요구량": 0,
        "급탕요구량": 0,
        "온열원설비_효율": 96.8,
        "급탕설비_효율": 96.8
      },
      "자립률_솔버_입력값": {
        "총_지붕면적": 443.0700000000001,
        "총_외벽면적_동": 887.0399848937986,
        "총_외벽면적_남동": 0,
        "총_외벽면적_남": 547.3199906791959,
        "총_외벽면적_남서": 0,
        "총_외벽면적_서": 887.0399848937986,
        "irrad": {
          "수평": [
            83,
            117.4,
            141.2,
            180.3,
            189.3,
            183.1,
            145.9,
            147.4,
            157.7,
            129.1,
            82.4,
            72.1
          ],
          "지중": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          "동": [
            46.6,
            66.7,
            122.1,
            95.5,
            97,
            113,
            102,
            100.4,
            99.6,
            92.1,
            51.8,
            50.2
          ],
          "서": [
            52,
            90.8,
            62.4,
            112.5,
            95.9,
            97.4,
            72,
            80.9,
            97.8,
            72.7,
            52.6,
            43.5
          ],
          "남": [
            116,
            134.4,
            118.5,
            110.6,
            85.6,
            86,
            75.1,
            86.7,
            117.7,
            138.7,
            103.9,
            105.8
          ],
          "북": [
            28.3,
            41,
            48.4,
            66.5,
            56.3,
            77.3,
            67,
            68.1,
            60.2,
            38.3,
            31.3,
            26.9
          ],
          "남동": [
            87.5,
            98.9,
            142,
            105.7,
            97.5,
            104.6,
            94.2,
            99.6,
            115.7,
            128.9,
            83.5,
            87.8
          ],
          "남서": [
            94.6,
            127.3,
            82.3,
            120.2,
            96.9,
            94.5,
            73.2,
            84.3,
            112.2,
            106.8,
            84.4,
            79.6
          ],
          "북동": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ],
          "북서": [
            28.7,
            44.2,
            75,
            77.1,
            76.9,
            99.3,
            88.7,
            85,
            72.9,
            49.5,
            32.5,
            28.5
          ]
        }
      },
      "요구량_솔버_출력값": {
        "난방요구량": [
          [
            8.228231582757457,
            4.646346294955861,
            1.7306242438719626,
            0.06921869312633294,
            0,
            0,
            0,
            0,
            0,
            0,
            1.904332716422299,
            6.269023347864474
          ]
        ],
        "급탕요구량": [
          [
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333,
            2.558333333333333
          ]
        ],
        "환기요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "냉방요구량": [
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        ],
        "조명요구량": [
          [
            1.1625,
            1.05,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625,
            1.1625,
            1.125,
            1.1625,
            1.125,
            1.1625
          ]
        ]
      },
      "소요량_솔버_출력값": {
        "난방_등급산출_1차에너지_소요량": 34.43040829077363,
        "급탕_등급산출_1차에너지_소요량": 30.396291841566562,
        "조명_등급산출_1차에너지_소요량": 34.05849375724792,
        "난방_1차에너지_소요량": 34.4399195455078,
        "급탕_1차에너지_소요량": 30.401397033771868,
        "조명_1차에너지_소요량": 34.05849375724792,
        "건물에너지_효율_등급": "1+"
      },
      "자립률_솔버_출력값": {
        "category": 7,
        "energy_value": 98,
        "단위면적당_1차에너지_소요량": 98.8998103365276,
        "단위면적당_등급용_1차에너지_소요량": 98.88519388958812,
        "건물에너지_효율_등급": "1+",
        "최종_단위면적당_등급용_1차에너지_소요량": 89.75,
        "최종_건물에너지_효율_등급": "1++",
        "연면적": 6081.3896778662975,
        "총_1차에너지_소요량": 601448.2857234934,
        "자립률_구분": "G5",
        "자립률": 0.3802,
        "필요_생산량": 180434.48571704802,
        "생산량": 187718.22080944988,
        "총_필요_생산량": 180434.48571704802,
        "총_태양광_생산량": 187718.22080944988,
        "추가_필요_생산량": 0,
        "단위면적당_필요_생산량": 55.01,
        "단위면적당_태양광_생산량": 54.31,
        "단위면적당_추가_필요_생산량": 0.7,
        "태양광_생산량_동": 36543.942969465024,
        "태양광_생산량_남동": 0,
        "태양광_생산량_남": 114630.33487051984,
        "태양광_생산량_남서": 0,
        "태양광_생산량_서": 36543.942969465024,
        "면적옵션": "O1",
        "필요면적_45_남": 159.51,
        "필요면적_수직_동": 239.5,
        "필요면적_수직_남동": 0,
        "필요면적_수직_남": 0,
        "필요면적_수직_남서": 0,
        "필요면적_수직_서": 239.5,
        "출력계수_45": 0.21,
        "출력계수_수직": 0.18
      }
    }
  ]
}