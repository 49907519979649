import React, { Fragment, Component } from 'react';
import BuilditTemplate from './BuilditTemplate';
import SessionComponent from './SessionComponent';

import './css/Home.scss';
import App from './App';
import { Project } from './model/Project';
import ProjectTypeTag from './ProjectTypeTag';
import AWSModule from './AWSModule';

import ArrowForward from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom';
import Tabs from './Tabs';
import { Button } from '@material-ui/core';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import S3Image from './S3Image';
import * as URI from "uri-js";
import MapView from './MapView';
import { default as _ } from 'lodash';
import DiscountTag from './DiscountTag';
import ProjectIcon from './ProjectIcon';

import Axios from 'axios';

export interface HomeProps {

}

export interface HomeState {
  finishedProjects?: Array<Project>,
  finishedProjectsLength: number,
  finishedTabActive: "AI" | "DESIGNER",
}

export default class Home extends SessionComponent<HomeProps, HomeState> {
  state: HomeState = {
    finishedProjectsLength: 0,
    finishedTabActive: "AI",
  }
  componentWillMount = async() => {
    this.getFinishedProjects();
  }

  setFinishedTabActive = (type: "AI" | "DESIGNER") => {
    if (type !== this.state.finishedTabActive) {
      this.setState({
        finishedTabActive: type
      }, () => {
        this.getFinishedProjects();
      })
    }
  }

  getFinishedProjects = async () => {
    

    if (this.state.finishedTabActive === "AI") {
      const r = await App.search({
        table: 'platform-buildit-project',
        query: `discarded:false AND deleted:false AND status:FINISHED AND (project_type:AI OR project_type:CAL) AND (NOT hidden:true)`,
        from: 0,
        size: 4,
        sort: [ { finished_at: 'desc', created_at: 'desc' } ],
      });

      const rr = r.data;
      if (rr.hits && rr.hits.hits) {
        const projects = rr.hits.hits.map((p: any) => p._source);
        if (rr.hits.total > 0 && rr.hits.total < 4) {
          for (let i = 0; i < 4 - rr.hits.total; i++) {
            projects.push({});
          }
        }
        this.setState({
          finishedProjects: projects,
          finishedProjectsLength: rr.hits.total
        })
      } else {
        console.log('err');
      }
    } else {
      const r = await App.search({
        table: 'platform-buildit-project',
        query: `discarded:false AND deleted:false AND status:FINISHED AND project_type:DESIGNER AND (NOT hidden:true)`,
        from: 0, size: 4,
        sort: [ { finished_at: 'desc' } ]
      });

      const rr = r.data;
  
      if (rr.hits && rr.hits.hits) {
        
        const pidList = rr.hits.hits.map((p: any) => ( { project_id: p._source.project_id, stage: App.stage }));
        const ddb = await new AWSModule("DDB").connect();
        const dr = await ddb.Ddb!.batchGet({
          RequestItems: {
            'platform-buildit-project': {
              ConsistentRead: true,
              Keys: pidList,
            },
          },
        }).promise();


        const projects = dr.Responses && dr.Responses['platform-buildit-project'];
        if (rr.hits.total > 0 && rr.hits.total < 4) {
          for (let i = 0; i < 4 - rr.hits.total; i++) {
            (projects as Array<any>).push({});
          }
        }
        if (projects && projects.length > 0) {
          projects.sort((a: Project, b: Project) => {
            // @ts-ignore
            return new Date(b.finished_at) - new Date(a.finished_at);
          })
        }
        this.setState({
          finishedProjects: projects && projects || [],
          finishedProjectsLength: rr.hits.total,
        })
      } else {
        console.log('err');
      }
    }
  }
  render() {
    return (
      <BuilditTemplate footer={true} {...this.props} >
        <div className="Home">
          <div className="home-wrapper">
            <div className="header">
              <div className="wrapper">
              <div className="title">HOME</div>
                <div className="point-info">
                  <div className="name">
                    <img className="m-r-sm buildit-point-logo" src="/img/buildit_point_logo.svg" />
                    <div className="useable-point">사용 가능 포인트</div>
                  </div>
                  <div className="point">
                    { Number(App.session.point + App.session.service_point).toLocaleString() }P
                  </div>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="main-panel">
                <div className="finished-projects ">
                  <div className="header">
                    <Link className="router-link" to={`/project/list?type=FINISHED&page=1&q=${encodeURIComponent(JSON.stringify({query: [{ field: "status", content: "FINISHED"}]}))}`}>
                      완료된 프로젝트
                      <ArrowForward className="icon m-l-sm" />
                    </Link>
                    <Tabs className="bg-navy tabs tabs-small m-l-a ">
                      <Button disableRipple={true} onClick={e => this.setFinishedTabActive("AI")} className={`bg-navy tab tab-primary tab-small ${this.state.finishedTabActive === "AI" && "active" || ""}`}>AI</Button>
                      <Button disableRipple={true} onClick={e => this.setFinishedTabActive("DESIGNER")} className={`bg-navy tab tab-primary tab-small ${this.state.finishedTabActive === "DESIGNER" && "active" || ""}`}>DESIGNER</Button>
                    </Tabs>
                  </div>
                  <div className="body">
                  {
                    this.state.finishedProjects === undefined &&
                    <div style={{height: "410px"}} />
                    ||
                    (this.state.finishedProjects!.length === 0 &&
                    <Fragment>
                      <div className="sample-project">
                        <HomeSampleProjectCard />
                      </div>
                      <div className="empty-result">
                        {/* <div className="empty-img">
                          <img src="/img/home_empty.png" />
                        </div> */}
                        <div className="content">
                          <div className="main-title">Welcome to BUILDIT!</div>
                          <div className="sub-title">
                            <div className="msgs">
                              <div className="msg">왼쪽의 샘플 프로젝트 결과를 체험해 보실 수 있습니다.</div>
                              <div className="msg"><span className="font font-special">+프로젝트 시작</span>을 눌러 간단한 설정으로 빌드잇 결과를 받아보세요!</div>
                            </div>
                            <Button className="bg-navy btn btn-run btn-large new-project-btn"
                              disableRipple={true}
                              onClick={e => {
                                // @ts-ignore
                                this.props.history.push('/project/create')
                              }}
                            >
                              + 프로젝트 시작
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                     ||
                    this.state.finishedProjects!.map((p: Project, i: number) => {
                      if (p.user_project_id) {
                        return ( <HomeProjectCard project={p} /> )
                      } else if (this.state.finishedProjectsLength === i) {
                        return ( <HomeProjectCard firstEmpty={true} /> )
                      } else {
                        return ( <HomeProjectCard empty={true} /> )
                      }
                    }))
                  }
                  </div>
                </div>
              </div>
              <div className="sub-panel">
                <ProjectListSummary
                  type="FAVORITE"
                  title="즐겨찾기 프로젝트"
                  query={`email.keyword:${App.session.email} AND deleted:false AND discarded:false AND favorite:true AND (NOT hidden:true)`}
                  link={`/project/list?type=FAVORITE&page=1&q=${encodeURIComponent(JSON.stringify({query: [{ field: "favorite", content: true}]}))}`}
                />
                <ProjectListSummary 
                  type="LATEST"
                  className="m-l-a"
                  title="최근 생성 프로젝트"
                  query={`email.keyword:${App.session.email} AND deleted:false AND discarded:false AND (NOT hidden:true)`}
                  link="/project/list"
                />
              </div>
            </div>
          </div>
        </div>
      </BuilditTemplate>
    )
  }
}

export class HomeSampleProjectCard extends Component {
  render() {
    return (
      <Link to={`/sample?pid=1`} className="router-link">
        <div className={`HomeProjectCard`} style={{marginRight: "20px"}}>
          <div className="header">
            <div className="user-project-id">
              0
            </div>
            <div className="project-type-tag">
              <div style={{ 
                  borderRadius: "20px",
                  color: "#ccc",
                  border: "1px solid #ccc",
                  fontSize: "10px",
                  width: "60px",
                  display: "flex",
                  height: "18px",
                  alignItems: "center",
                  justifyContent: "center",
              }}>
                sample
              </div>
            </div>
          </div>
          <div className="view-panel">
            <img src="https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/7/resView.png" style={{ width: "100%" }}/>
          </div>
          <div className="body">
            <div title={`불광 5구역 프로젝트`} className="project-name"><span>{`불광 5구역 프로젝트`}</span></div>
            <div className="project-value">
              달성 용적률
              <div className="floor-area-ratio">
                220.36%
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}


export interface HomeProjectCardProps {
  firstEmpty?: boolean;
  empty?: boolean;
  project?: Project;
  className?: string;
}
export interface HomeProjectCardState {
  result: any;
  existConfigProject: boolean;
}

export class HomeProjectCard extends Component<HomeProjectCardProps, HomeProjectCardState> {
  state: HomeProjectCardState = {
    result: undefined,
    existConfigProject: false,
  }
  componentWillMount = async () => {
    if (this.props.project) {
      const r = await App.search({
        table: "engine-buildit-result",
        query: `base_project_id:${this.props.project.project_id}`,
        sort: [ { loc_floor_area_ratio: 'desc' } ],
      });

      const rrr = r.data;

      if (rrr.hits && rrr.hits.hits && rrr.hits.hits.length > 0) {
        const projectId = rrr.hits.hits[0]._source.base_project_id;
        const reportId = rrr.hits.hits[0]._source.base_report_id;
        const aws = await new AWSModule("DDB").connect();
        const r = await aws.Ddb!.get({
          TableName: "engine-buildit-result",
          ConsistentRead: true,
          Key: {
            base_uuid: `${App.stage}_${projectId}_${reportId}`
          }
        }).promise();

        if (r.Item) {
          this.setState({ result: r.Item });
        }
      }
    }


    if (this.props.firstEmpty) {
      const cr = await App.search({
        table: "platform-buildit-project",
        query: `discarded:false AND deleted:false AND status:CONFIG AND (NOT hidden:true)`,
        from: 0, size: 1,
      });
      
      const crr = cr.data;
      if (crr.hits && crr.hits.hits && crr.hits.hits.length > 0) {
        this.setState({ existConfigProject: true });
      }
    }
  }

  render() {
    if (this.props.firstEmpty) {
      return (
        <div className={`HomeProjectCard first-empty ${this.props.className || ""}`}>
        {
          this.state.existConfigProject &&
          <div className="content">
            <div className="title">설정중인 프로젝트를 완료해주세요</div>
            <Button disableRipple={false} className="bg-navy btn btn-primary btn-large">설정중 목록가기</Button>
          </div>
          ||
          <div className="content">
            <div className="title">새로운 프로젝트를 시작하세요</div>
            <Button disableRipple={false} className="bg-navy btn btn-run btn-large">+ 새 프로젝트 시작</Button>
          </div>
        }
        </div>
      )
    } else if (this.props.empty) {
      return (
        <div className={`HomeProjectCard empty ${this.props.className && this.props.className || ""}`}>
          <div className="msg">프로젝트를 완료해주세요</div>
        </div>
      )
    } else {
      return (
        <Link to={`/project/result?pid=${this.props.project && this.props.project.user_project_id}`} className="router-link">
          <div className={`HomeProjectCard ${this.props.className && this.props.className || ""}`}>
            <div className="header">
              <div className="user-project-id">
                { this.props.project && this.props.project.user_project_id }
              </div>
              <div className="project-type-tag">
                { this.props.project && <ProjectTypeTag background="DARK" type={this.props.project.project_type} /> }
              </div>
              <div className="statusField">
                { this.props.project && <ProjectIcon status={this.props.project.status} />}
              </div>
            </div>
            <div className="view-panel">
            {
              this.props.project &&
              this.props.project.status === "FINISHED" &&
              (this.props.project.project_type === "AI" || this.props.project.project_type === "CAL") &&
              <div className="img">
              {
                this.state.result && this.state.result.base_report_file_path.resImage &&
                <S3Image
                  s3={{
                    Bucket: "teneleven-engine-result",
                    Key: this.state.result.base_report_file_path.resImage.split("teneleven-engine-result/")[1]
                  }}
                /> 
              }
              </div> 
              ||
              this.props.project && this.props.project.project_type === "DESIGNER" &&
              <div>
                <MapView project={this.props.project} height={285} 
                  size="SMALL"
                  viewOptions={{
                    projectSite: true,
                    road: true,
                    vacancyInside: true,
                    vacancyOutside: true,
                    skyline: true,
                    boundarySite: true,
                  }}
                  interaction={false}
                />
              </div>
            }
            </div>
            <div className="body">
              <div title={this.props.project && this.props.project.project_name} className="project-name">
                {
                  this.props.project &&
                  this.props.project.discountable && <DiscountTag className="m-r-sm"/>
                }
                <span className="name">{this.props.project && this.props.project.project_name}</span>
              </div>
              {
                this.props.project && (this.props.project.project_type === "AI" || this.props.project.project_type === "CAL") &&
                <div className="project-value">
                  달성 용적률
                  <div className="floor-area-ratio">
                    {
                      this.state.result !== undefined ?
                      Number(this.state.result.loc_floor_area_ratio).toFixed(2) : 0
                    }%
                  </div>
                </div>
              }
            </div>
          </div>
        </Link>
      )
    }
  }
}

export interface ProjectListSummaryProps {
  className?: string,
  title: string,
  query: string,
  link: string,
  type: "FAVORITE" | "LATEST";
}

export interface ProjectListSummaryState {
  projects: Array<Project>;
}

export class ProjectListSummary extends Component<ProjectListSummaryProps, ProjectListSummaryState> {
  state: ProjectListSummaryState = {
    projects: [],
  }

  componentWillMount = async () => {
    const r = await App.search({
      table: "platform-buildit-project",
      query: this.props.query,
      from: 0, size: 5,
      sort: [ { created_at: 'desc' } ],
    });

    const rr = r.data;
    if (rr.hits && rr.hits.hits) {
      const projects = rr.hits.hits.map((p: any) => p._source);
      if (rr.hits.total < 5 && rr.hits.total > 0) {
        for (let i = 0; i < 5 - rr.hits.total; i++) {
          projects.push({});
        }
      }
      this.setState({
        projects: projects
      })
    } else {
      console.log('err');
    }
  }

  render() {
    return (
      <div className={`ProjectListSummary ${this.props.className && this.props.className || ""}`}>
        <div className="header">
          <Link to={this.props.link} className="router-link">
            {this.props.title}
            <ArrowForward className="icon m-l-sm" />
          </Link>
        </div>
        <div className="body">
          <div className="project-summary-table">
          {
            this.state.projects.length === 0 &&
            <div className="empty">
              <div className="msg"> 
              {
                this.props.type === "FAVORITE" &&
                "즐겨찾는 프로젝트가 없습니다"
              }
              </div>
            { 
              this.props.type === "LATEST" &&
              <div className="msg">
                <span>최근 생성 프로젝트가 없습니다</span>
                <span>새로운 프로젝트를 시작해주세요!</span>
              </div>
            }
            </div>

            ||
            this.state.projects.map((p: Project) => {
              let to = "edit";
              if (["FINISHED", "RUNNING", "WAITING", "FAILED"].includes(p.status!)) {
                to = "result";
              }

              if (p.user_project_id) {
                return (
                  <Link to={`/project/${to}?pid=${p.user_project_id}`} className="router-link">
                    <div className="tr">
                      <div className="td user_project_id">{p.user_project_id}</div>
                      <div className="td project_type"><ProjectTypeTag type={p.project_type} background="DARK"/></div>
                      <div className="td project_name" title={p.project_name}>
                      {
                        p.discountable && <DiscountTag className="m-r-sm"/>
                      }
                        <div className="name">{p.project_name}</div>
                      </div>
                    </div>
                  </Link>
                )
              } else {
                return (
                  <div className="tr empty" />
                )
              }
            })
          }
          </div>
        </div>
      </div>
    )
  }
}